<template>
    <div class="hint-help-popup-dev-container">
        <lightbox :is-static="true">
            <hint-help-popup
                v-if="render && currentQuestion"
                :help-text="currentQuestion.hint"
                @timeout="onTimeout"
                @click:ok="onClickOk"
            />
        </lightbox>
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <locale-select-dev />
        <quiz-select-dev @question-changed="currentQuestion = $event" :show-question="true" />

        <div class="row">
            <button class="btn btn-success" @click="reRender">Re-render</button>
        </div>
    </dev-settings-panel>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>

import {BootstrapSelect, BootstrapButtonGroup} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import HintHelpPopup from "@/components/ui/HintHelpPopup/index.vue";
import Lightbox from "@seriousgamesinteractive/components-vue3/common/Lightbox.vue";
import {getFakeQuizFromLocale} from "@/utils/generator";
import LocaleSelectDev from "@/components/dev/LocaleSelectDev.vue";
import QuizSelectDev from "@/components/dev/QuizSelectDev.vue";

export default {
    name: 'HintHelpPopupDev',
    components: {
        QuizSelectDev, LocaleSelectDev,
        HintHelpPopup,
        BootstrapSelect,
        DevSettingsPanel,
        BootstrapButtonGroup,
        EventTicks,
        Lightbox,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        return {
            events: ['click:ok', 'timeout'],

            render: true,

            currentQuestion: null,
        }
    },
    methods: {
        reRender() {
            this.render = false;

            this.$nextTick(() => {
                this.render = true;
            });
        },
        onClickOk() {
            this.triggerEvent('click:ok')
        },
        onTimeout() {
            this.triggerEvent('timeout')
        },
    },
}
</script>

<style lang="scss" scoped>
.hint-help-popup-dev-container {
    margin-top: 50px;
}
</style>
