<template>
    <h1>MarkdownTextDev</h1>

    <div class="row">
        <markdown-text :debug="true">{{ markdownTexts[markdownTextIndex] }}</markdown-text>
    </div>

    <button class="btn btn-success" @click="cycleText">Cycle text (current: {{ markdownTextIndex }})</button>
</template>

<script>
import {DevComponentContainer, EventTicksComponentMixin} from "@seriousgamesinteractive/components-vue3/DevComponent";
import MarkdownText from "./index.vue";

export default {
    name: 'MarkdownTextDev',
    components: {MarkdownText},
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],
    data() {
        return {
            markdownTexts: [
                '>notquote',
                '> blockquote',
                '**Some bold text** - Bold!',
                '*Some italic text* - Italic!',
                '~Some underlined text~ - Underline!',
                '[https://www.google.com](some link) - Link!',
                '# headline1 - h1',
                '## headline2 - h2',
                '### headline3 - h3',
                '#### headline4 - h4',
                '- option',
            ],
            markdownTextIndex: 0,
        };
    },
    methods: {
        cycleText() {
            let newMarkdownTextIndex = this.markdownTextIndex + 1;

            if (newMarkdownTextIndex >= this.markdownTexts.length) {
                newMarkdownTextIndex = 0;
            }

            this.markdownTextIndex = newMarkdownTextIndex;
        },
    },
};
</script>
