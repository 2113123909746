<template>
    <div ref="timeLeftContainer" class="time-left-container">
        <div :style="questionTimeLeftBarStyle" class="time-left-bar"/>
        <div ref="timeLeftBox" class="time-left-box">
            {{ Math.max(0, secondsLeft) }}
        </div>
    </div>
</template>
<script>

export default {
    name: 'TimeLeft',
    props: {
        secondsLeft: {
            type: Number,
            required: true,
        },
        secondsTotal: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            timeLeftContainerWidth: 0,
            timeLeftBoxWidth: 0,
        }
    },
    mounted() {
        this.recomputeWidths();

        window.addEventListener('resize', this.onWindowResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            this.recomputeWidths();
        },
        recomputeWidths() {
            this.timeLeftContainerWidth = this.getContainerWidth();
            this.timeLeftBoxWidth = this.getBoxWidth();
        },
        getContainerWidth() {
            const element = this.$refs['timeLeftContainer'];

            if (!element) {
                return 0;
            }

            return element.clientWidth;
        },
        getBoxWidth() {
            const element = this.$refs['timeLeftBox'];

            if (!element) {
                return 0;
            }

            return element.clientWidth;
        },
    },
    computed: {
        questionTimeLeftBarStyle() {
            const secondsLeft = Math.max(0, Math.min(this.secondsTotal, this.secondsLeft));

            const fraction = (secondsLeft / this.secondsTotal);

            let fullWidth = this.timeLeftContainerWidth;
            let padding = this.timeLeftBoxWidth;

            let fullWidthWithPadding = fullWidth - padding;
            let calcWidthWithPadding = (fullWidthWithPadding * fraction) + padding;

            return {
                'width': `${calcWidthWithPadding}px`,
                'left': `${(fullWidth - calcWidthWithPadding) / 2}px`
            };
        },
    },
}
</script>

<style lang="scss" scoped>
$timeLeftContainerHeight: 25px;
$timeLeftBarHeight: $timeLeftContainerHeight - 6px;
$timeLeftBoxHeight: $timeLeftContainerHeight * 2.5;

.time-left-container {
    position: absolute;
    top: ($timeLeftContainerHeight / -2);
    left: 50%;
    transform: translateX(-50%);

    height: ($timeLeftContainerHeight);
    border-radius: ($timeLeftContainerHeight / 2);
    border: 2px solid #6A2600;
    width: calc(100% - 80px);
    background: #263E89;
    box-shadow: 13px 12px 4px 0px rgba(0, 0, 0, 0.25) inset;

    .time-left-box {
        position: absolute;
        top: ($timeLeftBoxHeight / -2) + 8px;
        left: 50%;
        transform: translateX(-50%);

        width: 100px;
        height: $timeLeftBoxHeight;
        background: #00205C;
        border: 1px solid #6A2600;
        box-shadow: 0px 4.80967px 1.92387px 0.48097px rgba(0, 0, 0, 0.57) inset;
        line-height: $timeLeftBoxHeight;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 40pt;
    }

    .time-left-bar {
        position: absolute;
        top: 1px;
        border-radius: ($timeLeftBarHeight / 2);
        height: $timeLeftBarHeight;
        background: #019BDE;
        transition: 250ms ease-in-out;
    }
}
</style>
