<template>
    <div :class="computedClass" class="app-button">
        <img class="background background-default" src="@/assets/buttons/btn-default.png"/>
        <img class="background background-active" src="@/assets/buttons/btn-active.png"/>
        <img class="background background-glow" src="@/assets/buttons/btn-glow.png"/>
        <img class="background background-glow-false" src="@/assets/buttons/btn-glow-false.png"/>

        <div class="app-button-inner">
            <span v-if="icon" class="app-button-icon"><no-drag-image :src="icon"/></span>

            <div class="label-container">
                <label v-if="fitText">
                    <text-fit :initial-size="fitTextInitialSize" :min-size="fitTextMinSize" :size-unit="fitTextSizeUnit"
                              :step="fitTextStep" :debug="fitTextDebug" :debug-step-time="fitTextDebugStepTime">
                        <slot/>
                    </text-fit>
                </label>
                <label v-else class="label-center">
                    <slot/>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import {NoDragImage, TextFit} from "@seriousgamesinteractive/components-vue3/common";

export default {
    name: 'AppButton',
    components: {TextFit, NoDragImage},
    props: {
        state: {
            type: String,
            required: false, // eg. "glow", "glow-false" or "disabled"
        },
        disabled: {
            // Note, can also be set in "state"
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        interactable: {
            type: Boolean,
            required: false,
            default: true,
        },
        fitText: {
            type: Boolean,
            required: false,
            default: true,
        },
        fitTextStep: {
            type: Number,
            required: false,
            default: 3,
        },
        fitTextSizeUnit: {
            type: String,
            required: false,
            default: 'px',
        },
        fitTextInitialSize: {
            type: Number,
            required: false,
            default: 30,
        },
        fitTextMinSize: {
            type: Number,
            required: false,
            default: 14,
        },
        fitTextDebug: {
            type: Boolean,
            required: false,
            default: false,
        },
        fitTextDebugStepTime: {
            type: Number,
            required: false,
            default: 1,
        },
    },
    computed: {
        computedClass() {
            const classes = [];

            if (this.state) {
                classes.push(`app-button-state-${this.state}`);
            }

            if (this.disabled || this.state === 'disabled') {
                classes.push(`app-button-disabled`);
            }

            if (this.icon) {
                classes.push(`app-button-with-icon`);
            }

            if (this.interactable) {
                classes.push(`app-button-interactable`);
            }

            return classes;
        }
    }
}
</script>

<style lang="scss" scoped>
.app-button {
    background-size: cover;

    display: inline-block;
    width: 475px;
    height: 117px;
    position: relative;
    pointer-events: all;

    .app-button-inner {
        user-select: none !important;
        width: 100%;
        height: 100%;
    }

    .app-button-icon {
        position: absolute;
        top: calc(50% - 5px);
        left: 25px;
        transform: translateY(-50%);
    }

    &.app-button-with-icon {
        .label-container {
            left: 80px;
            width: calc(100% - 80px - 30px);
        }
    }

    .label-container {
        top: 5px;
        right: 30px;
        bottom: 25px;
        left: 30px;

        width: calc(100% - 30px - 30px);
        height: calc(100% - 5px - 25px);
        position: absolute;
    }

    label {
        font-size: 40pt;
        color: white;
        pointer-events: none;
        font-weight: bold;
        line-height: 120%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        white-space: pre-wrap;
    }

    label.label-center {
        width: 100%;
        height: 100%;

        position: absolute;
        top: calc(50%);
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 150ms ease-in-out;
        pointer-events: none;
        user-select: none;
    }

    .background-active,
    .background-glow,
    .background-glow-false {
        opacity: 0;
    }

    &-interactable {
        cursor: pointer;

        &:hover {
            .background-active {
                opacity: 0.3;
            }
        }
    }

    &-disabled, &-state-disabled {
        cursor: not-allowed;

        .background {
            opacity: .3 !important;
        }

        .background-active {
            opacity: 0 !important;
        }

        .background-glow {
            opacity: 0 !important;
        }
    }

    &-state-hover {
        .background-active {
            opacity: 0.3 !important;
        }
    }

    &:active {
        .background-active {
            opacity: 1;
        }
    }

    &-state-active {
        .background-active {
            opacity: 1 !important;
        }
    }

    &-state-glow {
        .background-glow {
            opacity: 1 !important;
            filter: drop-shadow(0px 0px 36px #51FF00);
        }
    }

    &-state-glow-false {
        .background-glow-false {
            opacity: 1 !important;
            filter: drop-shadow(0px 0px 36px #ff0000);
        }
    }
}

label {
    text-align: center;
}

.text-fit-container {
    margin: 0 auto;
    text-align: center;
}
</style>
