import {createRouter, createWebHashHistory} from 'vue-router'

import DevView from '../views/000-DevView.vue'
import QuizListView from '../views/005-QuizListView.vue'
import QuizLanguageSelectView from '../views/010-QuizLanguageSelectView.vue'
import GameView from '../views/020-GameView.vue'
import StatView from '../views/100-StatView.vue'

const routes = [
    {
        path: '/',
        name: 'quiz-list',
        component: QuizListView
    },
    {
        path: '/quiz/:masterQuizId',
        name: 'quiz-select-language',
        component: QuizLanguageSelectView
    },
    {
        path: '/quiz/:masterQuizId/:quizLanguage',
        name: 'game',
        component: GameView
    },
    {
        path: '/stats/:masterQuizId',
        name: 'stats',
        component: StatView,
        children: [
            {
                path: '/stats/:masterQuizId/:quizLanguage',
                name: 'statsWithLanguage',
                component: StatView,
                children: [
                    {
                        path: '/stats/:masterQuizId/:quizLanguage/:questionNumber',
                        name: 'statsWithLanguageAndQuestionNumber',
                        component: StatView,
                    },
                ],
            },
        ],
    },
]

const isDev = true;

if (isDev) {
    routes.push({
        path: '/dev',
        name: 'DevMain',
        component: DevView,
        children: [
            {
                path: ':component',
                name: 'DevSub',
                component: DevView,
            },
        ],
    });
}

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
