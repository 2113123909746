<template>
    <app-button
        :class="computedClasses"
        :fit-text-initial-size="24"
        :fit-text-step="2"
        :icon="answerIcon"
        :state="(answer && answer.state ? answer.state : null)"
        class="answer-button"
        fit-text-size-unit="pt"
    >
        <div v-if="answer && answer.text" class="answer-button-inner">{{ answerText }}</div>
    </app-button>
</template>
<script>
import AppButton from "@/components/common/AppButton.vue";
import {NoDragImage, TextFit} from "@seriousgamesinteractive/components-vue3/common";

import AnswerIconA from "@/assets/icons/icon-question-a.png";
import AnswerIconB from "@/assets/icons/icon-question-b.png";
import AnswerIconC from "@/assets/icons/icon-question-c.png";
import AnswerIconD from "@/assets/icons/icon-question-d.png";
import {mapGetters} from "vuex";

export default {
    name: 'AnswerButton',
    props: {
        answerIndex: {
            type: Number,
            required: true,
        },
        answer: {
            type: [Object, null],
            required: true,
        },
        showAnswer: {
            type: Boolean,
            required: true,
        },
        pickedOtherAnswer: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['cheatMode']),

        computedClasses() {
            return [
                `answer-button-${this.showAnswer ? 'show' : 'hide'}`,
                `answer-button-picked-other-answer-${this.pickedOtherAnswer ? 'yes' : 'no'}`,
            ];
        },
        answerIcon() {
            if (this.answerIndex === 0) {
                return AnswerIconA;
            } else if (this.answerIndex === 1) {
                return AnswerIconB;
            } else if (this.answerIndex === 2) {
                return AnswerIconC;
            } else if (this.answerIndex === 3) {
                return AnswerIconD;
            }

            return null;
        },

        answerText() {
            let answerText = this.answer.text;

            if (this.cheatMode && this.answer.isCorrect) {
                answerText += ` (!!!)`;
            }

            return answerText;
        }
    },
    components: {
        NoDragImage,
        TextFit,
        AppButton,
    },
}
</script>

<style lang="scss" scoped>
.answer-button {
    position: relative;
    transition: opacity 200ms ease-in-out;

    &-show {
        .answer-button-inner {
            opacity: 1;
        }
    }

    &-hide {
        .answer-button-inner {
            opacity: 0;
        }
    }

    &-picked-other-answer-yes {
        opacity: .4;
    }
}

.answer-button-inner {
    transition: opacity 200ms ease-in-out;
    user-select: none !important;
}
</style>
