<template>
    <div class="center-ui-container">
        <question-container
            :answer-index-chosen="answerIndexChosen"
            :answer-points="answerPoints"
            :answer-points-index="answerIndexCorrect"
            :answer-show-result="answerShowResult"
            :answers="computedAnswers"
            :can-answer="computedCanAnswer"
            :can-use-lifelines="computedCanUseLifelines"

            :question="question"
            :seconds-left="secondsLeft"
            :seconds-total="secondsTotal"
            :show-answer1="showAnswer1"
            :show-answer2="showAnswer2"

            :show-answer3="showAnswer3"
            :show-answer4="showAnswer4"

            :show-question="showQuestion"

            :time-points="timePoints"

            :title="title"
            :used-life-lines="usedLifeLines"

            @answer:pick="onPickAnswer"
            @lifeline:request="onLifelineRequest"
        />
    </div>
</template>
<script>
import LeftUi from "@/components/ui/LeftUi/index.vue";
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";
import QuestionContainer from "@/components/ui/QuestionContainer/index.vue";
import {shuffle} from "@seriousgamesinteractive/utils/arrayUtil";
import {mapGetters} from "vuex";
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {TimerDurations} from '@/lib/timer-durations';

export default {
    name: 'QuestionContainerLive',
    components: {
        QuestionContainer,
        BootstrapSelect,
        LeftUi,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        question: {
            type: String,
            required: true,
        },
        secondsLeft: {
            type: Number,
            required: true,
        },
        secondsTotal: {
            type: Number,
            required: true,
        },
        answers: {
            type: Array,
            required: true,
        },
        showCorrectAnswer: {
            type: Boolean,
            required: false,
            default: false,
        },
        showQuestion: {
            type: Boolean,
            required: true,
        },
        showAnswer1: {
            type: Boolean,
            required: true,
        },
        showAnswer2: {
            type: Boolean,
            required: true,
        },
        showAnswer3: {
            type: Boolean,
            required: true,
        },
        showAnswer4: {
            type: Boolean,
            required: true,
        },
        canAnswer: {
            type: Boolean,
            required: true,
        },
        canUseLifelines: {
            type: Boolean,
            required: true,
        },
        timePoints: {
            type: Number,
            required: false,
            default: 0,
        },
        answerPoints: {
            type: Number,
            required: false,
            default: 0,
        },
        lifelineUsedHint: {
            type: Boolean,
            required: false,
            default: false,
        },
        lifelineUsedFiftyfifty: {
            type: Boolean,
            required: false,
            default: false,
        },
        lifelineUsedStats: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['answer:pick', 'answer:result', 'lifeline:request', 'lifeline:use'],

    watch: {
        lifelineUsedFiftyfifty() {
            if (!this.lifelineUsedFiftyfifty) {
                return;
            }

            this.useLifelineFiftyFifty();
        },
        lifelineUsedStats() {
            if (!this.lifelineUsedStats) {
                return;
            }

            this.useLifelineStats();
        },
        lifelineUsedHint() {
            if (!this.lifelineUsedHint) {
                return;
            }

            this.useLifelineHint();
        },
        question() {
            this.reset();
        },
        answers() {
            this.recomputeAnswers();
        },
        fiftyFiftyDisabledAnswerIndexes() {
            this.recomputeAnswers();
        },
        answerShowResult() {
            this.recomputeAnswers();
        },
        answerIndexChosen() {
            this.recomputeAnswers();
        },
        computedAnswerShowResult() {
            this.recomputeAnswers();
        },
    },
    data() {
        return {
            answerIndexChosen: null,
            answerShowResult: false,

            showAnswerTimeout: null,

            computedAnswers: [],
            fiftyFiftyDisabledAnswerIndexes: [],

            usedLifeLines: {
                [LifeLineIdentifier.Hint]: false,
                [LifeLineIdentifier.FiftyFifty]: false,
                [LifeLineIdentifier.Stats]: false,
            },
        }
    },
    mounted() {
        this.recomputeAnswers();
    },
    computed: {
        ...mapGetters(['fastMode']),
        answerIndexCorrect() {
            for (let i = 0, len = this.answers.length; i < len; i++) {
                if (this.answers[i].isCorrect) {
                    return i;
                }
            }

            return -1;
        },
        LifeLineIdentifier() {
            return LifeLineIdentifier
        },
        computedAnswerShowResult() {
            return this.answerShowResult || this.showCorrectAnswer;
        },
        computedCanUseLifelines() {
            if (!this.computedCanAnswer) {
                return false;
            }

            return this.canUseLifelines;
        },
        computedCanAnswer() {
            if (!this.canAnswer) {
                return false;
            }

            if (this.computedAnswerShowResult) {
                return false; // Can't do anything more, we already showed the answer
            }

            if (!this.showQuestion) {
                return false;
            }

            if (!this.showAnswer1 || !this.showAnswer2 || !this.showAnswer3 || !this.showAnswer4) {
                return false;
            }

            return true;
        },
    },
    methods: {
        reset() {
            this.answerIndexChosen = null;
            this.answerShowResult = false;

            if (this.showAnswerTimeout) {
                clearTimeout(this.showAnswerTimeout);

                this.showAnswerTimeout = null;
            }

            this.fiftyFiftyDisabledAnswerIndexes = [];

            this.recomputeAnswers();
        },
        recomputeAnswers() {
            console.log('recomputeAnswers');

            const answers = [];

            for (let i = 0; i < this.answers.length; i++) {
                const answer = this.answers[i];

                const isCorrect = answer.isCorrect;

                let state = null;

                if (this.fiftyFiftyDisabledAnswerIndexes.includes(i)) {
                    // Disabled by 50/50
                    state = 'disabled';
                } else if (this.computedAnswerShowResult) {
                    if (isCorrect) {
                        state = 'glow';
                    } else if (this.answerIndexChosen === i) {
                        state = 'glow-false';
                    } else {
                        state = 'disabled';
                    }
                } else if (this.answerIndexChosen === i) {
                    state = 'active';
                }

                answers.push({
                    text: answer.text,
                    id: answer.id,
                    isCorrect: isCorrect,
                    state: state,
                });
            }

            this.computedAnswers = answers;
        },
        onLifelineRequest(lifeLineIdentifier) {
            if (!this.computedCanAnswer) {
                return; // Can't answer right now
            }

            if (this.usedLifeLines[lifeLineIdentifier]) {
                // Life line already used

                return;
            }

            this.$emit('lifeline:request', lifeLineIdentifier);
        },
        useLifelineFiftyFifty() {
            const nonCorrectAnswerIndexes = [];

            for (let i = 0, len = this.computedAnswers.length; i < len; i++) {
                const answer = this.computedAnswers[i];

                if (!answer.isCorrect) {
                    nonCorrectAnswerIndexes.push(i);
                }
            }

            const answerIndexesShuffled = shuffle(nonCorrectAnswerIndexes);

            for (let i = 0; i < 2; i++) {
                this.fiftyFiftyDisabledAnswerIndexes.push(answerIndexesShuffled[i]);
            }

            this.recomputeAnswers();

            this.useLifeline(LifeLineIdentifier.FiftyFifty);
        },
        useLifelineHint() {
            this.useLifeline(LifeLineIdentifier.Hint);
        },
        useLifelineStats() {
            this.useLifeline(LifeLineIdentifier.Stats);
        },
        useLifeline(lifelineIdentifier) {
            this.usedLifeLines[lifelineIdentifier] = true;

            this.$emit('lifeline:use', lifelineIdentifier);
        },
        onPickAnswer(answerIndex) {
            if (!this.computedCanAnswer) {
                return; // Can't answer right now
            }

            if (this.answerIndexChosen === answerIndex) {
                return;
            }

            if (this.showAnswerTimeout) {
                clearTimeout(this.showAnswerTimeout);

                this.showAnswerTimeout = null;
            }

            this.answerIndexChosen = answerIndex;

            const answer = this.computedAnswers[answerIndex];

            this.$emit('answer:pick', {
                answer,
            });

            //const timeout = this.fastMode ? 1 : 30000;
            const timeout = TimerDurations.beforeAnswerResult;

            this.showAnswerTimeout = setTimeout(() => {
                this.answerShowResult = true;

                this.$emit('answer:result', {
                    isCorrect: answer.isCorrect,
                    answer,
                    answerIndex,
                });
            }, timeout);
        },
    },
}
</script>

<style lang="scss" scoped>
.center-ui-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}
</style>
