<template>
    <div :class="'kpi-' + identifier" class="kpi">
        <div :id="circleId" class="kpi-circle">
            <div :style="circleFillStyle" class="kpi-circle-fill">
                <div class="kpi-circle-fill-inner"/>
            </div>
            <div class="kpi-icon">
                <img :src="icon"/>
            </div>
        </div>
        <div class="kpi-label">
            <div class="kpi-label-inner">
                <label>{{ label }}</label>
            </div>
        </div>
    </div>
</template>

<script>
import {KpiIdentifier} from "@/enum/KpiIdentifier";

import IconHealthCoverage from "@/assets/icons/icon-universal-health-coverage.png"
import IconHealthEmergencyProtection from "@/assets/icons/icon-health-emergencies-protection.png"
import IconHealthierPopulations from "@/assets/icons/icon-healthier-populations.png"

export default {
    name: 'Kpi',
    props: {
        identifier: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
        circleId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            maxValue: 100,
        };
    },
    computed: {
        circleFillHeight() {
            return Math.round((this.value / this.maxValue) * 100) + '%';
        },
        circleFillStyle() {
            const scaleY = (this.value / this.maxValue);

            return {
                height: this.circleFillHeight
            };
        },
        icon() {
            if (this.identifier === KpiIdentifier.HealthCoverage) {
                return IconHealthCoverage;
            } else if (this.identifier === KpiIdentifier.HealthEmergencyProtection) {
                return IconHealthEmergencyProtection;
            } else if (this.identifier === KpiIdentifier.HealthierPopulations) {
                return IconHealthierPopulations;
            } else {
                return null;
            }
        },
        label() {
            if (this.identifier === KpiIdentifier.HealthCoverage) {
                return this.$t('game.kpiHealthCoverage');
            } else if (this.identifier === KpiIdentifier.HealthEmergencyProtection) {
                return this.$t('game.kpiHealthEmergencyProtection');
            } else if (this.identifier === KpiIdentifier.HealthierPopulations) {
                return this.$t('game.kpiHealthierPopulations');
            } else {
                return 'N/A';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$circleFillOffset: 6px;
$circleSize: 172px;
$circleFillSize: $circleSize - ($circleFillOffset * 2);

.kpi {
    width: 245px;
    height: 250px;
    cursor: help;

    &:hover {
        .kpi-label {
            opacity: 1;
        }
    }
}

.kpi-circle {
    background: url("@/assets/ui/bg-kpi.png");
    width: $circleSize;
    height: $circleSize;
    margin: 0 auto;
    padding: 6px;
    position: relative;

    .kpi-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .kpi-circle-fill {
        width: $circleFillSize;
        overflow: hidden;
        position: absolute;
        bottom: $circleFillOffset;
        transition: 200ms ease-out;

        .kpi-circle-fill-inner {
            width: $circleFillSize;
            height: $circleFillSize;
            background: #80BC00;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.kpi-label {
    background: url("@/assets/ui/bg-kpi-label.png");
    width: 245px;
    height: 73px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    padding: 17px 6px 11px;

    .kpi-label-inner {
        position: relative;
        width: 100%;
        height: 100%;
    }

    label {
        position: absolute;
        line-height: 18px;
        text-align: center;
        font-size: 14pt;
        font-weight: bold;
        width: 100%;
        height: fit-content;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.kpi-circle {
    transform-origin: center;
}
</style>
