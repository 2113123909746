<template>
    <div :class="'answer-icon-' + answerIndex" class="answer-icon">
        <no-drag-image :src="answerIcon"/>
    </div>
</template>

<script>
import AnswerIconA from "@/assets/icons/icon-question-a.png";
import AnswerIconB from "@/assets/icons/icon-question-b.png";
import AnswerIconC from "@/assets/icons/icon-question-c.png";
import AnswerIconD from "@/assets/icons/icon-question-d.png";
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";

export default {
    name: 'AnswerIcon',
    components: {
        NoDragImage,
    },
    props: {
        answerIndex: {
            type: Number,
            required: true,
        },
    },
    computed: {
        answerIcon() {
            if (this.answerIndex === 0) {
                return AnswerIconA;
            } else if (this.answerIndex === 1) {
                return AnswerIconB;
            } else if (this.answerIndex === 2) {
                return AnswerIconC;
            } else if (this.answerIndex === 3) {
                return AnswerIconD;
            }

            return null;
        }
    },
}
</script>
