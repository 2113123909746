<template>
    <amr-popup class="challenge-a-friend-popup" :class="'challenge-a-friend-popup-' + language" height="xs" width="xs">
        <template v-slot:content>
            <p class="share-text"><markdown-text>{{ $t('end.challengeAFriend') }}</markdown-text></p>

            <div class="input-group">
                <input ref="input-share-url" :value="computedShareUrl" class="form-control" readonly type="text">
                <div class="input-group-append">
                    <button class="btn btn-right btn-copy" @click="onClickCopy">
                        <no-drag-image src="@/assets/icons/icon-clipboard.png"/>
                    </button>
                </div>
            </div>

            <p :class="'status-message-container-' + (popStatusShow ? 'show' : 'hide')"
               class="status-message-container">{{ popStatusMessage }}</p>
        </template>

        <template v-slot:footer-buttons>
            <app-button-row class="button-container" size="xs">
                <app-button @click="onClickOk">{{ $t('generic.buttonOk') }}</app-button>
            </app-button-row>
        </template>
    </amr-popup>
</template>

<script>
import AppButton from "@/components/common/AppButton.vue";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";
import AmrPopup from '@/components/ui/AmrPopup.vue';
import MarkdownText from "@/components/common/MarkdownText";

export default {
    name: 'ChallengeFriendPopup',
    components: {
        MarkdownText,
        LifeLineButton,
        NoDragImage, AmrPopup, AppButtonRow, TimeLeft, AppButton
    },
    props: {
        masterQuizId: {
            type: String,
            required: true,
        },
        quizLanguage: {
            type: String,
            required: true,
        },
    },
    emits: ['click:ok'],
    data() {
        return {
            popStatusShow: false,
            popStatusTimeout: null,
            popStatusMessage: null,
        };
    },
    computed: {
        language() {
            return this.$i18n.locale;
        },
        computedShareUrl() {
            const resolvedUrl = this.$router.resolve({
                name: 'quiz-select-language',
                params: {
                    'masterQuizId': this.masterQuizId,
                }
            });

            const {protocol, hostname, port, pathname} = window.location;

            const protocolNormalized = protocol.toLowerCase();
            const defaultPort = (protocolNormalized === 'http') ? 80 : 443;

            if (port && defaultPort !== port) {
                return `${protocol}//${hostname}:${port}${pathname}${resolvedUrl.href}`
            } else {
                return `${protocol}//${hostname}:${pathname}${resolvedUrl.href}`
            }
        }
    },
    methods: {
        onClickCopy() {
            const inputShareUrl = this.$refs['input-share-url'];

            inputShareUrl.focus();
            inputShareUrl.select();
            inputShareUrl.setSelectionRange(0, 99999);

            if (navigator.clipboard) {
                navigator.clipboard.writeText(this.computedShareUrl)

                this.popStatus(this.$t('end.challengeAFriendLinkCopied'));
            } else {
                alert('Could not write to clipboard');
            }
        },
        popStatus(statusMessage) {
            if (this.popStatusTimeout) {
                clearTimeout(this.popStatusTimeout);
            }

            this.popStatusShow = true;
            this.popStatusMessage = statusMessage;

            this.popStatusTimeout = setTimeout(() => {
                this.popStatusShow = false;
            }, 5000);
        },
        onClickOk() {
            this.$emit('click:ok');
        },
    },
}
</script>

<style lang="scss" scoped>

.challenge-a-friend-popup {
    .status-message-container {
        transition: 200ms ease-in-out;

        &-hide {
            opacity: 0;
        }

        &-show {
            opacity: 1;
        }

        padding-top: 10px;
        padding-bottom: 0;
        margin: 0;
        font-size: 16pt;
        font-weight: bold;
    }

    p {
        font-size: 23pt;
        padding-top: 30px;
        padding-bottom: 15px;
        margin: 0;
    }

    .input-group {
        padding: 0 50px;
    }
}

.btn-right {
    background: url("@/assets/buttons/btn-right.png");
    width: 120px;
    height: 59px;
    color: white;
    position: relative;
    cursor: pointer;
    outline: none !important;

    &:hover {
        top: 1px;
    }

    &:active {
        top: 2px;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.share-text {
    padding: 0 20px;
}
</style>
