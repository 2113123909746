<template>
    <div style="margin-top: 100px;">
        <markdown-text>{{ markdownText }}</markdown-text>
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <div class="i18n-menu">
            <div class="mb-4">
                <label class="me-4">Language</label>
                <bootstrap-button-group
                    :values="messageLocales"
                    v-model="$i18n.locale"
                    :inactive-class="'btn-dark'"
                />
            </div>
        </div>
    </dev-settings-panel>
</template>

<script>
import MarkdownText from "@/components/common/MarkdownText/index.vue";
import DevSettingsPanel from "@seriousgamesinteractive/components-vue3/DevComponent/DevSettingsPanel.vue";
import BootstrapButtonGroup from "@seriousgamesinteractive/components-vue3/bootstrap/BootstrapButtonGroup.vue";

export default {
    name: 'MarkdownMemberStateTableDev',
    computed: {
        markdownText() {
            return this.$i18n.t('generic.countryListTable', this.$i18n.locale);
        },
        messageLocales() {
            return this.$i18n.availableLocales;
        },
    },
    components: {
        BootstrapButtonGroup,
        DevSettingsPanel,
        MarkdownText,
    }
}
</script>
