<template>
    <div :class="loading ? 'previous-answers-chart-loading' : ''" class="previous-answers-chart">
        <div class="chart-area">
            <div class="chart-background">
                <div v-for="n in 10" :style="{ top: ((n - 1) * 10) + '%' }" class="chart-background-line">
                    <div class="chart-background-line-inner"/>
                </div>
            </div>
            <div class="chart-bars d-flex justify-content-evenly align-items-end">
                <div v-for="n in 4" class="chart-bar-container d-flex">
                    <div :style="chartBarStyle[n - 1]" class="chart-bar-outer align-self-end">
                        <div class="chart-bar-label">{{ formatPercent(chartBarPercent[n - 1]) }}</div>
                        <div class="chart-bar">
                            <div class="chart-bar-inner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="chart-footer">
            <div class="chart-labels d-flex justify-content-evenly align-items-top">
                <div v-for="n in 4" class="chart-label-container">
                    <answer-icon :answer-index="n - 1"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from "@/components/common/AppButton.vue";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import AnswerIcon from "@/components/ui/AnswerIcon.vue";

export default {
    name: 'PreviousAnswersChart',
    components: {MarkdownHoverText, AppButtonRow, TimeLeft, AppButton, AnswerIcon},
    props: {
        answerCountA: {
            type: Number,
            required: true,
        },
        answerCountB: {
            type: Number,
            required: true,
        },
        answerCountC: {
            type: Number,
            required: true,
        },
        answerCountD: {
            type: Number,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    watch: {
        answerCountA() {
            this.recompute();
        },
        answerCountB() {
            this.recompute();
        },
        answerCountC() {
            this.recompute();
        },
        answerCountD() {
            this.recompute();
        },
    },
    data() {
        return {
            chartBarStyle: [
                null,
                null,
                null,
                null
            ],
            chartBarPercent: [0, 0, 0, 0],
        };
    },
    mounted() {
        this.recompute();
    },
    methods: {
        recompute() {
            let total = this.answerCountA + this.answerCountB + this.answerCountC + this.answerCountD;

            if (total <= 0) {
                for (let i = 0; i < 4; i++) {
                    this.chartBarPercent[i] = 0;
                }
            } else {
                this.chartBarPercent[0] = (this.answerCountA / total) * 100;
                this.chartBarPercent[1] = (this.answerCountB / total) * 100;
                this.chartBarPercent[2] = (this.answerCountC / total) * 100;
                this.chartBarPercent[3] = (this.answerCountD / total) * 100;
            }

            for (let i = 0; i < 4; i++) {
                this.chartBarStyle[i] = {
                    height: this.chartBarPercent[i] + '%'
                };
            }
        },
        formatPercent(percent) {
            return Math.round(percent) + '%';
        },
    },
}
</script>

<style lang="scss" scoped>
$chartFooterHeight: 25%;
$backgroundLineHeight: 5px;

.previous-answers-chart {
    width: 100%;
    height: 100%;
    position: relative;

    &-loading {
        .chart-bar {
            opacity: .3;
        }
    }
}

.chart-footer {
    background: linear-gradient(30deg, #009DE1, #00567C);
    padding: 15px;
}

.chart-bars, .chart-labels {
    width: 70%;
    height: 100%;
    margin: 0 auto;
}

.chart-labels {
    gap: 20px;
}

.chart-area {
    position: relative;
    height: calc(100% - $chartFooterHeight);

    .chart-background {
        width: 100%;
        height: 100%;
    }

    .chart-background, .chart-bars {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .chart-background-line {
        position: absolute;
        width: 100%;

        .chart-background-line-inner {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 50%)),
            linear-gradient(0deg, #736655, #4D3836);

            height: $backgroundLineHeight;
            position: relative;
            transform: translateY($backgroundLineHeight / -2);
        }
    }
}

.chart-label-container, .chart-bar-container {
    width: 80px;
}

.chart-bar-container {
    width: 80px;
    height: 100%;
}

.chart-bar-outer {
    width: 100%;
    height: 100%;
    transition: 500ms cubic-bezier(0.7, -0.3, 0.3, 1.2);
}

.chart-bar {
    width: 100%;
    height: 100%;
    position: relative;
    top: -40px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(-25deg, #6A2600, #F9FFB8, #9B3800);
    transition: 200ms cubic-bezier(0.7, -0.3, 0.3, 1.2);

    .chart-bar-inner {
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, #FF9900, #FFFEFA, #FFB800);
        border-radius: 10px 10px 0 0;
    }
}

.chart-bar-label {
    height: 40px;
    position: relative;
    top: -40px;
    font-weight: bold;
    font-size: 22pt;
    text-shadow: -2px 4px 7px rgba(0, 0, 0, 0.5);
}

.chart-footer {
    height: $chartFooterHeight;
}
</style>
