// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/ui/tooltip-left.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/ui/tooltip-right.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".points-tooltip[data-v-2ebd2ed5] {\n  width: 258px;\n  height: 149px;\n  font-size: 40pt;\n  line-height: 67px;\n  font-weight: bold;\n  color: white;\n  opacity: 0;\n  transition: 250ms ease-in-out;\n}\n.points-tooltip.points-tooltip-show[data-v-2ebd2ed5] {\n  opacity: 1;\n}\n.points-tooltip-direction-left[data-v-2ebd2ed5] {\n  padding: 35px 43px 47px 55px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.points-tooltip-direction-right[data-v-2ebd2ed5] {\n  padding: 35px 55px 47px 43px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
