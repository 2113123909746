<template>
    <div class="intro-view pt-4">
        <app-logo :label="$t('intro.logoText')" class="app-logo" @click="onClickAppLogo"/>

        <version-container :attach-corner="true" :x-offset="10" :y-offset="10" attachment-point="bottomRight"
                           @click="onClickVersion"/>

        <div class="language-button-container">
            <h2>{{ $t('intro.selectQuiz', 'en') }} / {{ $t('intro.selectQuiz', 'de') }} / {{ $t('intro.selectQuiz', 'ru') }}</h2>

            <div v-if="loading" class="loading-message">
                {{ $t('generic.loading', 'en') }} / {{ $t('generic.loading', 'de') }} / {{ $t('generic.loading', 'ru') }}
            </div>
            <div v-else>
                <app-button-row class="quiz-buttons">
                    <app-button
                        v-for="quiz in quizzes"
                        :state="quizId === quiz.id ? 'active' : null"
                        @click="selectQuiz(quiz.id)"
                    >{{ quiz.title }}
                    </app-button>
                </app-button-row>
            </div>
        </div>

        <div class="disclaimer-container">
            <disclaimer/>
        </div>
    </div>
</template>
<script>
import AppButton from "@/components/common/AppButton.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import AppLogo from "@/components/ui/AppLogo.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import {Lightbox, TextFit} from "@seriousgamesinteractive/components-vue3/common";
import LifelineConfirmationPopup from "@/components/ui/LifelineConfirmationPopup/index.vue";
import HintHelpPopup from "@/components/ui/HintHelpPopup/index.vue";
import Disclaimer from "@/components/ui/Disclaimer.vue";
import VersionContainer from "@/components/ui/VersionContainer.vue";

export default {
    name: 'QuizListView',
    components: {
        Disclaimer,
        HintHelpPopup, LifelineConfirmationPopup, Lightbox,
        TextFit,
        MarkdownHoverText,
        AppLogo,
        AppButtonRow,
        AppButton,
        VersionContainer,
    },
    data() {
        return {
            loading: true,
            quizzes: [],
            quizId: null,

            showPopup: false,
        };
    },
    mounted() {
        this.loading = true;

        this.$apiClient.quiz.getQuizzes().then((quizzes) => {
            console.log('quizzes', quizzes);

            this.quizzes = quizzes;
            this.loading = false;
        });
    },
    methods: {
        onClickAppLogo() {
            console.log(this);
        },
        selectQuiz(quizId) {
            this.$router.push(`/quiz/${quizId}`)
        },
        onClickVersion() {
            this.throwRandomError();
        }
    },
};
</script>

<style lang="scss" scoped>
.app-logo, .language-button-container, .disclaimer-container {
    transition: 800ms cubic-bezier(0.7, -0.3, 0.3, 1.2);
}

.app-logo {
    position: absolute;
    top: 210px;
    left: 50%;
    transform: translateX(-50%);
}

.language-button-container {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
        margin-bottom: 50px;
        font-size: 35pt;
    }
}

.disclaimer-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 80%;
}

.intro-language-committed {
    .app-logo {
        top: -500px;
        opacity: 0;
    }

    .language-button-container {
        bottom: -500px;
        opacity: 0;
    }

    .disclaimer-container {
        bottom: -100px;
    }
}

.loading-message {
    font-size: 25pt;
    font-weight: bold;
}
</style>
