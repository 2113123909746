// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/ui/bg-points.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".end-popup[data-v-02d76922] {\n  width: 1200px;\n}\n.end-popup .footer-buttons[data-v-02d76922] {\n  position: absolute;\n  bottom: -40px;\n  width: 100%;\n}\nh1[data-v-02d76922] {\n  padding: 10px;\n  font-size: 35pt;\n  font-weight: bold;\n  margin-top: 10px;\n  margin-bottom: 0;\n}\n.end-popup-content[data-v-02d76922] {\n  position: relative;\n}\n.end-popup-content .content-text[data-v-02d76922] {\n  text-align: left !important;\n}\n.end-popup-content .content[data-v-02d76922] {\n  padding-top: 50px;\n  padding-bottom: 50px;\n}\n.end-popup-content .content-kpis[data-v-02d76922] {\n  margin-bottom: 50px;\n}\n.end-popup-content .content-text[data-v-02d76922] {\n  font-size: 18pt;\n  width: 1000px;\n  text-align: center;\n  margin: 0 auto;\n}\n.end-popup-content .content-kpi label[data-v-02d76922] {\n  font-weight: bold;\n  font-size: 30pt;\n}\n.end-popup-content .content-kpi .points-container[data-v-02d76922] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 373px;\n  height: 87px;\n  text-align: center;\n  font-size: 40pt;\n  font-weight: bold;\n  line-height: 87px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
