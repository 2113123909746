<template>
    <amr-popup v-if="loading">
        <template v-slot:icon>
            <life-line-button :identifier="LifeLineIdentifier.Stats" :is-interactable="false"/>
        </template>

        <template v-slot:content>
            <div class="loading-content-inner">
                <p>{{ $t('game.lifelineHowPreviousPlayersAnswered') }}</p>

                <p>{{ $t('generic.loading') }}</p>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <app-button-row class="button-container" size="xs">
                <app-button @click="onClickOk">{{ $t('generic.buttonOk') }}</app-button>
            </app-button-row>
        </template>
    </amr-popup>

    <previous-answers-popup
        v-else
        :answer-count-a="answerCounts[answerIds[0]] || 0"
        :answer-count-b="answerCounts[answerIds[1]] || 0"
        :answer-count-c="answerCounts[answerIds[2]] || 0"
        :answer-count-d="answerCounts[answerIds[3]] || 0"
        @timeout="onTimeout"
        @click:ok="onClickOk"
    />
</template>
<script>
import AppButton from "@/components/common/AppButton.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";
import PreviousAnswersPopup from "@/components/ui/PreviousAnswersPopup/index.vue";
import AmrPopup from "@/components/ui/AmrPopup.vue";

export default {
    name: 'PreviousAnswersPopupLive',
    computed: {
        LifeLineIdentifier() {
            return LifeLineIdentifier
        }
    },
    components: {AppButtonRow, AppButton, LifeLineButton, AmrPopup, PreviousAnswersPopup},
    props: {
        masterQuizId: {
            type: Number,
            required: true,
        },
        language: {
            type: String,
            required: true,
        },
        questionNumber: {
            type: Number,
            required: true,
        },
        answerIds: {
            type: Array,
            required: true,
        }
    },
    mounted() {
        if (this.masterQuizId && this.language) {
            this.reload();
        }
    },
    data() {
        return {
            loading: false,
            answerCounts: [-1, -1, -1, -1],
        }
    },
    watch: {
        masterQuizId() {
            this.reload();
        },
        language() {
            this.reload();
        },
    },
    emits: ['click:ok', 'timeout'],
    methods: {
        reload() {
            this.loading = true;

            this.answerCounts = [-1, -1, -1, -1];

            this.$apiClient.quiz.getAnswerCountsQuizStats(this.masterQuizId, this.language, this.questionNumber).then((answerCounts) => {
                this.answerCounts = answerCounts;

                this.loading = false;
            })
        },
        onTimeout() {
            this.$emit('timeout');
        },
        onClickOk() {
            this.$emit('click:ok');
        },
    },
}
</script>

<style lang="scss" scoped>
.loading-content-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20pt;
}
</style>
