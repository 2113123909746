<template>
    <div ref="canvas-container" class="ar-full canvas-container">
        <canvas ref="canvas"></canvas>
    </div>
</template>

<script>

import {randomBetween} from '@seriousgamesinteractive/utils/randomUtil';
import {mapGetters} from 'vuex';

class Particle {
    x = 0;
    y = 0;

    radius = 0;

    speed = 0;
    angle = 0;

    value = 0;

    destinationX = 0;
    destinationY = 0;

    color = '#000000';

    phase = 'slowdown';

    slowdownRate = 0.96; // Modify this to your needs
    //minSpeedToAccelerate = 0.2;
    minSpeedToAccelerate = 0.2;

    //slowdownRate = 0.999; // Modify this to your needs
    //minSpeedToAccelerate = 0.01;

    constructor({origin, radius, speed, angle, destination, color, value}) {
        this.x = origin.x;
        this.y = origin.y;

        this.radius = radius;

        this.speed = speed;

        this.angle = angle

        this.destinationX = destination.x;
        this.destinationY = destination.y;

        this.color = color;

        this.value = value;
    }

    update() {
        // Calculate acceleration towards the destination
        const dx = this.destinationX - this.x;
        const dy = this.destinationY - this.y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        const accel = 0.0001;

        if (this.phase === 'slowdown') {
            this.speed *= this.slowdownRate;
            if (this.speed <= this.minSpeedToAccelerate) {
                this.phase = 'accelerate';
                // Calculate the angle towards the destination
                this.angle = Math.atan2(dy, dx);
            }
        } else if (this.phase === 'accelerate') {
            // Update speed based on angle, acceleration
            this.speed += accel * dist;

            this.speed = Math.max(1, this.speed);
        }

        this.x += Math.cos(this.angle) * this.speed;
        this.y += Math.sin(this.angle) * this.speed;
    }
}

export default {
    name: 'PointsParticles',
    props: {
        originElement: {
            required: true,
        },
        destinationElement: {
            required: true,
        },
        amount: {
            type: Number,
            required: true,
        },
        totalValue: {
            type: Number,
            required: true,
        },
        destinationRadius: {
            type: Number,
            required: false,
            default: 0,
        },
        explosionScale: {
            type: Number,
            required: false,
            default: 1,
        },
    },
    mounted() {
        this.canvas = this.$refs.canvas;

        this.ctx = this.canvas.getContext('2d');

        this.resizeCanvas();

        window.addEventListener('resize', this.onWindowResize);

        this.explode();
    },
    unmounted() {
        window.removeEventListener('resize', this.onWindowResize);

        this.detach();

        this.cancelAnimationFrame();
    },
    emit: ['particle:hit', 'animation:complete'],
    data() {
        return {
            canvas: null,
            ctx: null,

            particles: [],

            particleState: null,
            particleStateChangeTimer: null,

            destinationRadiusGrow: 0,

            originPosition: null,
            originRect: null,
            destinationPosition: null,
            destinationRect: null,
        };
    },
    computed: {
        ...mapGetters(['aspectRatioScale']),
        computedDestinationRadius() {
            return this.destinationRadius || (this.destinationRect.width / 2);
        },
        computedDestinationRadiusWithScale() {
            return this.computedDestinationRadius * this.aspectRatioScale;
        },
    },
    methods: {
        attach() {
            if (this.destinationElement) {
                this.destinationElement.style.transition = '';
                this.destinationElement.style.transform = 'scale(1)';
            }
        },
        detach() {
            if (this.destinationElement) {
                this.destinationElement.style.transition = '500ms ease-in-out';
                this.destinationElement.style.transform = '';
            }
        },
        generateParticleColor() {
            // Generate a random red value between 200 and 255
            const red = Math.floor(Math.random() * 56) + 200;

            // Generate a random green value between 200 and 255
            const green = Math.floor(Math.random() * 56) + 200;

            // Generate a random blue value between 0 and 100
            const blue = Math.floor(Math.random() * 101);

            // Convert the RGB values to a hex string
            const hexRed = red.toString(16).padStart(2, '0');
            const hexGreen = green.toString(16).padStart(2, '0');
            const hexBlue = blue.toString(16).padStart(2, '0');

            // Concatenate the hex values
            const hexColor = `#${hexRed}${hexGreen}${hexBlue}`;

            return hexColor;
        },
        onWindowResize() {
            this.resizeCanvas();
        },
        resizeCanvas() {
            this.canvas.width = window.innerWidth;
            this.canvas.height = window.innerHeight;
        },
        explode() {
            if (this.particleStateChangeTimer) {
                clearTimeout(this.particleStateChangeTimer);

                this.particleStateChangeTimer = null;
            }

            this.particles = [];

            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            this.cancelAnimationFrame();

            // Calculate origin rect
            this.originRect = this.originElement.getBoundingClientRect();

            this.originPosition = {
                x: this.originRect.x + (this.originRect.width / 2),
                y: this.originRect.y + (this.originRect.height / 2),
            };

            // Calculate destination rect
            this.destinationRect = this.destinationElement.getBoundingClientRect();

            this.destinationPosition = {
                x: this.destinationRect.x + (this.destinationRect.width / 2),
                y: this.destinationRect.y + (this.destinationRect.height / 2),
            };

            //this.computedDestinationRadius = this.destinationRadius || (this.destinationRect.width / 2);

            this.attach();

            const particleValue = this.totalValue / this.amount;

            console.log({
                totalValue: this.totalValue,
                amount: this.amount,
                particleValue: particleValue,
            });

            for (let i = 0; i < this.amount; i++) {
                const angle = randomBetween(0, 2 * Math.PI);
                const speed = randomBetween(2, 8) * this.aspectRatioScale * this.explosionScale;
                const radius = randomBetween(3, 15) * this.aspectRatioScale * this.explosionScale;

                const color = this.generateParticleColor();

                const particle = new Particle({
                    origin: this.originPosition,
                    radius,
                    speed,
                    angle,
                    destination: this.destinationPosition,
                    color,
                    value: particleValue,
                });

                this.particles.push(particle);
            }

            this.draw();

            this.clearForceEndTimeout();
            this.forceEndTimeout = setTimeout(this.forceEnd, 5000);
        },
        stop() {
            if (this.particleStateChangeTimer) {
                clearTimeout(this.particleStateChangeTimer);

                this.particleStateChangeTimer = null;
            }

            this.clearForceEndTimeout();
            this.cancelAnimationFrame();

            this.particles = [];

            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        },
        clearForceEndTimeout() {
            if (this.forceEndTimeout) {
                clearTimeout(this.forceEndTimeout);

                this.forceEndTimeout = null;
            }
        },
        forceEnd() {
            this.stop();

            this.$emit('animation:complete');
        },
        updateParticles() {
            const particleCountOriginal = this.particles.length;

            if (particleCountOriginal <= 0) {
                return;
            }

            for (let i = particleCountOriginal - 1; i >= 0; i--) {
                const particle = this.particles[i];

                particle.update();

                const xDiff = this.destinationPosition.x - particle.x;
                const yDiff = Math.abs(this.destinationPosition.y - particle.y);

                if ((xDiff < this.computedDestinationRadiusWithScale && yDiff < this.computedDestinationRadiusWithScale) || xDiff < 0) {
                    this.particles.splice(i, 1);

                    this.particleHit(particle);
                }
            }

            //console.log('particleCount', this.particles.length);

            if (this.particles.length === 0) {
                this.stop();

                this.$emit('animation:complete');
            }
        },
        cancelAnimationFrame() {
            if (this.animationFrameRequestId) {
                cancelAnimationFrame(this.animationFrameRequestId);

                this.animationFrameRequestId = null;
            }
        },
        draw() {
            //console.log('draw' + this.amount + Math.random());
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            this.updateParticles();

            /*this.ctx.fillStyle = `rgba(255, 0, 0, .5)`;

            this.ctx.beginPath();
            this.ctx.arc(this.destination.x, this.destination.y, this.destinationRadius + this.destinationRadiusGrow, 0, 2 * Math.PI);
            this.ctx.fill();*/

            for (const particle of this.particles) {
                this.ctx.fillStyle = particle.color;

                this.ctx.beginPath();
                this.ctx.arc(particle.x, particle.y, particle.radius, 0, 2 * Math.PI);
                this.ctx.fill();
            }

            this.destinationRadiusGrow *= 0.8;

            const growScale = Math.min(1.5, 1 + (this.destinationRadiusGrow / this.destinationRadius));

            this.destinationElement.style.transform = `scale(${growScale})`;

            this.animationFrameRequestId = requestAnimationFrame(this.draw);
        },
        particleHit(particle) {
            // You can emit this event to parent component or handle it here
            // this.$emit("particleHit");

            this.destinationRadiusGrow += (particle.value / this.destinationRadius) * 100;

            this.$emit('particle:hit', particle);
        }
    }
};
</script>

<style lang="scss" scoped>
.canvas-container {
    &, canvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        pointer-events: none !Important;
    }
}
</style>
