import {Howl, Howler} from 'howler';
import {getConsole, getMockConsole} from "@seriousgamesinteractive/utils/console/getConsole";

export default class {
    sounds = {};
    soundIdentifiers = {};
    soundGroup = {};

    constructor(useDebugConsole = false) {
        this.setUseDebugConsole(useDebugConsole);
    }

    setUseDebugConsole(useDebugConsole, introMessage = null, textColor = 'white', backgroundColor = '#00aaaa') {
        if (useDebugConsole) {
            this.console = getConsole('SoundPlayer', textColor, backgroundColor);

            if (introMessage) {
                this.console.log(introMessage);
            }
        } else {
            this.console = getMockConsole();
        }
    }

    loadSound(identifier, soundSource, group) {
        this.sounds[identifier] = new Howl({
            src: soundSource,
            loop: group === 'music',
            volume: group === 'music' ? 0.3 : 0.8,
        })

        this.console.log(`loadSound(${identifier})`);

        this.soundIdentifiers = Object.keys(this.sounds);
    }

    setVolume(volume) {
        Howler.volume = volume;
    }

    getSound(identifier) {
        if (!this.soundIdentifiers.includes(identifier)) {
            throw new Error(`Could not find sound by identifier ${identifier}`);
        }

        return this.sounds[identifier];
    }

    playSound(identifier, groupIdentifier = null) {
        const sound = this.getSound(identifier);

        if (groupIdentifier) {
            if (!this.soundGroup[groupIdentifier]) {
                this.soundGroup[groupIdentifier] = [];
            }

            this.soundGroup[groupIdentifier].push(identifier);
        }

        this.console.log(`PlaySound(${identifier}, ${groupIdentifier || 'null'})`);

        sound.play();
    }

    playSoundOnce(identifier, groupIdentifier) {
        if (groupIdentifier) {
            this.stopSoundGroup(groupIdentifier);
        }

        this.stopSound(identifier, groupIdentifier);
        this.playSound(identifier, groupIdentifier);
    }

    stopSoundGroup(groupIdentifier) {
        console.log('groupIdentifier', this.soundGroup[groupIdentifier]);

        if (this.soundGroup[groupIdentifier]) {
            for (const soundIdentifier of this.soundGroup[groupIdentifier]) {
                this.stopSound(soundIdentifier);
            }
        }

        // Reset group
        this.soundGroup[groupIdentifier] = [];
    }

    stopSound(identifier, groupIdentifier = null) {
        const sound = this.getSound(identifier);

        if (sound) {
            sound.stop();
        }

        if (groupIdentifier) {
            if (this.soundGroup[groupIdentifier]) {
                this.soundGroup[groupIdentifier] = this.soundGroup[groupIdentifier].filter((val) => {
                    return val !== identifier;
                })
            }
        }
    }

    unloadSound(identifier) {
        const sound = this.getSound(identifier);

        this.console.log(`UnloadSound(${identifier})`);

        //delete this.sounds[identifier];
    }

    mute() {
        Howler.mute(true);
    }

    unmute() {
        Howler.mute(false);
    }
}
