<template>
    <div class="who-logo logo">
        <transition name="fade" mode="out-in">
            <img v-if="region === 'de'" src="@/assets/logos/de.who.png"/>
            <img v-else-if="region === 'ru'" src="@/assets/logos/ru.who.png" />
            <img v-else src="@/assets/logos/eu.who.png"/>
        </transition>
    </div>
</template>
<script>
export default {
    name: 'WhoLogo',
    props: {
        region: {
            type: String,
            required: true,
        }
    },
}
</script>
<style lang="scss" scoped>
.who-logo {
    height: 143px;
}
</style>
