<template>
    <div class="time-left-dev-container">
        <time-left :seconds-left="secondsLeft" :seconds-total="secondsTotal"/>
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <div class="row">
            <label>Seconds left ({{ secondsLeft }})</label>
            <input v-model.number="secondsLeft" :max="secondsTotal" min="0" type="range"/>
        </div>

        <div class="row">
            <label>Seconds total ({{ secondsTotal }})</label>
            <input v-model.number="secondsTotal" max="100" min="0" type="range"/>
        </div>
    </dev-settings-panel>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>

import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";

export default {
    name: 'TimeLeftDev',
    components: {
        TimeLeft,
        BootstrapSelect,
        DevSettingsPanel,
        EventTicks,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        return {
            secondsLeft: 30,
            secondsTotal: 60,
        }
    },
}
</script>

<style lang="scss" scoped>
.time-left-dev-container {
    margin-top: 200px;
    position: relative;
}
</style>
