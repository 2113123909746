<template>
    <div :class="['points-tooltip-direction-' + direction, show ? 'points-tooltip-show' : '']" class="points-tooltip">
        {{ formattedPoints }}
    </div>
</template>

<script>
export default {
    name: 'PointsTooltip',
    props: {
        points: {
            type: Number,
            required: true,
        },
        direction: {
            type: String,
            required: true,
        }
    },
    computed: {
        formattedPoints() {
            if (this.points > 0) {
                return '+' + this.points;
            } else if (this.points === 0) {
                return '0';
            } else {
                // Negative
                return this.points;
            }
        }
    },
    data() {
        return {
            show: false,
            fadeTimeout: null,
        };
    },
    watch: {
        points() {
            this.reset();
        },
    },
    mounted() {
        this.reset();
    },
    unmounted() {
        this.clearTimeout();
    },
    methods: {
        clearTimeout() {
            if (this.fadeTimeout) {
                clearInterval(this.fadeTimeout);

                this.fadeTimeout = null;
            }
        },
        reset() {
            this.clearTimeout();

            this.show = true;

            this.fadeTimeout = setTimeout(() => {
                this.show = false;

                this.clearTimeout();
            }, 60000);
        }
    }
}
</script>

<style lang="scss" scoped>
.points-tooltip {
    width: 258px;
    height: 149px;
    font-size: 40pt;
    line-height: 67px;
    font-weight: bold;
    color: white;
    opacity: 0;
    transition: 250ms ease-in-out;

    &.points-tooltip-show {
        opacity: 1;
    }

    &-direction-left {
        padding: 35px 43px 47px 55px;
        background: url("@/assets/ui/tooltip-left.png") no-repeat;
    }

    &-direction-right {
        padding: 35px 55px 47px 43px;
        background: url("@/assets/ui/tooltip-right.png") no-repeat;
    }
}
</style>
