import Audio_Aj_Bg from "@/assets/audio/bgmusic.mp3";
import Audio_Aj_Correct from "@/assets/audio/correct.mp3";
import Audio_Aj_Incorrect from "@/assets/audio/incorrect.mp3";
import Audio_Aj_FinalAnswer from "@/assets/audio/finalanswer.mp3";

export const AudioIdentifiers = {
    BG_100_1000: 'bg-100-1000',
    /*BG_2000_32000: 'bg-2000-32000',
    BG_64000: 'bg-64000',
    BG_125000_250000: 'bg-125000-250000',
    BG_500000: 'bg-500000',
    BG_1000000: 'bg-1000000',
    COMMERCIAL_BREAK: 'commercial-break',*/
    CORRECT_ANSWER: 'correct-answer',
    FINAL_ANSWER: 'final-answer',
    //LETS_PLAY: 'lets-play',
    //PHONE_A_FRIEND: 'phone-a-friend',
    WRONG_ANSWER: 'wrong-answer'
};

export const AudioSounds = {
    [AudioIdentifiers.BG_100_1000]: Audio_Aj_Bg,
    /*[AudioIdentifiers.BG_2000_32000]: Audio_Bg_2000_32000,
    [AudioIdentifiers.BG_64000]: Audio_Bg_64000,
    [AudioIdentifiers.BG_125000_250000]: Audio_Bg_125000_250000,
    [AudioIdentifiers.BG_500000]: Audio_Bg_500000,
    [AudioIdentifiers.BG_1000000]: Audio_Bg_1000000,
    [AudioIdentifiers.COMMERCIAL_BREAK]: Audio_CommercialBreak,*/
    [AudioIdentifiers.CORRECT_ANSWER]: Audio_Aj_Correct,
    [AudioIdentifiers.FINAL_ANSWER]: Audio_Aj_FinalAnswer,
    //[AudioIdentifiers.LETS_PLAY]: Audio_LetsPlay,
    //[AudioIdentifiers.PHONE_A_FRIEND]: Audio_PhoneAFriend,
    [AudioIdentifiers.WRONG_ANSWER]: Audio_Aj_Incorrect,
};
