<template>
    <div class="app-button-dev">
        <!--<div class="mt-4">
            <app-button-row size="xs">
                <app-button @click="clickButton('en.1')" :state="currentButton === 'en.1' ? buttonState : null">English</app-button>
                <app-button @click="clickButton('ru.1')" :state="currentButton === 'ru.1' ? buttonState : null">Russian</app-button>
            </app-button-row>
        </div>

        <div class="mt-4">
            <app-button-row size="sm">
                <app-button @click="clickButton('en.2')" :state="currentButton === 'en.2' ? buttonState : null">English</app-button>
                <app-button @click="clickButton('ru.2')" :state="currentButton === 'ru.2' ? buttonState : null">Russian</app-button>
            </app-button-row>
        </div>

        <div class="mt-4">
            <app-button-row size="md">
                <app-button @click="clickButton('en.3')" :state="currentButton === 'en.3' ? buttonState : null">English</app-button>
                <app-button @click="clickButton('ru.3')" :state="currentButton === 'ru.3' ? buttonState : null">Russian</app-button>
            </app-button-row>
        </div>

        <div class="mt-4">
            <app-button-row size="lg">
                <app-button @click="clickButton('en.4')" :state="currentButton === 'en.4' ? buttonState : null">English</app-button>
                <app-button @click="clickButton('ru.4')" :state="currentButton === 'ru.4' ? buttonState : null">Russian</app-button>
            </app-button-row>
        </div>

        <div class="mt-4">
            <app-button-row size="md">
                <app-button @click="clickButton('en.5')" :state="currentButton === 'en.5' ? buttonState : null">English, Russian, Chinese and other text too</app-button>
                <app-button @click="clickButton('en.5')" :state="currentButton === 'en.5' ? buttonState : null">English, Russian, Chinese<br />and other text too</app-button>
            </app-button-row>
        </div>

        <div class="mt-4">
            <app-button-row size="md">
                <app-button :icon="icon" @click="clickButton('en.6')" :state="currentButton === 'en.6' ? buttonState : null">English, Russian, Chinese and other text too</app-button>
                <app-button :icon="icon" @click="clickButton('en.6')" :state="currentButton === 'en.6' ? buttonState : null">English, Russian, Chinese<br />and other text too</app-button>
            </app-button-row>
        </div>-->

        <div class="mt-4">
            <app-button-row size="md">
                <answer-button :answer="{ text: 'English, Russian, Chinese and other text too' }" :answer-index="0"
                               :show-answer="true"></answer-button>
            </app-button-row>
        </div>
    </div>

    <dev-settings-panel anchor="bottomLeft">
    </dev-settings-panel>
</template>

<script>
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import AppButton from "@/components/common/AppButton.vue";
import LeftUi from "@/components/ui/LeftUi/index.vue";
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import AnswerIconA from "@/assets/icons/icon-question-a.png";
import AnswerButton from "@/components/ui/QuestionContainer/AnswerButton.vue";
import {DevSettingsPanel} from "@seriousgamesinteractive/components-vue3/DevComponent";

export default {
    name: 'AppButtonDev',
    components: {AnswerButton, BootstrapSelect, LeftUi, DevSettingsPanel, AppButtonRow, AppButton},
    props: {
        state: {
            type: String,
            required: false, // eg. "glow"
        },
    },
    data() {
        return {
            icon: AnswerIconA,
            currentButton: null,
            buttonStateIterator: 0,
            buttonStates: [
                null,
                'active',
                'glow',
            ],
        };
    },
    computed: {
        buttonState() {
            return this.buttonStates[this.buttonStateIterator];
        },
    },
    methods: {
        clickButton(currentButton) {
            let newState = (this.currentButton !== currentButton ? 1 : this.buttonStateIterator + 1);

            if (newState > this.buttonStates.length - 1) {
                newState = 0;
            }

            this.buttonStateIterator = newState;
            this.currentButton = currentButton;
        }
    }
}
</script>
