<script>
export default {
    props: {
        inline: {
            type: Boolean,
            required: false,
            default: false,
        },
        debug: {
            type: Boolean,
            required: false,
            default: false,
        },
        class: {
            type: String,
            required: false,
            default: '',
        },
        features: {
            type: Array,
            required: false,
            default() {
                return [
                    'code',
                    'blockquote',
                    'html',
                    'heading',
                    'hr',
                    'list',
                    'listitem',
                    'paragraph',
                    'table',
                    'tablerow',
                    'tablecell',
                    'strong',
                    'em',
                    'codespan',
                    'br',
                    'del',
                    'link',
                    'image',
                    'text',
                ];
            },
        },
        featuresBlacklist: {
            type: Array,
            required: false,
            default() {
                return [
                    'blockquote',
                    //'del',
                    'image',
                    'code',
                    'codespan',
                    'checkbox',
                    'hr',
                ];
            },
        },
        brNewLines: {
            type: Boolean,
            required: false,
            default: true,
        },
        linkTarget: {
            type: String,
            required: false,
            default: null,
        }
    }
}
</script>
