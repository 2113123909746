<template>
    <div class="handle-with-care-logo logo">
        <transition name="fade" mode="out-in">
            <img v-if="language === 'de'" src="@/assets/logos/de.handle-with-care.jpg"/>
            <img v-else-if="language === 'ru'" src="@/assets/logos/ru.handle-with-care.jpg"/>
            <img v-else src="@/assets/logos/en.handle-with-care.jpg"/>
        </transition>
    </div>
</template>
<script>
export default {
    name: 'HandleWithCareLogo',
    props: {
        language: {
            type: String,
            required: true,
        }
    },
}
</script>
<style lang="scss" scoped>
.handle-with-care-logo {
    height: 203px;
}
</style>
