export default {
  'intro': {
    'selectQuiz': 'Quiz ausw\u00e4hlen', // Type: text
    'selectLanguage': 'Sprache ausw\u00e4hlen', // Type: text
    'disclaimer': '**Haftungsausschluss: F\u00fcr die Teilnahme an diesem Quiz werden unabh\u00e4ngig von der erreichten Wertung keine Preise vergeben.**', // Type: markdown
    'handleWithCareMessage': 'Durch ~~0:antimikrobielle Resistenzen (AMR)~~ k\u00f6nnten die gesundheitlichen Zugewinne der letzten 100 Jahre zunichte gemacht werden. Es ist dringend notwendig, verst\u00e4rkt auf den angemessenen Einsatz antimikrobieller Mittel (Antibiotika, Virostatika, Antimykotika und Antiparasitika) hinzuwirken, damit sie weiterhin die Infektionen heilen, f\u00fcr deren Behandlung sie gedacht sind.', // Type: markdown
    'logoText': 'Die weltweit ergriffenen Ma\u00dfnahmen werden dem Ausma\u00df der Bedrohung noch nicht gerecht. Zur Bek\u00e4mpfung ~~0:antimikrobieller Resistenzen (AMR)~~ mit dem Ziel, eine **allgemeine Gesundheitsversorgung** zu gew\u00e4hrleisten, die **Vorsorge f\u00fcr und Reaktion auf gesundheitliche Notlagen** auszuweiten und die **Gesundheit der Bev\u00f6lkerung** zu verbessern, sind eine st\u00e4rkere Sensibilisierung sowie ein nachdr\u00fcckliches Engagement der Politik und finanzielle Investitionen erforderlich.', // Type: text
  },
  'generic': {
    'buttonYes': 'Ja', // Type: text
    'buttonNo': 'Nein', // Type: text
    'buttonOk': 'OK', // Type: text
    'buttonMute': 'Stummschalten', // Type: text
    'buttonNextQuestion': 'N\u00e4chste Frage', // Type: text
    'buttonFinalScore': 'Zur Endwertung gehen', // Type: text
    'buttonUnmite': 'Stummschaltung aufheben', // Type: text
    'buttonBack': 'Zur\u00fcck', // Type: text
    'loading': 'Wird geladen..', // Type: text
    'pleaseRotatePhone': 'F\u00fcr eine optimale Erfahrung drehen Sie bitte Ihr Telefon', // Type: text
    'countryListTable': '0 | 1 | 2 \r\n' + 
      '- | - | - \r\n' + 
      'Albanien | Italien | Russische F\u00f6deration\n' + 
      'Andorra | Kasachstan | San Marino\n' + 
      'Armenien | Kirgisistan | Schweden\n' + 
      'Aserbaidschan | Kroatien | Schweiz\n' + 
      'Belarus | Lettland | Serbien\n' + 
      'Belgien | Litauen | Slowakei\n' + 
      'Bosnien und Herzegowina | Luxemburg | Slowenien\n' + 
      'Bulgarien | Malta | Spanien\n' + 
      'D\u00e4nemark | Monaco | Tadschikistan\n' + 
      'Deutschland | Montenegro | Tschechische Republik\n' + 
      'Estland | Niederlande (K\u00f6nigreich der) | T\u00fcrkei\n' + 
      'Finnland | Nordmazedonien | Turkmenistan\n' + 
      'Frankreich | Norwegen | Ukraine\n' + 
      'Georgien | \u00d6sterreich | Ungarn\n' + 
      'Griechenland | Polen | Usbekistan\n' + 
      'Irland | Portugal | Vereinigtes K\u00f6nigreich Gro\u00dfbritannien und Nordirland\n' + 
      'Island | Republik Moldau | Zypern\n' + 
      'Israel | Rum\u00e4nien |', // Type: markdown
  },
  'game': {
    'labelMinutes': 'Min.', // Type: text
    'labelPoints': 'Punkte', // Type: text
    'kpiHealthCoverage': 'Allgemeine Gesundheitsversorgung', // Type: text
    'kpiHealthEmergencyProtection': 'Schutz in gesundheitlichen Notlagen', // Type: text
    'kpiHealthierPopulations': 'Eine ges\u00fcndere Bev\u00f6lkerung', // Type: text
    'lifelineHowPreviousPlayersAnswered': 'Wie bisherige Spieler geantwortet haben', // Type: text
    'lifelineFiftyFifty': '2 Antworten entfernen', // Type: text
    'lifelineHintHelp': 'Tipp', // Type: text
    'lifelineConfirmation': 'Joker verwenden? [LIFELINE]', // Type: text
  },
  'statistics': {
    'title': 'Quiz-Statistik', // Type: text
    'labelAnswers': 'Antworten', // Type: text
    'labelNumberOfAnswers': 'Anzahl der Antworten', // Type: text
  },
  'end': {
    'title': 'Herzlichen Gl\u00fcckwunsch zum Abschluss des Quiz!', // Type: text
    'bonusPoints': 'Bonuspunkte', // Type: text
    'finalScore': 'Endwertung', // Type: text
    'correctAnswers': 'Richtige Antworten', // Type: text
    'buttonPlayAgain': 'Erneut spielen', // Type: text
    'buttonChallengeAFriend': 'Freunde herausfordern', // Type: text
    'buttonLearnMore': 'Mehr erfahren', // Type: text
    'endLogoText': 'Antimikrobielle Mittel gehen zur Neige', // Type: text
    'challengeAFriend': 'Teilen Sie den Link, um festzustellen, ob andere Ihre Wertung \u00fcbertreffen k\u00f6nnen:', // Type: text
    'challengeAFriendLinkCopied': 'Link wurde in die Zwischenablage kopiert', // Type: text
  },
  'hoverText': {
    'hoverText_0': '**Antimikrobielle Resistenzen (AMR)** entstehen auf nat\u00fcrliche Weise, wenn sich Bakterien, Viren, Pilze und Parasiten im Laufe der Zeit ver\u00e4ndern und nicht mehr auf Arzneimittel ansprechen, so dass Infektionen immer schwerer zu behandeln sind und das Risiko einer Ausbreitung von Krankheiten, schweren Erkrankungen und Todesf\u00e4llen steigt. Durch den unsachgem\u00e4\u00dfen und \u00fcberm\u00e4\u00dfigen Einsatz antimikrobieller Mittel wird die Entstehung von AMR erheblich vorangetrieben.', // Type: markdown
    'hoverText_1': 'Die L\u00e4nder der **Europ\u00e4ischen Union (EU)** sind: Belgien, Bulgarien, D\u00e4nemark, Deutschland, Estland, Finnland, Frankreich, Griechenland, Irland, Italien, Kroatien, Lettland, Litauen, Luxemburg, Malta, das K\u00f6nigreich der Niederlande, \u00d6sterreich, Polen, Portugal, Rum\u00e4nien, Schweden, die Slowakei, Slowenien, Spanien, die Tschechische Republik, Ungarn und Zypern.\r\n' + 
      '\r\n' + 
      'Der **Europ\u00e4ische Wirtschaftsraum (EWR)** umfasst die EU-L\u00e4nder sowie Island, Liechtenstein und Norwegen.', // Type: markdown
    'hoverText_2': 'Die **gesundheitliche Belastung** ist ein die gesamte Bev\u00f6lkerung erfassendes Ma\u00df der Auswirkungen eines Gesundheitsproblems. Sie wird h\u00e4ufig als um Behinderungen bereinigte Lebensjahre (DALY) ausgedr\u00fcckt und ber\u00fccksichtigt die durch vorzeitige Sterblichkeit verlorenen sowie die durch Krankheit und Behinderung beeintr\u00e4chtigten Lebensjahre. Ein DALY entspricht einem verlorenen gesunden Lebensjahr.', // Type: markdown
    'hoverText_3': 'Drei h\u00e4ufige Arten von resistenten Bakterien sind: Escherichia coli, methicillin-resistenter Staphylococcus aureus und carbapenem-resistenter Pseudomonas aeruginosa.', // Type: markdown
    'hoverText_4': '**Infektionspr\u00e4vention und -bek\u00e4mpfung** ist ein praktischer, evidenzbasierter Ansatz, der die Patienten und das Gesundheitspersonal vor einer Sch\u00e4digung durch vermeidbare Infektionen bewahrt. Er betrifft alle Aspekte der Gesundheitsversorgung und umfasst H\u00e4nde- und Umwelthygiene, Injektionssicherheit und Abfallbewirtschaftung zur Eind\u00e4mmung von Infektionen und AMR.', // Type: markdown
    'hoverText_5': '**H\u00e4ndehygiene** durch effektives H\u00e4ndewaschen unter flie\u00dfendem Wasser mit Seife oder durch H\u00e4ndedesinfektionsmittel auf Alkoholbasis\r\n' + 
      'ist eine der wichtigsten Ma\u00dfnahmen zur Verringerung des Risikos einer Infektions\u00fcbertragung. \r\n' + 
      '**Umwelthygiene** umfasst die wirksame Reinigung und Dekontaminierung von Oberfl\u00e4chen, medizinischer Ausr\u00fcstung und Ger\u00e4ten sowie den sicheren Umgang mit scharfen\/spitzen Gegenst\u00e4nden, Blut, K\u00f6rperfl\u00fcssigkeiten, Abfall und W\u00e4sche zur Eind\u00e4mmung der Infektions\u00fcbertragung.', // Type: markdown
    'hoverText_6': '**Antibiotika der Gruppe Zugang** haben ein schmales Wirkungsspektrum und bieten in Bezug auf Nebenwirkungen ein gutes Sicherheitsprofil.\n' + 
      '\n' + 
      '**Antibiotika der Gruppe Beobachtung** sind Antibiotika mit breiterem Wirkungsspektrum und werden als erste Wahl f\u00fcr Patienten mit schwererem Krankheitsverlauf oder bei Infektionen empfohlen, bei denen die verursachenden Erreger mit h\u00f6herer Wahrscheinlichkeit gegen Antibiotika der Gruppe Zugang resistent sind.\n' + 
      '\n' + 
      '**Reserveantibiotika** sind Antibiotika der letzten Wahl, die zur Behandlung multiresistenter Infektionen eingesetzt werden.', // Type: markdown
    'hoverText_7': '**Ein verantwortungsbewusster Umgang mit antimikrobiellen Mitteln** f\u00f6rdert die optimale Verschreibung und Verwendung antimikrobieller Mittel zur wirksamen Behandlung von Infektionen, zum Schutz der Patienten und zur Bek\u00e4mpfung von AMR.', // Type: markdown
    'hoverText_8': '**One Health** ist ein Ansatz, der die Wechselwirkungen zwischen der Gesundheit von Mensch, Tier und Umwelt und die Notwendigkeit einer bereichs\u00fcbergreifenden Zusammenarbeit zur Bek\u00e4mpfung von AMR durch Aufkl\u00e4rung, \u00dcberwachung, Infektionspr\u00e4vention und -bek\u00e4mpfung, einen angemessenen Einsatz in der Landwirtschaft sowie Forschung und Entwicklung anerkennt.', // Type: markdown
    'hoverText_10': '**Rifampicin-resistente Tuberkulose (RR-Tb)** wird durch Bakterien verursacht, die nicht auf Rifampicin ansprechen. \r\n' + 
      '\r\n' + 
      '**Multiresistente Tuberkulose (MDR-Tb)** wird durch Bakterien verursacht, die zumindest nicht auf Isoniazid und Rifampicin als den beiden wirksamsten Antituberkulotika ansprechen. \r\n' + 
      '\r\n' + 
      'Patienten mit multiresistenter oder Rifampicin-resistenter Tuberkulose (MDR\/RR-Tb) ben\u00f6tigen Zweitlinientherapien, die komplexer sind als die Therapien, die zur Behandlung von Patienten ohne arzneimittelresistente Tb verwendet werden.', // Type: markdown
    'hoverText_11': 'Die **Mitgliedstaaten der Europ\u00e4ischen Region der WHO** sind:\n' + 
      '[MEMBER_STATE_TABLE]', // Type: markdown
    'hoverText_12': '**Extensiv arzneimittelresistente Tuberkulose (XDR-Tb)** ist eine Form der MDR-Tb, die auch gegen zwei Gruppen von Zweitrang-Antituberkulotika resistent ist, was ihre Behandlung noch schwieriger macht.', // Type: markdown
    'hoverText_13': 'Das Antibiotika-Buch der WHO **AWaRe** (Access, Watch, Reserve) enth\u00e4lt pr\u00e4gnante, evidenzbasierte Leitlinien zur Wahl des Antibiotikums, Dosierung, Verabreichungsform und Behandlungsdauer f\u00fcr mehr als 30 der h\u00e4ufigsten klinischen Infektionen unter Ber\u00fccksichtigung der Vor- und Nachteile und des Resistenzpotenzials.', // Type: markdown
    'hoverText_14': '**Antibiotika der Kategorie Zugang** haben ein schmales Wirkungsspektrum, sind kosteng\u00fcnstiger, bieten ein gutes Sicherheitsprofil und weisen allgemein ein geringes Resistenzpotenzial auf. Sie werden oft als empirische Therapie der ersten oder zweiten Wahl f\u00fcr h\u00e4ufig auftretende Infektionen empfohlen.', // Type: markdown
    'hoverText_15': '**Antibiotika der Kategorie Beobachtung** sind Antibiotika mit breiterem Wirkungsspektrum und allgemein h\u00f6herpreisig und werden als erste Wahl nur f\u00fcr Patienten mit schwererem Krankheitsverlauf oder bei Infektionen empfohlen, bei denen die verursachenden Erreger mit h\u00f6herer Wahrscheinlichkeit gegen Antibiotika der Gruppe Zugang resistent sind.\n' + 
      '\n' + 
      '**Reserveantibiotika** sind Antibiotika der letzten Wahl, die zur Behandlung multiresistenter Infektionen eingesetzt werden.', // Type: markdown
    'hoverText_16': 'Die **WHO-Mitgliedstaaten** sind in sechs Regionen gegliedert: Afrika, Europa, Gesamtamerika, \u00d6stlicher Mittelmeerraum, S\u00fcdostasien und Westlicher Pazifikraum.', // Type: markdown
    'hoverText_17': '**MDR-Tb** wird durch Bakterien verursacht, die zumindest nicht auf Isoniazid und Rifampicin als den beiden wirksamsten Antituberkulotika ansprechen.', // Type: markdown
  },
};