<template>
    <div class="left-ui-container">
        <lightbox :is-static="true">
                <question-container
                    v-if="currentQuestion"

                    :answer-show-result="answerShowResult"
                    :answers="answers"
                    :can-answer="true"
                    :can-use-lifelines="true"

                    :question="currentQuestion.text"
                    :seconds-left="secondsLeft"
                    :seconds-total="secondsTotal"
                    :show-answer1="true"
                    :show-answer2="true"

                    :show-answer3="true"
                    :show-answer4="true"

                    :show-question="true"

                    :title="title"
                    :used-life-lines="{}"
                />
        </lightbox>
    </div>

    <dev-settings-panel anchor="bottomRight">
        <locale-select-dev />
        <quiz-select-dev @question-changed="currentQuestion = $event" :show-question="true" />

        <div class="row">
            <label>Seconds left ({{ secondsLeft }})</label>
            <input v-model.number="secondsLeft" class="form-control" max="300" min="0" type="range"/>
        </div>
        <div class="row">
            <label>Seconds total ({{ secondsTotal }})</label>
            <input v-model.number="secondsTotal" class="form-control" max="300" min="0" type="range"/>
        </div>
        <div class="row">
            <label>Life lines available</label>

            <div class="d-flex gap-4">
                <button :class="lifeLineAvailableHint ? 'btn-success' : 'btn-dark'" class="btn btn-xs"
                        @click="lifeLineAvailableHint = !lifeLineAvailableHint">Hint
                </button>
                <button :class="lifeLineAvailable5050 ? 'btn-success' : 'btn-dark'" class="btn btn-xs"
                        @click="lifeLineAvailable5050 = !lifeLineAvailable5050">50/50
                </button>
                <button :class="lifeLineAvailableStats ? 'btn-success' : 'btn-dark'" class="btn btn-xs"
                        @click="lifeLineAvailableStats = !lifeLineAvailableStats">Stats
                </button>
            </div>
        </div>
    </dev-settings-panel>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import LeftUi from "@/components/ui/LeftUi/index.vue";
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";
import QuestionContainer from "@/components/ui/QuestionContainer/index.vue";
import {shuffle} from "@seriousgamesinteractive/utils/arrayUtil";
import QuestionContainerLive from "@/components/ui/QuestionContainer/QuestionContainerLive.vue";
import Lightbox from "@seriousgamesinteractive/components-vue3/common/Lightbox.vue";
import LocaleSelectDev from "@/components/dev/LocaleSelectDev.vue";
import QuizSelectDev from "@/components/dev/QuizSelectDev.vue";

export default {
    name: 'QuestionContainerDev',
    components: {
        QuizSelectDev, LocaleSelectDev,
        QuestionContainerLive,
        QuestionContainer,
        BootstrapSelect,
        DevSettingsPanel,
        EventTicks,
        LeftUi,
        Lightbox,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        return {
            events: ['mute:toggle', 'click:back'],

            secondsLeft: 18,
            secondsTotal: 60,
            lifeLineAvailableHint: true,
            lifeLineAvailable5050: true,
            lifeLineAvailableStats: true,

            answerChosen: null,
            answerShowResult: false,

            currentQuestion: null,
        }
    },
    computed: {
        title() {
            if (!this.currentQuestion) {
                return;
            }

            return this.currentQuestion.title;
        },
        question() {
            if (!this.currentQuestion) {
                return;
            }

            return this.currentQuestion.question;
        },
        answers() {
            if (!this.currentQuestion) {
                return;
            }

            return this.currentQuestion.answers;
        },

        LifeLineIdentifier() {
            return LifeLineIdentifier
        },
        lifeLinesAvailable() {
            return [
                this.lifeLineAvailableHint ? LifeLineIdentifier.Hint : null,
                this.lifeLineAvailable5050 ? LifeLineIdentifier.FiftyFifty : null,
                this.lifeLineAvailableStats ? LifeLineIdentifier.Stats : null,
            ].filter(v => v !== null);
        }
    },
    methods: {
        recalculateQuizQuestions() {
            const questionOptions = [];

            if (this.currentQuiz) {
                for (let i = 0, len = this.currentQuiz.questions.length; i < len; i++) {
                    const question = this.currentQuiz.questions[i];

                    questionOptions.push({
                        value: i.toString(),
                        label: `[${i + 1}] ${question.title}`,
                    });
                }
            }

            this.questionOptions = questionOptions;
        },
        onUseLifeline(lifeLineIdentifier) {
            if (lifeLineIdentifier === LifeLineIdentifier.Hint) {
                this.lifeLineAvailableHint = !this.lifeLineAvailableHint;
            } else if (lifeLineIdentifier === LifeLineIdentifier.FiftyFifty) {
                if (this.lifeLineAvailable5050) {
                    const nonCorrectAnswerIndexes = [];

                    for (let i = 0, len = this.answers.length; i < len; i++) {
                        const answer = this.answers[i];

                        if (!answer.isCorrect) {
                            nonCorrectAnswerIndexes.push(i);
                        }
                    }

                    const answerIndexesShuffled = shuffle(nonCorrectAnswerIndexes);

                    for (let i = 0; i < 2; i++) {
                        this.answers[answerIndexesShuffled[i]].disabled = true;
                    }
                } else {
                    for (let i = 0, len = this.answers.length; i < len; i++) {
                        this.answers[i].disabled = false;
                    }
                }

                this.lifeLineAvailable5050 = !this.lifeLineAvailable5050;
            } else if (lifeLineIdentifier === LifeLineIdentifier.Stats) {
                this.lifeLineAvailableStats = !this.lifeLineAvailableStats;
            }
        },
        onPickAnswer(answerIndex) {
            if (answerIndex === this.answerChosen) {
                this.answerChosen = null;

                clearTimeout(() => {

                });
            } else {
                this.answerChosen = answerIndex;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.left-ui-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}
</style>
