export default {
    state: {
        activeHoverText: null,
        fastMode: false,
        cheatMode: false,
        background: 'who-blue',
        language: null,
        languageLogo: null,
        hideLogos: false,
        masterQuizId: null,
        localizedQuizId: null,
        questionStates: [
            'inactive', // 0
            'inactive', // 1
            'inactive', // 2
            'inactive', // 3
            'inactive', // 4
            'inactive', // 5
            'inactive', // 6
            'inactive', // 7
            'inactive', // 8
            'inactive', // 9
        ],
    },
    mutations: {
        activeHoverText: (state, value) => {
            state.activeHoverText = value || null;
        },
        fastMode: (state, value) => {
            state.fastMode = value;
        },
        cheatMode: (state, value) => {
            state.cheatMode = value;
        },
        background: (state, value) => {
            state.background = value;
        },
        masterQuizId: (state, value) => {
            state.masterQuizId = value;
        },
        localizedQuizId: (state, value) => {
            state.localizedQuizId = value;
        },
        language: (state, value) => {
            state.language = value;
        },
        languageLogo: (state, value) => {
            state.languageLogo = value;
        },
        hideLogos: (state, value) => {
            state.hideLogos = value;
        },
        resetQuestionStates: (state) => {
            const questionStates = [];

            for (let i = 0; i < 10; i++) {
                questionStates.push('inactive');
            }

            state.questionStates = questionStates;
        },
        setQuestionState: (state, {questionIndex, questionState}) => {
            state.questionStates[questionIndex] = questionState;
        },
    },
    getters: {
        activeHoverText(state) {
            return state.activeHoverText;
        },
        fastMode(state) {
            return state.fastMode;
        },
        cheatMode(state) {
            return state.cheatMode;
        },
        background(state) {
            return state.background;
        },
        masterQuizId(state) {
            return state.masterQuizId;
        },
        localizedQuizId(state) {
            return state.localizedQuizId;
        },
        language(state) {
            return state.language;
        },
        languageLogo(state) {
            return state.languageLogo;
        },
        hideLogos(state) {
            return state.hideLogos;
        },
        questionStates(state) {
            return state.questionStates;
        },
    },
}
