<template>
    <markdown-text :class="class" :debug="debug" :features="features" :features-blacklist="featuresBlacklist" :br-new-lines="true"
                   @render="onMarkdownRender">
        <slot/>
    </markdown-text>
</template>

<script>
import MarkdownText from "@/components/common/MarkdownText/index.vue";
import MarkdownTextMixins from "@/components/common/MarkdownText/MarkdownTextMixins.vue";

const regex = /^(\d+):(.+)$/g;

export default {
    name: 'MarkdownHoverText',
    components: {MarkdownText},
    emits: ['render'],
    mixins: [MarkdownTextMixins],
    methods: {
        onMarkdownRender({element, html}) {
            if (!element) {
                return;
            }

            const underlinedElements = element.querySelectorAll('del');

            const hoverTexts = [];

            for (const underlinedElement of underlinedElements) {
                underlinedElement.innerText = underlinedElement.innerText.replace(regex, (string, hoverTextId, rawText) => {
                    const hoverText = this.$t(`hoverText.hoverText_${hoverTextId}`);

                    underlinedElement.classList.add('with-hover-text');

                    //underlinedElement.title = hoverText;

                    hoverTexts.push(hoverText);

                    underlinedElement.addEventListener('click', () => {
                        this.$store.commit('activeHoverText', hoverText);
                    });

                    return rawText;
                });
            }

            this.$emit('render', {
                element,
                html,
                hoverTexts,
            });
        }
    }
};
</script>

<style lang="scss">
.with-hover-text {
    cursor: pointer;
}
</style>
