export default {
  'intro': {
    'selectQuiz': 'intro.selectQuiz', // Type: text
    'selectLanguage': 'intro.selectLanguage', // Type: text
    'disclaimer': 'intro.disclaimer', // Type: markdown
    'handleWithCareMessage': 'intro.handleWithCareMessage', // Type: markdown
    'logoText': 'intro.logoText', // Type: text
  },
  'generic': {
    'buttonYes': 'generic.buttonYes', // Type: text
    'buttonNo': 'generic.buttonNo', // Type: text
    'buttonOk': 'generic.buttonOk', // Type: text
    'buttonMute': 'generic.buttonMute', // Type: text
    'buttonNextQuestion': 'generic.buttonNextQuestion', // Type: text
    'buttonFinalScore': 'generic.buttonFinalScore', // Type: text
    'buttonUnmite': 'generic.buttonUnmite', // Type: text
    'buttonBack': 'generic.buttonBack', // Type: text
    'loading': 'generic.loading', // Type: text
    'pleaseRotatePhone': 'generic.pleaseRotatePhone', // Type: text
    'countryListTable': '0 | 1 | 2 \r\n' + 
      '- | - | - \r\n' + 
      'albania | greece | portugal\n' + 
      'andorra | hungary | republicOfMoldova\n' + 
      'armenia | iceland | romania\n' + 
      'austria | ireland | russianFederation\n' + 
      'azerbaijan | israel | sanMarino\n' + 
      'belarus | italy | serbia\n' + 
      'belgium | kazakhstan | slovakia\n' + 
      'bosniaAndHerzegovina | kyrgyzstan | slovenia\n' + 
      'bulgaria | latvia | spain\n' + 
      'croatia | lithuania | sweden\n' + 
      'cyprus | luxembourg | switzerland\n' + 
      'czechia | malta | tajikistan\n' + 
      'denmark | monaco | turkey\n' + 
      'estonia | montenegro | turkmenistan\n' + 
      'finland | netherlands | ukraine\n' + 
      'france | northMacedonia | unitedKingdom\n' + 
      'georgia | norway | uzbekistan\n' + 
      'germany | poland |', // Type: markdown
  },
  'game': {
    'labelMinutes': 'game.labelMinutes', // Type: text
    'labelPoints': 'game.labelPoints', // Type: text
    'kpiHealthCoverage': 'game.kpiHealthCoverage', // Type: text
    'kpiHealthEmergencyProtection': 'game.kpiHealthEmergencyProtection', // Type: text
    'kpiHealthierPopulations': 'game.kpiHealthierPopulations', // Type: text
    'lifelineHowPreviousPlayersAnswered': 'game.lifelineHowPreviousPlayersAnswered', // Type: text
    'lifelineFiftyFifty': 'game.lifelineFiftyFifty', // Type: text
    'lifelineHintHelp': 'game.lifelineHintHelp', // Type: text
    'lifelineConfirmation': 'game.lifelineConfirmation', // Type: text
  },
  'statistics': {
    'title': 'statistics.title', // Type: text
    'labelAnswers': 'statistics.labelAnswers', // Type: text
    'labelNumberOfAnswers': 'statistics.labelNumberOfAnswers', // Type: text
  },
  'end': {
    'title': 'end.title', // Type: text
    'bonusPoints': 'end.bonusPoints', // Type: text
    'finalScore': 'end.finalScore', // Type: text
    'correctAnswers': 'end.correctAnswers', // Type: text
    'buttonPlayAgain': 'end.buttonPlayAgain', // Type: text
    'buttonChallengeAFriend': 'end.buttonChallengeAFriend', // Type: text
    'buttonLearnMore': 'end.buttonLearnMore', // Type: text
    'endLogoText': 'end.endLogoText', // Type: text
    'challengeAFriend': 'end.challengeAFriend', // Type: text
    'challengeAFriendLinkCopied': 'end.challengeAFriendLinkCopied', // Type: text
  },
  'hoverText': {
    'hoverText_0': 'hoverText.hoverText_0', // Type: markdown
    'hoverText_1': 'hoverText.hoverText_1', // Type: markdown
    'hoverText_2': 'hoverText.hoverText_2', // Type: markdown
    'hoverText_3': 'hoverText.hoverText_3', // Type: markdown
    'hoverText_4': 'hoverText.hoverText_4', // Type: markdown
    'hoverText_5': 'hoverText.hoverText_5', // Type: markdown
    'hoverText_6': 'hoverText.hoverText_6', // Type: markdown
    'hoverText_7': 'hoverText.hoverText_7', // Type: markdown
    'hoverText_8': 'hoverText.hoverText_8', // Type: markdown
    'hoverText_10': 'hoverText.hoverText_10', // Type: markdown
    'hoverText_11': 'hoverText.hoverText_11', // Type: markdown
    'hoverText_12': 'hoverText.hoverText_12', // Type: markdown
    'hoverText_13': 'hoverText.hoverText_13', // Type: markdown
    'hoverText_14': 'hoverText.hoverText_14', // Type: markdown
    'hoverText_15': 'hoverText.hoverText_15', // Type: markdown
    'hoverText_16': 'hoverText.hoverText_16', // Type: markdown
    'hoverText_17': 'hoverText.hoverText_17', // Type: markdown
  },
};