<template>
    <div class="lifeline-confirmation-popup-dev-container">
        <lifeline-confirmation-popup
            :lifeline="lifeline"
            @click:yes="onClickYes"
            @click:no="onClickNo"
        />
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <div class="row">
            <label>Lifeline</label>
            <bootstrap-select v-model="lifeline" :options="lifelineOptions"/>
        </div>
    </dev-settings-panel>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>

import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";
import LifelineConfirmationPopup from "@/components/ui/LifelineConfirmationPopup/index.vue";

export default {
    name: 'LifelineConfirmationPopupDev',
    computed: {
        LifeLineIdentifier() {
            return LifeLineIdentifier
        }
    },
    components: {
        LifelineConfirmationPopup,
        BootstrapSelect,
        DevSettingsPanel,
        EventTicks,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        return {
            events: ['click:yes', 'click:no'],

            lifeline: LifeLineIdentifier.FiftyFifty,

            lifelineOptions: [
                {
                    value: LifeLineIdentifier.Hint,
                    label: 'Hint',
                },
                {
                    value: LifeLineIdentifier.FiftyFifty,
                    label: '50/50',
                },
                {
                    value: LifeLineIdentifier.Stats,
                    label: 'Other answers',
                }
            ]
        }
    },
    methods: {
        onClickYes() {
            this.triggerEvent('click:yes')
        },
        onClickNo() {
            this.triggerEvent('click:no')
        },
    },
}
</script>

<style lang="scss" scoped>
.lifeline-confirmation-popup-dev-container {
    margin-top: 50px;
}
</style>
