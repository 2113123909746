<template>
    <div class="previous-answers-popup-dev-container">
        <challenge-friend-popup
            v-if="quiz"
            :master-quiz-id="quiz.id"
            :quiz-language="$i18n.locale"

            @click:ok="onClickOk"
        />
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <locale-select-dev />
        <quiz-select-dev @quiz-changed="quiz = $event" :show-question="false" />
    </dev-settings-panel>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>

import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import ChallengeFriendPopup from '@/components/ui/ChallengeFriendPopup/index.vue';
import LocaleSelectDev from "@/components/dev/LocaleSelectDev.vue";
import QuizSelectDev from "@/components/dev/QuizSelectDev.vue";

export default {
    name: 'ChallengeFriendPopupDev',
    components: {
        QuizSelectDev, LocaleSelectDev,
        ChallengeFriendPopup,
        BootstrapSelect,
        DevSettingsPanel,
        EventTicks,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        return {
            events: ['click:ok'],

            quiz: null,
        }
    },
    mounted() {
        this.randomize();
    },
    methods: {
        reRender() {
            this.render = false;

            this.$nextTick(() => {
                this.render = true;
            });
        },
        randomize() {
            this.answerCountA = Math.floor(Math.random() * 1000);
            this.answerCountB = Math.floor(Math.random() * 1000);
            this.answerCountC = Math.floor(Math.random() * 1000);
            this.answerCountD = Math.floor(Math.random() * 1000);
        },
        onClickOk() {
            this.triggerEvent('click:ok')
        },
    },
}
</script>

<style lang="scss" scoped>
.previous-answers-popup-dev-container {
    margin-top: 50px;
}
</style>
