// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/buttons/btn-hex.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/ui/tooltip-bottom.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".life-line-button[data-v-bd99189e] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  width: 87px;\n  height: 101px;\n  position: relative;\n  pointer-events: none;\n}\n.life-line-button-interactable[data-v-bd99189e] {\n  cursor: pointer;\n  pointer-events: all !important;\n}\n.life-line-button-used[data-v-bd99189e] {\n  cursor: not-allowed;\n  opacity: 0.3;\n}\n.life-line-button-used[data-v-bd99189e]:hover, .life-line-button-used[data-v-bd99189e]:active {\n  top: 0 !important;\n}\n.life-line-button-used .life-line-tooltip[data-v-bd99189e] {\n  display: none !important;\n}\n.life-line-button[data-v-bd99189e]:hover {\n  top: 1px;\n}\n.life-line-button:hover .life-line-tooltip[data-v-bd99189e] {\n  display: block;\n  opacity: 1;\n}\n.life-line-button[data-v-bd99189e]:active {\n  top: 2px;\n}\n.life-line-button .icon[data-v-bd99189e] {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, calc(-50% - 6px));\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n.life-line-tooltip[data-v-bd99189e] {\n  pointer-events: none !important;\n  opacity: 0;\n  transition: 200ms ease-in-out;\n  position: absolute;\n  top: -85px;\n  left: 50%;\n  transform: translateX(-50%);\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  width: 620px;\n  height: 77px;\n  color: white;\n  font-size: 20pt;\n  padding-top: 2px;\n  padding-bottom: 24px;\n  line-height: 55px;\n  text-align: center;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
