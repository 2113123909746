import en from './locale/en';
import ru from './locale/ru';
import de from './locale/de';
import system from './locale/system';

export default {
    en,
    ru,
    de,
    system,
};
