<template>
    <div class="right-ui">
        <div class="right-ui-background-line-through"/>

        <top-circle id="points-ui-ball"
                    :metric-label="$t('game.labelPoints')"
                    :metric-value="points"
                    class="particle-expansion"
                    side="right"
        />

        <div class="right-ui-kpis">
            <kpi :identifier="KpiIdentifier.HealthCoverage" :value="kpiHealthCoverage" circle-id="kpi-health-coverage"/>
            <kpi :identifier="KpiIdentifier.HealthEmergencyProtection" :value="kpiHealthEmergencyProtection"
                 circle-id="kpi-health-emergency-protection"/>
            <kpi :identifier="KpiIdentifier.HealthierPopulations" :value="kpiHealthierPopulations"
                 circle-id="kpi-healthier-populations"/>
        </div>
    </div>
</template>

<script>
import RightUiQuestion from "@/components/ui/RightUi/RightUiQuestion.vue";
import {KpiIdentifier} from "@/enum/KpiIdentifier";
import TopCircle from "@/components/ui/TopCircle.vue";
import Kpi from "@/components/ui/Kpi/index.vue";

export default {
    name: 'RightUi',
    components: {
        Kpi,
        TopCircle,
        RightUiQuestion,
    },
    props: {
        points: {
            type: Number,
            required: true,
        },
        kpiHealthCoverage: {
            type: Number,
            required: true,
        },
        kpiHealthEmergencyProtection: {
            type: Number,
            required: true,
        },
        kpiHealthierPopulations: {
            type: Number,
            required: true,
        },
    },
    computed: {
        KpiIdentifier() {
            return KpiIdentifier
        },
    },
}
</script>

<style lang="scss" scoped>
.right-ui-background-line-through {
    position: absolute;
    top: 0;
    right: 50%;
    height: calc(100% - 200px);
    width: 5px;
    transform: translateX(1px);
    background: linear-gradient(#966838, #6A2601);
    z-index: -1;
}

.right-ui {
    position: relative;
    //background: rgba(255, 0, 0, .8);
    padding-top: 220px;
    height: 100%;
}

.right-ui-kpis {
    margin-top: 70px;
    padding: 0 70px;
    margin-bottom: 30px;
    transform-origin: top;
}

.particle-expansion {
    transform-origin: center;
    //transition: 200ms ease-in-out;
}
</style>
