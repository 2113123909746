<template>
    <div class="quiz-select-dev">
        <div class="row" v-if="quizOptions">
            <label>Quiz</label>
            <bootstrap-select v-model="currentQuizId" :options="quizOptions"/>
        </div>
        <div class="row" v-if="currentQuiz && showQuestion && quizQuestionOptions">
            <label>Question</label>
            <bootstrap-select v-model="currentQuestionId" :options="quizQuestionOptions"/>
        </div>
    </div>

</template>

<script>
import { BootstrapSelect } from '@seriousgamesinteractive/components-vue3/bootstrap';

export default {
    name: 'QuizSelectDev',
    components: {
        BootstrapSelect,
    },
    props: {
        showQuestion: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            quizIds: [1],
            locales: this.$i18n.availableLocales.filter(v => v !== 'system'),

            quizzes: {},

            currentQuizId: null,
            currentQuestionId: null,

            quizOptions: [],
            quizQuestionOptions: [],
        };
    },
    mounted() {
        this.loadQuizzes();
    },
    watch: {
        '$i18n.locale'() {
            this.recomputeQuizOptions();
        },
        currentQuiz() {
            this.$emit('quiz-changed', this.currentQuiz);

            this.recomputeQuizQuestionOptions();
        },
        currentQuestion() {
            this.$emit('question-changed', this.currentQuestion);
        },
    },
    emits: [
        'quizzes-loaded',
        'locale-quizzes-changed',
        'quiz-changed',
        'question-changed',
    ],
    computed: {
        localeQuizzes() {
            if (!this.quizzes) {
                return [];
            }

            if (!this.quizzes[this.$i18n.locale]) {
                return [];
            }

            return this.quizzes[this.$i18n.locale];
        },
        currentQuiz() {
            if (!this.currentQuizId) {
                return null;
            }

            return this.localeQuizzes[this.currentQuizId];
        },
        currentQuestion() {
            if (this.currentQuiz && this.currentQuestionId) {
                for (const question of this.currentQuiz.questions) {
                    if (question.id.toString() === this.currentQuestionId) {
                        return question;
                    }
                }
            }

            return null;
        },
    },
    methods: {
        recomputeQuizOptions() {
            const quizOptions = [];

            if (this.localeQuizzes) {
                for (const quizId in this.localeQuizzes) {
                    const quiz = this.localeQuizzes[quizId];

                    quizOptions.push({
                        label: `${quiz.id.toString()} / ${quiz.title}`,
                        value: quiz.id.toString(),
                    });
                }
            }

            this.quizOptions = quizOptions;

            this.currentQuizId = quizOptions[0].value;
        },
        recomputeQuizQuestionOptions() {
            const quizQuestionOptions = [];

            for (let i = 0, len = this.currentQuiz.questions.length; i < len; i++) {
                const question = this.currentQuiz.questions[i];
                const questionIdString = question.id.toString();

                quizQuestionOptions.push({
                    label: `#${i + 1} / ${question.title}`,
                    value: questionIdString,
                });
            }

            this.quizQuestionOptions = quizQuestionOptions;

            this.currentQuestionId = quizQuestionOptions[0].value;
        },
        loadQuizzes() {
            this.loading = true;

            const quizPromises = [];

            for (const locale of this.locales) {
                this.quizzes[locale] = {};

                for (const quizId of this.quizIds) {
                    const promise = this.$apiClient.quiz.getLocalizedQuiz(quizId, locale).then((quizData) => {
                        this.quizzes[locale][quizData.id.toString()] = quizData;
                    });

                    quizPromises.push(promise);
                }
            }

            Promise.all(quizPromises).then(() => {
                this.recomputeQuizOptions();
                this.recomputeQuizQuestionOptions();

                this.$emit('quizzes-loaded', this.quizzes);
                this.$emit('locale-quizzes-changed', this.localeQuizzes);

                this.$emit('quiz-changed', this.currentQuiz);
                this.$emit('question-changed', this.currentQuestion);

                this.loading = false;
            });
        }
    }
}
</script>
