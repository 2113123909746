<template>
    <amr-popup-with-timeout :timeout-seconds="60" class="question-information-popup" timer-width="md" width="sm"
                            @timeout="onTimeout">
        <template v-slot:icon>
            <no-drag-image src="@/assets/icons/icon-information.png"/>
        </template>

        <template v-slot:content>
            <p class="answer-explanation">
                <markdown-hover-text>{{ answerInfo }}</markdown-hover-text>
            </p>
            <div v-if="false" class="answer-container">
                <div
                    v-for="(answer, i) in answers"
                    :class="['answer-' + i, answer.isCorrect ? 'answer-correct' : 'answer-incorrect']"
                    class="answer"
                >
                    {{ answer.text }}
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <app-button-row class="button-container" size="xs">
                <app-button @click="onClickContinue">
                    {{ $t(isLastQuestion ? 'generic.buttonFinalScore' : 'generic.buttonNextQuestion') }}
                </app-button>
            </app-button-row>
        </template>
    </amr-popup-with-timeout>
</template>

<script>
import AppButton from "@/components/common/AppButton.vue";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import AmrPopup from "@/components/ui/AmrPopup.vue";
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";
import AmrPopupWithTimeout from "@/components/ui/AmrPopupWithTimeout.vue";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";

export default {
    name: 'QuestionInformationPopup',
    components: {
        LifeLineButton,
        AmrPopupWithTimeout, NoDragImage, AmrPopup, MarkdownHoverText, AppButtonRow, TimeLeft, AppButton
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        isLastQuestion: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['click:continue', 'timeout'],
    computed: {
        answerInfo() {
            return this.question.correctAnswerExplanation;
        },
        answers() {
            return this.question.answers;
        },
    },
    methods: {
        onClickContinue() {
            this.$emit('click:continue');
        },
        onTimeout() {
            this.$emit('timeout');
        },
    },
}
</script>

<style lang="scss" scoped>
.question-information-popup {
    .answer-explanation {
        width: 1150px;
        text-align: left;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 25px;
        padding: 10px;
        height: 500px;
        overflow-y: scroll;
    }

    .answer-container {
        width: fit-content;
        max-width: 600px;
        margin: 0 auto;
        text-align: left;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;

        .answer {
            padding: 2px 15px;
            border: 2px solid transparent;

            &-correct {
                background: rgba(128, 188, 0, 0.60);
                border: 2px solid #6A2600;
                border-radius: 10px;
            }

            &:before {
                width: 50px;
                margin-right: 15px;
                font-weight: bold;
            }

            &-0:before {
                content: "A";
            }

            &-1:before {
                content: "B";
            }

            &-2:before {
                content: "C";
            }

            &-3:before {
                content: "D";
            }

        }
    }
}

.question-information-icon {
    background: url("@/assets/buttons/btn-info.png");
    width: 84px;
    height: 71px;
    padding: 10px;

    position: absolute;
    top: (-71px / 2);
    left: 50%;
    transform: translateX(-50%);
}
</style>
