<template>
    <amr-box :class="computedClasses" class="amr-popup">
        <div :class="'icon-position-' + iconPosition" class="icon">
            <slot name="icon"/>
        </div>

        <div class="header">
            <slot name="header"/>
        </div>

        <div class="content">
            <slot name="content"/>
        </div>

        <div class="footer-buttons">
            <div class="footer-buttons-inner">
                <slot name="footer-buttons"/>
            </div>
        </div>
    </amr-box>
</template>

<script>
import AmrBox from "@/components/ui/AmrBox.vue";

export default {
    name: 'AmrPopup',
    components: {AmrBox},
    props: {
        width: {
            type: String,
            required: false,
            default: 'md',
        },
        height: {
            type: String,
            required: false,
            default: 'md',
        },
        iconPosition: {
            type: String,
            required: false,
            default: 'left',
        },
    },
    computed: {
        computedClasses() {
            return [
                `amr-popup-width-${this.width}`,
                `amr-popup-height-${this.height}`,
            ];
        }
    }
}
</script>

<style lang="scss" scoped>
.amr-popup {
    .content {
        font-size: 20pt;
    }

    &-width {
        &-xs {
            width: 600px;
        }

        &-sm {
            width: 1200px;
        }

        &-md {
            width: 1400px;
        }
    }

    &-height {
        &-xs {
            height: 300px;
        }

        &-sm {
            height: 400px;
        }

        &-md {
            height: 600px;
        }
    }

    .icon {
        position: absolute;
        top: 0;

        &-position-left {
            left: -50px;
            transform: translateY(-50%);
        }

        &-position-center {
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .footer-buttons {
        position: absolute;
        bottom: 0px;
        width: 100%;

        .footer-buttons-inner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            //transform: translate(calc(-50% - 30px), -50%);
            text-align: center;
        }
    }
}
</style>
