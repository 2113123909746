<template>
    <div class="learn-more-popup-dev-container">
        <lightbox :is-static="true">
                <learn-more-popup
                    v-if="quiz"

                    :quiz="quiz"

                    @click:ok="onClickOk"
                />
        </lightbox>
    </div>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>

    <dev-settings-panel anchor="bottomLeft">
        <locale-select-dev />
        <quiz-select-dev @quiz-changed="quiz = $event" :show-question="false" />
    </dev-settings-panel>
</template>
<script>

import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import LearnMorePopup from '@/components/ui/LearnMorePopup/index.vue';
import {getFakeQuizFromLocale} from '@/utils/generator';
import BootstrapButtonGroup from "@seriousgamesinteractive/components-vue3/bootstrap/BootstrapButtonGroup.vue";
import Lightbox from "@seriousgamesinteractive/components-vue3/common/Lightbox.vue";
import HintHelpPopup from "@/components/ui/HintHelpPopup/index.vue";
import LocaleSelectDev from "@/components/dev/LocaleSelectDev.vue";
import QuizSelectDev from "@/components/dev/QuizSelectDev.vue";

export default {
    name: 'LearnMorePopupDev',
    components: {
        HintHelpPopup,
        BootstrapButtonGroup,
        LearnMorePopup,
        BootstrapSelect,
        DevSettingsPanel,
        EventTicks,
        Lightbox,
        LocaleSelectDev,
        QuizSelectDev,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        return {
            events: ['click:ok'],

            quiz: null,
        }
    },
    methods: {
        onClickOk() {
            this.triggerEvent('click:ok')
        },
    },
}
</script>

<style lang="scss" scoped>
.previous-answers-popup-dev-container {
    margin-top: 50px;
}
</style>
