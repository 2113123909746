<template>
    <div class="end-popup">
        <amr-box class="end-popup-content">
            <h1>{{ $t('end.title') }}</h1>

            <div class="content">
                <div class="content-kpis d-flex justify-content-evenly">
                    <div class="content-kpi">
                        <label>{{ $t('end.correctAnswers') }}:</label>
                        <div class="points-container">{{ correctAnswers }} / {{ correctAnswersMax }}</div>
                    </div>
                    <div class="content-kpi">
                        <label>{{ $t('end.bonusPoints') }}:</label>
                        <div class="points-container">{{ bonusPoints }}</div>
                    </div>
                    <div class="content-kpi">
                        <label>{{ $t('end.finalScore') }}:</label>
                        <div class="points-container">{{ finalScore }} / {{ finalScoreMax }}</div>
                    </div>
                </div>

                <p class="content-text">
                    <markdown-hover-text>{{ bodyText }}</markdown-hover-text>
                </p>
            </div>
            <div class="footer-buttons">
                <div class="footer-buttons-inner d-flex justify-content-between">
                    <app-button-row size="xs">
                        <app-button v-on:click="onClickPlayAgain">{{ $t('end.buttonPlayAgain') }}</app-button>
                        <app-button v-on:click="onClickChallengeFriend">{{
                                $t('end.buttonChallengeAFriend')
                            }}
                        </app-button>
                    </app-button-row>

                    <app-button-row size="xs">
                        <app-button v-on:click="onClickLearnMore">{{ $t('end.buttonLearnMore') }}</app-button>
                    </app-button-row>
                </div>
            </div>
        </amr-box>
    </div>
</template>

<script>
import AppButton from "@/components/common/AppButton.vue";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import {NoDragImage, TextFit} from "@seriousgamesinteractive/components-vue3/common";
import AmrBox from "@/components/ui/AmrBox.vue";

export default {
    name: 'EndPopup',
    components: {
        MarkdownHoverText,
        TextFit,
        AmrBox,
        NoDragImage, AppButtonRow, TimeLeft, AppButton
    },
    props: {
        finalScore: {
            type: Number,
            required: true,
        },
        finalScoreMax: {
            type: Number,
            required: true,
        },
        correctAnswers: {
            type: Number,
            required: true,
        },
        correctAnswersMax: {
            type: Number,
            required: true,
        },
        bonusPoints: {
            type: Number,
            required: true,
        },
        bodyText: {
            type: String,
            required: true,
        },
    },
    emits: ['click:challengeFriend', 'click:learnMore', 'click:playAgain'],
    methods: {
        onClickChallengeFriend() {
            this.$emit('click:challengeFriend');
        },
        onClickLearnMore() {
            this.$emit('click:learnMore');
        },
        onClickPlayAgain() {
            this.$emit('click:playAgain');
        },
    },
}
</script>


<style lang="scss" scoped>
.end-popup {
    width: 1200px;

    .footer-buttons {
        position: absolute;
        bottom: -40px;
        width: 100%;
    }
}

h1 {
    padding: 10px;
    font-size: 35pt;
    font-weight: bold;

    margin-top: 10px;
    margin-bottom: 0;
}

.end-popup-content {
    position: relative;

    .content-text {
        text-align: left !important;
    }

    .content {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .content-kpis {
        margin-bottom: 50px;
    }

    .content-text {
        font-size: 18pt;
        width: 1000px;
        text-align: center;
        margin: 0 auto;
    }

    .content-kpi {
        label {
            font-weight: bold;
            font-size: 30pt;
        }

        .points-container {
            background: url("@/assets/ui/bg-points.png");
            width: 373px;
            height: 87px;
            text-align: center;
            font-size: 40pt;
            font-weight: bold;
            line-height: 87px;
        }
    }
}
</style>
