<template>
    <div>
        <div>
            <kpi
                :identifier="KpiIdentifier.HealthCoverage"
                :value="value"
            />
        </div>
        <div>
            <kpi
                :identifier="KpiIdentifier.HealthEmergencyProtection"
                :value="value"
            />
        </div>
        <div>
            <kpi
                :identifier="KpiIdentifier.HealthierPopulations"
                :value="value"
            />
        </div>
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <div class="row">
            <label>Value ({{ value }})</label>
            <input v-model.number="value" class="form-control" max="100" min="0" type="range"/>
        </div>
    </dev-settings-panel>
</template>
<script>
import Kpi from "@/components/ui/Kpi/index.vue";
import {KpiIdentifier} from "@/enum/KpiIdentifier";
import {DevComponentContainer, DevSettingsPanel} from "@seriousgamesinteractive/components-vue3/DevComponent";
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";

export default {
    name: 'KpiDev',
    computed: {
        KpiIdentifier() {
            return KpiIdentifier
        }
    },
    components: {
        BootstrapSelect,
        DevSettingsPanel,
        Kpi,
    },
    extends: DevComponentContainer,

    data() {
        return {
            value: 10,
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
