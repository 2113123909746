<template>
    <div class="left-ui">
        <div class="left-ui-background-line-through"/>

        <top-circle :metric-label="$t('game.labelMinutes')" :metric-value="formattedTimeElapsed" side="left"
                    @click:icon="onClickCircleIcon"/>

        <div class="left-ui-questions">
            <left-ui-question v-for="n in 10" :question-number="10 - n + 1" :state="questionStates[10 - n]"/>
        </div>

        <div class="left-ui-bottom d-flex gap-3 align-items-center justify-content-center">
            <mute-button v-model="isMutedLocal"/>

            <div class="btn-back-container">
                <transition name="fade">
                    <button v-show="questionNumber === 1" :title="$t('generic.buttonBack')" class="btn-back"
                            @click="goBack">
                        {{ $t('generic.buttonBack') }}
                    </button>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import LeftUiQuestion from "@/components/ui/LeftUi/LeftUiQuestion.vue";
import TopCircle from "@/components/ui/TopCircle.vue";
import {formatSecondsToTime} from "@seriousgamesinteractive/utils/timeUtil";
import MuteButton from "@/components/common/MuteButton.vue";

export default {
    name: 'LeftUi',
    components: {
        MuteButton,
        TopCircle,
        LeftUiQuestion,
    },
    props: {
        secondsElapsed: {
            type: Number,
            required: true,
        },
        questionNumber: {
            type: Number,
            required: true,
        },
        isMuted: {
            type: Boolean,
            required: true,
        },
        questionStates: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        }
    },
    data() {
        return {
            isMutedLocal: this.isMuted,
            devPanelActive: false,
            devPanelClickIterator: 0,
        };
    },
    computed: {
        formattedTimeElapsed() {
            return formatSecondsToTime(this.secondsElapsed);
        }
    },
    methods: {
        goBack() {
            this.$emit('click:back');
        },
        onClickCircleIcon() {
            if (this.devPanelActive) {
                return;
            }

            this.devPanelClickIterator++;

            if (this.devPanelClickIterator < 5) {
                return;
            }

            this.devPanelActive = true;

            this.$emit('activate:devPanel');
        },
    },
    mounted() {
        if (this.isMutedLocal !== this.isMuted) {
            this.isMutedLocal = this.isMuted;
        }
    },
    watch: {
        isMuted() {
            if (this.isMutedLocal === this.isMuted) {
                return;
            }

            this.isMutedLocal = this.isMuted;
        },
        isMutedLocal() {
            if (this.isMutedLocal === this.isMuted) {
                return;
            }

            this.$emit('mute:update', this.isMutedLocal);
        }
    },
    emits: ['mute:update', 'click:back', 'activate:devPanel']
}
</script>

<style lang="scss" scoped>
.left-ui-background-line-through {
    position: absolute;
    top: 0;
    left: 50%;
    height: calc(100% - 200px);
    width: 5px;
    transform: translateX(-2.5px);
    background: linear-gradient(#966838, #6A2601);
}

.btn-back-container {
    width: 104px;
    height: 60px;
    margin: 0;
    padding: 0;
}

.left-ui-question {
    &:first-child {
        margin-top: 0;
    }

    margin-top: 10px;
}

.left-ui {
    position: relative;
    //background: rgba(255, 0, 0, .8);
    padding-top: 220px;
    height: 100%;
}

.left-ui-questions {
    margin-top: 70px;
    padding: 0 70px;
    transform: scale(1.0);
    margin-bottom: 30px;
    transform-origin: top;
}

.left-ui-bottom {
    padding: 12px;
}

.btn-mute,
.btn-back {
    border: 0;
    outline: 0;
    padding: 0;
    position: relative;
}

.btn-back {
    background: url("@/assets/buttons/btn-back.png");
    width: 120px;
    height: 59px;

    line-height: 59px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14pt;
}
</style>
