<template>
    <div class="left-ui-container">
        <left-ui
            :question-number="questionNumber"
            :is-muted="isMuted"
            :seconds-elapsed="secondsElapsed"
            :question-states="questionStates"
            class="left-ui"
            @mute:toggle="onMuteToggle"
            @click:back="onClickBack"
        />
    </div>
    <dev-settings-panel anchor="bottomRight">
        <div class="row">
            <label>Seconds elapsed ({{ secondsElapsed }})</label>
            <input v-model.number="secondsElapsed" class="form-control" max="300" min="0" type="range"/>
        </div>
        <div class="row">
            <label>Question number ({{ questionNumber }})</label>
            <input v-model.number="questionNumber" class="form-control" max="10" min="1" type="range"/>
        </div>
        <div class="row">
            <label>Is muted</label>
            <bootstrap-select v-model="isMuted" :options="isMutedOptions"/>
        </div>
    </dev-settings-panel>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import LeftUi from "@/components/ui/LeftUi/index.vue";
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";

export default {
    name: 'LeftUiDev',
    components: {
        BootstrapSelect,
        DevSettingsPanel,
        EventTicks,
        LeftUi,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        const questionNumberOptions = [];

        for (let i = 1; i <= 10; i++) {
            questionNumberOptions.push({
                value: i,
                label: i.toString()
            });
        }

        const isMutedOptions = [
            {
                value: false,
                label: 'No',
            },
            {
                value: true,
                label: 'Yes',
            },
        ];

        return {
            events: ['mute:toggle', 'click:back'],

            questionNumberOptions: questionNumberOptions,
            isMutedOptions: isMutedOptions,

            secondsElapsed: 10,
            questionNumber: 1,
            isMuted: false,
            questionStates: [],
        }
    },
    mounted() {
        this.generateQuestionStates();
    },
    watch: {
        questionNumber() {
            this.generateQuestionStates();
        },
    },
    methods: {
        generateQuestionStates() {
            const questionStates = [];

            for (let i = 1; i <= 10; i++) {
                let questionState = 'inactive';

                if (i === this.questionNumber) {
                    questionState = 'active';
                } else if (i < this.questionNumber) {
                    questionState = i % 2 === 0 ? 'correct' : 'incorrect';
                }

                questionStates.push(questionState);
            }

            this.questionStates = questionStates;
        },
        onMuteToggle() {
            this.triggerEvent('mute:toggle')

            this.isMuted = !this.isMuted;
        },
        onClickBack() {
            this.triggerEvent('click:back')
        },
    },
}
</script>

<style lang="scss" scoped>
.left-ui-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}
</style>
