<template>
    <button :class="modelValue ? 'btn-mute-on' : 'btn-mute-off'" :title="$t('generic.buttonMute')" class="btn-mute"
            @click="toggleMute">
        <img class="bg bg-mute" src="@/assets/buttons/btn-mute.png"/>
        <img class="bg bg-unmute" src="@/assets/buttons/btn-unmute.png"/>

        <img class="icon icon-mute" src="@/assets/icons/icon-mute.png"/>
        <img class="icon icon-unmute" src="@/assets/icons/icon-unmute2.png"/>
    </button>
</template>
<script>
export default {
    name: 'MuteButton',
    props: {
        modelValue: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    emits: ['update:modelValue'],
    methods: {
        toggleMute() {
            this.$emit('update:modelValue', !this.modelValue);
        }
    },
}
</script>

<style lang="scss" scoped>
.btn-mute {
    width: 76px;
    height: 59px;
    background: transparent;
    position: relative;

    &:hover {
        top: 1px;
    }

    &:active {
        top: 2px;
    }

    .icon, .bg {
        transition: 150ms ease-out;
    }

    &-off {
        .icon-unmute, .bg-unmute {
            opacity: 0;
        }

        .icon-mute, .bg-mute {
            opacity: 1;
        }
    }

    &-on {
        .icon-unmute, .bg-unmute {
            opacity: 1;
        }

        .icon-mute, .bg-mute {
            opacity: 0;
        }
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
