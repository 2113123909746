<template>
    <div :class="computedClasses" class="intro-view pt-4">
        <app-logo :label="$t('intro.logoText')" class="app-logo"/>

        <version-container :attach-corner="true" :x-offset="10" :y-offset="10" attachment-point="bottomRight"/>

        <div class="language-button-container">
            <h1 v-if="quizTitle">{{ quizTitle }}</h1>

            <h2>{{ $t('intro.selectLanguage', 'en') }} / {{ $t('intro.selectLanguage', 'de') }} / {{ $t('intro.selectLanguage', 'ru') }}</h2>

            <div v-if="loading" class="loading-message">
                {{ $t('generic.loading', 'en') }} / {{ $t('generic.loading', 'de') }} / {{ $t('generic.loading', 'ru') }}
            </div>
            <div v-else>
                <app-button-row class="language-buttons">
                    <app-button
                        v-for="language of allLanguagesSorted"
                        :state="language === language.languageCode ? 'active' : null"
                        @click="setLanguage(language)"
                    >
                        {{ formatLanguageName(language) }}
                    </app-button>
                </app-button-row>
            </div>
        </div>

        <app-button-row class="app-logo-continue-button-container">
            <app-button @click="onLogoContinueButtonClick">{{ $t('generic.buttonOk') }}</app-button>
        </app-button-row>

        <div :class="'disclaimer-container-' + (hideDisclaimer ? 'hide' : 'show')" class="disclaimer-container">
            <disclaimer/>
        </div>

        <transition name="fade">
            <div v-if="showHandleWithCareMessage" class="intro-message-container">
                <div class="intro-message-logo-container">
                    <handle-with-care-logo :language="language" />
                </div>
                <div class="intro-message-container-text">
                    <p>
                        <markdown-hover-text>{{ $t('intro.handleWithCareMessage') }}</markdown-hover-text>
                    </p>
                </div>
                <div class="intro-message-continue-button">
                    <app-button-row class="language-buttons">
                        <app-button @click="onHandleWithCareClickContinue">{{ $t('generic.buttonOk') }}</app-button>
                    </app-button-row>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import AppButton from "@/components/common/AppButton.vue";
import {mapGetters} from "vuex";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import AppLogo from "@/components/ui/AppLogo.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import {useRoute} from 'vue-router';
import Disclaimer from "@/components/ui/Disclaimer.vue";
import {NoDragImage, TextFit} from "@seriousgamesinteractive/components-vue3/common";
import {timeoutPromise} from "@seriousgamesinteractive/utils/promiseUtil";
import VersionContainer from "@/components/ui/VersionContainer.vue";
import HandleWithCareLogo from "@/components/ui/HandleWithCareLogo.vue";

export default {
    name: 'QuizLanguageSelectView',
    components: {
        HandleWithCareLogo,
        NoDragImage,
        Disclaimer,
        TextFit,
        MarkdownHoverText,
        AppLogo,
        AppButtonRow,
        AppButton,
        VersionContainer,
    },
    data() {
        return {
            languageSelectTimer: null,
            languageCommitted: false,
            hideLogo: false,
            showLogoContinueButton: false,

            loading: true,
            languages: [],

            hideDisclaimer: false,
            showHandleWithCareMessage: false,

            quiz: null,
        };
    },
    computed: {
        ...mapGetters(['language']),
        masterQuizId() {
            return useRoute().params.masterQuizId;
        },
        quizTitle() {
            if (!this.quiz) {
                return;
            }

            return this.quiz.title;
        },
        computedClasses() {
            return [
                this.languageCommitted ? 'intro-language-committed' : '',
                this.hideLogo ? 'intro-hide-logo' : '',
                this.showLogoContinueButton ? 'intro-show-logo-continue-button' : '',
                this.$i18n.locale ? ('intro-language-committed-' + this.$i18n.locale) : '',
            ];
        },
		allLanguagesSorted() {
			const prioritizedStaticLanguageOrder = ['en', 'de', 'ru'];

			return this.quiz.allLanguages.sort((a, b) => {
				const aIndex = prioritizedStaticLanguageOrder.indexOf(a.languageCode);
				const bIndex = prioritizedStaticLanguageOrder.indexOf(b.languageCode);

				if (aIndex !== -1 && bIndex !== -1) {
					// Both are in the staticLanguageOrder list
					return aIndex - bIndex;
				} else if (aIndex !== -1) {
					// Only 'a' is in the staticLanguageOrder list
					return -1;
				} else if (bIndex !== -1) {
					// Only 'b' is in the staticLanguageOrder list
					return 1;
				} else {
					// Convert a and b to strings before comparing
					// Neither are in the staticLanguageOrder list, sort alphabetically
					return a.languageCode.localeCompare(b.languageCode);
				}
			});
		},
    },
    mounted() {
        this.loading = true;

        this.$apiClient.quiz.getMasterQuiz(this.masterQuizId).then((quiz) => {
            this.quiz = quiz;

            this.loading = false;
        });
    },
    methods: {
        onLogoContinueButtonClick() {
            this.showHandleWithCareMessage = true;
            this.showLogoContinueButton = false;
            this.hideLogo = true;
        },
        formatLanguageName(language) {
            return language.localizedLanguage;

            if (language.language === language.localizedLanguage) {
                return language.language;
            } else {
                return `${language.localizedLanguage} (${language.language})`;
            }
        },
        clearTimers() {
            if (this.languageSelectTimer) {
                clearTimeout(this.languageSelectTimer);

                this.languageSelectTimer = null;
            }

            if (this.languageCommittedTimer) {
                clearTimeout(this.languageCommittedTimer);

                this.languageCommittedTimer = null;
            }
        },
        setLanguage(language) {
            if (this.languageCommitted) {
                return;
            }

            this.clearTimers();

            const languageCode = language.languageCode;

            this.$i18n.locale = languageCode;
            this.$root.$i18n.locale = languageCode;

            this.$store.commit('language', languageCode);
            this.$store.commit('languageLogo', language.logoType);

            this.languageSelectTimer = setTimeout(() => {
                this.commitLanguage();
            }, 1500);
        },
        commitLanguage() {
            if (this.languageCommitted) {
                return;
            }

            this.languageCommitted = true;
            this.hideDisclaimer = true;
            this.showLogoContinueButton = true;

            this.clearTimers();
        },
        onHandleWithCareClickContinue() {
            this.showHandleWithCareMessage = false;
            this.hideDisclaimer = true;

            return timeoutPromise(1000).then(() => {
                this.$router.push(`/quiz/${this.masterQuizId}/${this.language}`);
            });
        },
    },
    unmounted() {
        this.clearTimers();
    },
};
</script>

<style lang="scss" scoped>
.app-logo, .language-button-container, .app-logo-continue-button-container, .disclaimer-container {
    transition: 800ms cubic-bezier(0.7, -0.3, 0.3, 1.2);
}

.app-logo {
    position: absolute;
    top: 210px;
    left: 50%;
    transform: translateX(-50%);
}

.language-button-container {
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1 {
        margin-top: 40px;
        font-size: 40pt;
        font-weight: bold;
    }

    h2 {
        margin-top: 30px;
        margin-bottom: 50px;
        font-size: 35pt;
    }
}

.disclaimer-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    line-height: 80%;

    &-hide {
        bottom: -80px;
        opacity: 0;
    }

    &-show {
        bottom: 20px;
        opacity: 1;
    }
}

.intro-hide-logo {
    .app-logo {
        top: -500px;
        opacity: 0;
    }
}

.app-logo-continue-button-container {
    position: absolute;
    bottom: -500px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
}

.intro-show-logo-continue-button {
    .app-logo-continue-button-container {
        bottom: 200px;
        opacity: 1;
    }

    .app-logo {
        top: 300px;
    }
}

.intro-language-committed {
    .language-button-container {
        bottom: -500px;
        opacity: 0;
    }
}

.disclaimer-content {
    &-hide {
        bottom: -100px;
        opacity: 0;
    }
}

.loading-message {
    font-size: 25pt;
    font-weight: bold;
}

.intro-message-container {
    position: absolute;
    top: calc(50% + 90px);
    left: 50%;
    max-width: 1200px;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-size: 25pt;

    img {
        margin-bottom: 100px;
    }
}

.intro-message-logo-container {
    background: white;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    height: fit-content;
    margin: 0 auto 30px auto;
}

.intro-message-continue-button {
    margin-top: 40px;
}

.intro-message-container-text {
    height: 400px;
    position: relative;

    > p {
        width: 100%;
        height: fit-content;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}
</style>
