<template>
    <amr-popup class="learn-more-popup">
        <template v-slot:content>
            <div class="content-container">
                <h1>{{ $t('end.buttonLearnMore') }}</h1>

                <div class="questions-container">
                    <div v-for="question of referenceQuestions" class="question-container">
                        <h2>
                            <markdown-hover-text :inline="true">{{ question.title.replace(/\s+/g, ' ') }}</markdown-hover-text>
                        </h2>

                        <ul class="question-reference-links">
                            <li v-for="referenceLink of question.referenceLinks">
                                <markdown-text :inline="true" link-target="blank">{{ referenceLink }}</markdown-text>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <app-button-row class="button-container" size="xs">
                <app-button @click="onClickOk">{{ $t('generic.buttonOk') }}</app-button>
            </app-button-row>
        </template>
    </amr-popup>
</template>

<script>
import AppButton from "@/components/common/AppButton.vue";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";
import AmrPopup from '@/components/ui/AmrPopup.vue';
import MarkdownText from "@/components/common/MarkdownText/index.vue";

export default {
    name: 'LearnMorePopup',
    components: {
        MarkdownHoverText,
        MarkdownText,
        LifeLineButton,
        NoDragImage, AmrPopup, AppButtonRow, TimeLeft, AppButton
    },
    props: {
        quiz: {
            type: Object,
            requires: true,
        }
    },
    emits: ['click:ok'],
    computed: {
        referenceQuestions() {
            const referenceQuestions = [];

            for (const question of this.quiz.questions) {
                if (question.referenceLinks && question.referenceLinks.length > 0) {
                    referenceQuestions.push(question);
                }
            }

            return referenceQuestions;
        }
    },
    methods: {
        onClickOk() {
            this.$emit('click:ok');
        },
    },
}
</script>

<style lang="scss" scoped>
h1 {
    font-weight: bold;
}

.content-container {
    padding: 20px;
}

.questions-container {
    height: 470px;
    overflow-y: scroll;
    border: 1px solid rgba(255, 255, 255, .3);
    padding: 10px;
    border-radius: 5px;

    .question-container {
        text-align: left;

        h2 {
            font-size: 15pt;
            font-weight: bold;
            text-align: left;
        }

        ul {
            padding-left: 60px;

            li {
                font-size: 14pt;

                a {
                    color: white;
                    text-decoration: none;

                    &:hover, &:active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
</style>


<style lang="scss">
.learn-more-popup {
    .question-container {
        text-align: left;

        ul {
            padding-left: 60px;
            text-align: left;

            li {
                font-size: 14pt;
                text-align: left;

                a {
                    color: white;
                    text-decoration: none;

                    &:hover, &:active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
</style>
