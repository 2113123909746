<template>
    <div class="disclaimer">
        <div class="disclaimer-lang disclaimer-lang-en">
            <markdown-hover-text>{{ $t('intro.disclaimer', 'en') }}</markdown-hover-text>
        </div>
        <div class="disclaimer-lang disclaimer-lang-ru">
            <markdown-hover-text>{{ $t('intro.disclaimer', 'ru') }}</markdown-hover-text>
        </div>
		<div class="disclaimer-lang disclaimer-lang-de">
			<markdown-hover-text>{{ $t('intro.disclaimer', 'de') }}</markdown-hover-text>
		</div>
    </div>
</template>
<script>
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";

export default {
    name: 'Disclaimer',
    components: {
        MarkdownHoverText,
    },
}
</script>

<style lang="scss" scoped>
.disclaimer-lang {
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }
}
</style>
