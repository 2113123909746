// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/buttons/btn-info.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".question-information-popup .answer-explanation[data-v-059dd184] {\n  width: 1150px;\n  text-align: left;\n  margin-top: 50px;\n  margin-bottom: 50px;\n  margin-left: 25px;\n  padding: 10px;\n  height: 500px;\n  overflow-y: scroll;\n}\n.question-information-popup .answer-container[data-v-059dd184] {\n  width: -moz-fit-content;\n  width: fit-content;\n  max-width: 600px;\n  margin: 0 auto;\n  text-align: left;\n  transform: translateX(-50%);\n  left: 50%;\n  position: absolute;\n}\n.question-information-popup .answer-container .answer[data-v-059dd184] {\n  padding: 2px 15px;\n  border: 2px solid transparent;\n}\n.question-information-popup .answer-container .answer-correct[data-v-059dd184] {\n  background: rgba(128, 188, 0, 0.6);\n  border: 2px solid #6A2600;\n  border-radius: 10px;\n}\n.question-information-popup .answer-container .answer[data-v-059dd184]:before {\n  width: 50px;\n  margin-right: 15px;\n  font-weight: bold;\n}\n.question-information-popup .answer-container .answer-0[data-v-059dd184]:before {\n  content: \"A\";\n}\n.question-information-popup .answer-container .answer-1[data-v-059dd184]:before {\n  content: \"B\";\n}\n.question-information-popup .answer-container .answer-2[data-v-059dd184]:before {\n  content: \"C\";\n}\n.question-information-popup .answer-container .answer-3[data-v-059dd184]:before {\n  content: \"D\";\n}\n.question-information-icon[data-v-059dd184] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 84px;\n  height: 71px;\n  padding: 10px;\n  position: absolute;\n  top: -35.5px;\n  left: 50%;\n  transform: translateX(-50%);\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
