<template>
    <div :class="'top-circle-side-' + side" class="top-circle">
        <div class="content">
            <img v-if="icon" :src="icon" class="icon" @click="onClickIcon"/>

            <label class="metric-value">{{ metricValue }}</label>
            <label class="metric-label">{{ metricLabel }}</label>
        </div>
    </div>
</template>
<script>
import IconHourGlass from "@/assets/icons/icon-hourglass.png";
import IconStar from "@/assets/icons/icon-star.png";

export default {
    name: 'TopCircle',
    props: {
        side: {
            type: String,
            required: true,
        },
        metricValue: {
            type: [Number, String],
            required: true,
        },
        metricLabel: {
            type: String,
            required: true,
        },
    },
    computed: {
        icon() {
            if (this.side === 'left') {
                return IconHourGlass;
            } else if (this.side === 'right') {
                return IconStar;
            }

            return null;
        }
    },
    emits: ['click:icon'],
    methods: {
        onClickIcon() {
            this.$emit('click:icon');
        },
    },
}
</script>

<style lang="scss" scoped>
.content {
    position: absolute;
    top: 250px;
    width: 200px;
    height: 200px;
}

.top-circle {
    position: absolute;
    top: -290px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 555px;
    height: 555px;

    &.top-circle-side-left {
        left: -100px;
        background-image: url("@/assets/ui/circle-top-left.png");

        .content {
            left: calc(50% - 120px);
        }
    }

    &.top-circle-side-right {
        right: -64px;
        background-image: url("@/assets/ui/circle-top-right.png");

        .content {
            left: calc(50% - 80px);
        }
    }
}

.icon {
    margin-top: 50px;
    transform: scale(0.8);
}

label {
    display: block;
    text-align: center;

    &.metric-value {
        font-size: 36pt;
        font-weight: bold;
        letter-spacing: 6px;
        margin-top: -20px;
        margin-bottom: -20px;
    }

    &.metric-label {
        text-transform: uppercase;
        font-size: 20pt;
        font-weight: bold;
        letter-spacing: 1px;
    }
}
</style>
