import {addGetParamsToUrl} from "@seriousgamesinteractive/api-client-vue3";

export default {
    getLocalizedQuizStats(masterQuizId, languageCode, timeRange = null) {
        if (timeRange === 'allTime' || !timeRange) {
            timeRange = null;
        }

        let statsUrl = `/admin/quiz/${masterQuizId}/${languageCode}/stats`;

        if (timeRange) {
            statsUrl = addGetParamsToUrl(statsUrl, {
                timeFrom: timeRange[0],
                timeTo: timeRange[1],
            });
        }

        return this.get(statsUrl).then((data) => {
            const ms = Math.round(data.timeTaken * 1000);

            if (timeRange) {
                console.log(`Loading stats from ${timeRange[0]} to ${timeRange[1]} took ${ms} ms`);
            } else {
                console.log(`Loading stats took ${ms} ms`);
            }

            return data.stats;
        });
    },
    verifyLogin() {
        return this.get(`/admin/verify`);
    },
};
