<template>
    <div class="previous-answers-popup-dev-container">
        <end-popup
            :body-text="bodyText"
            :bonus-points="bonusPoints"

            :correct-answers="correctAnswers"
            :correct-answers-max="correctAnswersMax"

            :final-score="finalScore"

            :final-score-max="finalScoreMax"

            @click:challengeFriend="onClickChallengeFriend"
            @click:learnMore="onClickLearnMore"
            @click:playAgain="onClickPlayAgain"
        />
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <locale-select-dev />
        <quiz-select-dev @quiz-changed="quiz = $event" :show-question="false" />

        <div class="row">
            <label>Final score ({{ finalScore }})</label>
            <input v-model.number="finalScore" class="form-control" max="1000" min="0" type="range"/>
        </div>
        <div class="row">
            <label>Final score max ({{ finalScoreMax }})</label>
            <input v-model.number="finalScoreMax" class="form-control" max="1000" min="1" type="range"/>
        </div>
        <div class="row">
            <label>Correct answers ({{ correctAnswers }})</label>
            <input v-model.number="correctAnswers" class="form-control" max="10" min="0" type="range"/>
        </div>
        <div class="row">
            <label>Correct answers max ({{ correctAnswersMax }})</label>
            <input v-model.number="correctAnswersMax" class="form-control" max="10" min="1" type="range"/>
        </div>
    </dev-settings-panel>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>

import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import HintHelpPopup from "@/components/ui/HintHelpPopup/index.vue";
import EndPopup from "@/components/ui/EndPopup/index.vue";
import LocaleSelectDev from "@/components/dev/LocaleSelectDev.vue";
import QuizSelectDev from "@/components/dev/QuizSelectDev.vue";

export default {
    name: 'EndPopupDev',
    components: {
        QuizSelectDev, LocaleSelectDev,
        EndPopup,
        HintHelpPopup,
        BootstrapSelect,
        DevSettingsPanel,
        EventTicks,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        return {
            events: ['click:challengeFriend', 'click:learnMore', 'click:playAgain'],

            finalScore: 7540,
            finalScoreMax: 10000,

            correctAnswers: 7,
            correctAnswersMax: 10,

            bonusPoints: 345,

            quiz: null,

            bodyText: 'This is the end body text',
        }
    },
    methods: {
        onClickChallengeFriend() {
            this.triggerEvent('click:challengeFriend')
        },
        onClickLearnMore() {
            this.triggerEvent('click:learnMore')
        },
        onClickPlayAgain() {
            this.triggerEvent('click:playAgain')
        },
    },
}
</script>

<style lang="scss" scoped>
.previous-answers-popup-dev-container {
    margin-top: 50px;
}
</style>
