// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/ui/circle-top-left.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/ui/circle-top-right.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content[data-v-3409e88c] {\n  position: absolute;\n  top: 250px;\n  width: 200px;\n  height: 200px;\n}\n.top-circle[data-v-3409e88c] {\n  position: absolute;\n  top: -290px;\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n  width: 555px;\n  height: 555px;\n}\n.top-circle.top-circle-side-left[data-v-3409e88c] {\n  left: -100px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.top-circle.top-circle-side-left .content[data-v-3409e88c] {\n  left: calc(50% - 120px);\n}\n.top-circle.top-circle-side-right[data-v-3409e88c] {\n  right: -64px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.top-circle.top-circle-side-right .content[data-v-3409e88c] {\n  left: calc(50% - 80px);\n}\n.icon[data-v-3409e88c] {\n  margin-top: 50px;\n  transform: scale(0.8);\n}\nlabel[data-v-3409e88c] {\n  display: block;\n  text-align: center;\n}\nlabel.metric-value[data-v-3409e88c] {\n  font-size: 36pt;\n  font-weight: bold;\n  letter-spacing: 6px;\n  margin-top: -20px;\n  margin-bottom: -20px;\n}\nlabel.metric-label[data-v-3409e88c] {\n  text-transform: uppercase;\n  font-size: 20pt;\n  font-weight: bold;\n  letter-spacing: 1px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
