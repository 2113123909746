// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/buttons/btn-back.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".left-ui-background-line-through[data-v-40a99fce] {\n  position: absolute;\n  top: 0;\n  left: 50%;\n  height: calc(100% - 200px);\n  width: 5px;\n  transform: translateX(-2.5px);\n  background: linear-gradient(#966838, #6A2601);\n}\n.btn-back-container[data-v-40a99fce] {\n  width: 104px;\n  height: 60px;\n  margin: 0;\n  padding: 0;\n}\n.left-ui-question[data-v-40a99fce] {\n  margin-top: 10px;\n}\n.left-ui-question[data-v-40a99fce]:first-child {\n  margin-top: 0;\n}\n.left-ui[data-v-40a99fce] {\n  position: relative;\n  padding-top: 220px;\n  height: 100%;\n}\n.left-ui-questions[data-v-40a99fce] {\n  margin-top: 70px;\n  padding: 0 70px;\n  transform: scale(1);\n  margin-bottom: 30px;\n  transform-origin: top;\n}\n.left-ui-bottom[data-v-40a99fce] {\n  padding: 12px;\n}\n.btn-mute[data-v-40a99fce],\n.btn-back[data-v-40a99fce] {\n  border: 0;\n  outline: 0;\n  padding: 0;\n  position: relative;\n}\n.btn-back[data-v-40a99fce] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 120px;\n  height: 59px;\n  line-height: 59px;\n  text-align: center;\n  color: white;\n  font-weight: bold;\n  font-size: 14pt;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
