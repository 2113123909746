<template>
    <amr-popup-with-timeout :timeout-seconds="60" class="previous-answers-popup" timer-width="md" @timeout="onTimeout">
        <template v-slot:icon>
            <life-line-button :identifier="LifeLineIdentifier.Stats" :is-interactable="false"/>
        </template>

        <template v-slot:content>
            <p>{{ $t('game.lifelineHowPreviousPlayersAnswered') }}</p>

            <div class="chart-container">
                <previous-answers-chart
                    :answer-count-a="answerCountA || 0"
                    :answer-count-b="answerCountB || 0"
                    :answer-count-c="answerCountC || 0"
                    :answer-count-d="answerCountD || 0"
                />
            </div>
        </template>

        <template v-slot:footer-buttons>
            <app-button-row class="button-container" size="xs">
                <app-button @click="onClickOk">{{ $t('generic.buttonOk') }}</app-button>
            </app-button-row>
        </template>
    </amr-popup-with-timeout>
</template>

<script>
import AppButton from "@/components/common/AppButton.vue";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import PreviousAnswersChart from "@/components/ui/PreviousAnswersChart/index.vue";
import AmrPopupWithTimeout from "@/components/ui/AmrPopupWithTimeout.vue";
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";

export default {
    name: 'PreviousAnswersPopup',
    computed: {
        LifeLineIdentifier() {
            return LifeLineIdentifier
        }
    },
    components: {
        LifeLineButton,
        NoDragImage, AmrPopupWithTimeout, PreviousAnswersChart, MarkdownHoverText, AppButtonRow, TimeLeft, AppButton
    },
    props: {
        answerCountA: {
            type: Number,
            required: true,
        },
        answerCountB: {
            type: Number,
            required: true,
        },
        answerCountC: {
            type: Number,
            required: true,
        },
        answerCountD: {
            type: Number,
            required: true,
        },
    },
    emits: ['click:ok', 'timeout'],
    methods: {
        onTimeout() {
            this.$emit('timeout');
        },
        onClickOk() {
            this.$emit('click:ok');
        },
    },
}
</script>

<style lang="scss" scoped>
$popupHeight: 600px;
$descriptionPadding: 60px;

.previous-answers-popup {
    p {
        font-size: 23pt;
        padding: $descriptionPadding 0;
        margin: 0;
    }

    .chart-container {
        height: $popupHeight - ($descriptionPadding * 2) - 50px;
    }
}
</style>
