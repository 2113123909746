<template>
    <amr-popup :class="computedClasses" :height="height" :width="width" class="amr-popup-with-timeout">
        <template v-slot:icon>
            <slot name="icon"></slot>
        </template>

        <template v-slot:header>
            <time-left :seconds-left="secondsLeft" :seconds-total="timeoutSeconds" class="question-time-left"/>

            <slot name="header"></slot>
        </template>

        <template v-slot:content>
            <slot name="content"></slot>
        </template>

        <template v-slot:footer-buttons>
            <slot name="footer-buttons"></slot>
        </template>
    </amr-popup>
</template>

<script>
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import AmrPopup from "@/components/ui/AmrPopup.vue";

export default {
    name: 'AmrPopupWithTimeout',
    components: {TimeLeft, AmrPopup},
    props: {
        width: {
            type: String,
            required: false,
            default: 'md',
        },
        height: {
            type: String,
            required: false,
            default: 'md',
        },
        timeoutSeconds: {
            type: Number,
            required: true,
        },
        timerWidth: {
            type: String,
            required: false,
            default: 'lg',
        }
    },
    computed: {
        computedClasses() {
            return [
                `amr-popup-with-timeout-timer-width-${this.timerWidth}`,
            ]
        },
    },
    emits: ['timeout'],
    data() {
        return {
            secondsLeft: this.timeoutSeconds,
        };
    },
    watch: {
        timeoutSeconds() {
            this.restartTimer();
        },
    },
    mounted() {
        this.restartTimer();
    },
    beforeUnmount() {
        this.clearTick();
    },
    methods: {
        restartTimer() {
            this.secondsLeft = this.timeoutSeconds;

            this.startTick();
        },
        clearTick() {
            if (this.tickTimeout) {
                clearTimeout(this.tickTimeout);

                this.tickTimeout = null;
            }
        },
        startTick() {
            this.clearTick();

            this.tickTimeout = setTimeout(() => {
                this.tick();
            }, 1000);
        },
        tick() {
            this.secondsLeft -= 1;

            if (this.secondsLeft <= 0) {
                this.$emit('timeout');

                return;
            }

            this.startTick();
        },
    },
}
</script>

<style lang="scss" scoped>
.amr-popup-with-timeout {
    .question-time-left {
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
    }

    &-timer-width {
        &-md {
            .question-time-left {
                width: 75%;
            }
        }

        &-lg {
            .question-time-left {
                width: 90%;
            }
        }
    }
}
</style>
