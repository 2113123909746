<template>
    <div :class="computedClasses" class="left-ui-question">
        <div class="left-ui-question-backgrounds">
            <div class="left-ui-question-background left-ui-question-background-inactive">
                <img src="@/assets/ui/bg-question-number-inactive.png"/>
            </div>

            <div class="left-ui-question-background left-ui-question-background-active">
                <img src="@/assets/ui/bg-question-number-active.png"/>
            </div>

            <div class="left-ui-question-background left-ui-question-background-incorrect">
                <img src="@/assets/ui/bg-question-number-incorrect.png"/>
            </div>

            <div class="left-ui-question-background left-ui-question-background-correct">
                <img src="@/assets/ui/bg-question-number-correct.png"/>
            </div>
        </div>
        <label>{{ questionNumber }}</label>
        <no-drag-image v-if="icon" :src="icon" class="left-ui-question-icon"/>
    </div>
</template>
<script>
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";
import IconHexTick from "@/assets/icons/icon-hex-tick.png";
import IconHexX from "@/assets/icons/icon-hex-x.png";

import IconQuestionTick from "@/assets/icons/icon-question-tick.png";
import IconQuestionX from "@/assets/icons/icon-question-x.png";

export default {
    name: 'LeftUiQuestion',
    components: {NoDragImage},
    props: {
        questionNumber: {
            type: Number,
            required: true,
        },
        state: {
            type: String,
            required: false,
            default: null,
        }
    },
    computed: {
        icon() {
            if (this.state === 'correct') {
                return IconQuestionTick;
            } else if (this.state === 'incorrect') {
                return IconQuestionX;
            }

            return null;
        },
        computedClasses() {
            return [
                'left-ui-question-' + (this.state || 'inactive'),
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.left-ui-question {
    position: relative;
    width: 177px;
    height: 55px;

    .left-ui-question-icon {
        position: absolute;
        top: 3px;
        left: -28px;
    }

    .left-ui-question-backgrounds,
    .left-ui-question-background {
        position: absolute;
        top: 0;
        left: 0;
    }

    .left-ui-question-background {
        opacity: 0;
    }

    .left-ui-question-background, label {
        transition: 200ms ease-in-out;
    }

    label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 20pt;
        font-weight: bold;
    }

    &.left-ui-question-active {
        label {
            opacity: 1;
        }

        .left-ui-question-background-active {
            opacity: 1;
        }
    }

    &.left-ui-question-inactive {
        label {
            opacity: 0.3;
        }

        .left-ui-question-background-inactive {
            opacity: 1;
        }
    }

    &.left-ui-question-incorrect {
        label {
            opacity: 1;
        }

        .left-ui-question-background-incorrect {
            opacity: 1;
        }
    }

    &.left-ui-question-correct {
        label {
            opacity: 1;
        }

        .left-ui-question-background-correct {
            opacity: 1;
        }
    }
}
</style>
