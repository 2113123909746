import {v4 as uuidv4} from 'uuid';
import hash from 'hash.js';
import {addGetParamsToUrl} from "@seriousgamesinteractive/api-client-vue3";

export default {
    getQuizzes() {
        return this.get(`/quiz`).then((data) => {
            return data.quizzes;
        });
    },
    getMasterQuiz(masterQuizId) {
        return this.get(`/quiz/${masterQuizId}`).then((data) => {
            return data.quiz;
        });
    },
    getLocalizedQuiz(masterQuizId, languageCode, randomizeAnswers = true) {
        if (languageCode === 'all') {
            languageCode = 'en';
        }

        let url = `/quiz/${masterQuizId}/${languageCode}`;

        if (!randomizeAnswers) {
            url = addGetParamsToUrl(url, {noRandomize: '1'});
        }

        return this.get(url).then((data) => {
            return data.quiz;
        });
    },
    getAnswerCountsQuizStats(masterQuizId, languageCode, questionNumber) {
        return this.get(`/quiz/${masterQuizId}/${languageCode}/stats/${questionNumber}`).then((data) => {
            console.log(`Loading stats took ${Math.round(data.timeTaken * 1000)} ms`);

            return data.answerCounts;
        });
    },
    submitQuizResult(masterQuizId, languageCode, {finalScore, kpiPoints, secondsTaken, givenAnswers}) {
        const uid = uuidv4();

        const resultSalt = process.env.VUE_APP_RESULT_VERIFICATION_SALT;

        const verificationCodeRaw = `${resultSalt}_${uid}_${finalScore}`;
        const verificationCode = hash.sha256().update(verificationCodeRaw).digest('hex');

        return this.post(`/quiz/${masterQuizId}/${languageCode}/result`, {
            uid,
            verificationCode,
            finalScore,
            kpiPoints,
            secondsTaken,
            givenAnswers,
        });
    },
};
