<template>
    <div :class="computedClasses" class="question-container">
        <div class="question-title-container">
            <label>
                <markdown-hover-text>{{ showQuestion ? title : '' }}</markdown-hover-text>
            </label>
        </div>
        <div class="question-body-container">
            <div class="question-body-text">
                <text-fit v-if="showQuestion" :initial-size="20" :min-size="10" size-unit="pt" :debug="true" :debug-step-time="0" :fit-height="290">
                    <markdown-hover-text>{{ question }}</markdown-hover-text>
                </text-fit>
            </div>

            <time-left :seconds-left="secondsLeft" :seconds-total="secondsTotal" class="question-time-left-container"/>

            <div class="question-lifelines d-flex align-items-center justify-content-center gap-4">
                <life-line-button
                    :identifier="LifeLineIdentifier.Hint"
                    :is-used="usedLifeLines[LifeLineIdentifier.Hint]"
                    @use="requestLifeline(LifeLineIdentifier.Hint)"
                />

                <life-line-button
                    :identifier="LifeLineIdentifier.FiftyFifty"
                    :is-used="usedLifeLines[LifeLineIdentifier.FiftyFifty]"
                    @use="requestLifeline(LifeLineIdentifier.FiftyFifty)"
                />

                <life-line-button
                    :identifier="LifeLineIdentifier.Stats"
                    :is-used="usedLifeLines[LifeLineIdentifier.Stats]"
                    @use="requestLifeline(LifeLineIdentifier.Stats)"
                />
            </div>
        </div>
        <div class="question-answers">
            <app-button-row>
                <answer-button id="answer-button-1" :answer="answers[0] || null"
                               :answer-index="0"
                               :interactable="canAnswer" :picked-other-answer="pickedAnswer >= 0 && pickedAnswer !== 0" :show-answer="computedShowAnswer1"
                               @click="pickAnswer(0)"/>
                <answer-button id="answer-button-2" :answer="answers[1] || null"
                               :answer-index="1"
                               :interactable="canAnswer" :picked-other-answer="pickedAnswer >= 0 && pickedAnswer !== 1" :show-answer="computedShowAnswer2"
                               @click="pickAnswer(1)"/>
            </app-button-row>
            <app-button-row>
                <answer-button id="answer-button-3" :answer="answers[2] || null"
                               :answer-index="2"
                               :interactable="canAnswer" :picked-other-answer="pickedAnswer >= 0 && pickedAnswer !== 2" :show-answer="computedShowAnswer3"
                               @click="pickAnswer(2)"/>
                <answer-button id="answer-button-4" :answer="answers[3] || null"
                               :answer-index="3"
                               :interactable="canAnswer" :picked-other-answer="pickedAnswer >= 0 && pickedAnswer !== 3" :show-answer="computedShowAnswer4"
                               @click="pickAnswer(3)"/>
            </app-button-row>
        </div>
        <div :key="question" class="point-tooltips">
            <points-tooltip
                v-show="timePoints"
                id="tooltip-time-points"
                :points="timePoints"
                class="tooltip-time-points"
                direction="left"
            />

            <points-tooltip
                v-show="answerPoints"
                id="tooltip-answer-points"
                :class="'tooltip-answer-points-' + answerPointsIndex"
                :direction="answerPointsIndex === 0 || answerPointsIndex === 2 ? 'right' : 'left'"
                :points="answerPoints"
                class="tooltip-answer-points"
            />
        </div>
    </div>
</template>

<script>
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";
import AppButton from "@/components/common/AppButton.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import AnswerButton from "@/components/ui/QuestionContainer/AnswerButton.vue";
import PointsTooltip from "@/components/ui/PointsTooltip.vue";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import {TextFit} from "@seriousgamesinteractive/components-vue3/common";
import Lightbox from "@seriousgamesinteractive/components-vue3/common/Lightbox.vue";

export default {
    name: 'QuestionContainer',
    components: {TimeLeft, PointsTooltip, AnswerButton, AppButton, LifeLineButton, MarkdownHoverText, AppButtonRow, TextFit},
    props: {
        title: {
            type: String,
            required: true,
        },
        question: {
            type: String,
            required: true,
        },
        secondsLeft: {
            type: Number,
            required: true,
        },
        secondsTotal: {
            type: Number,
            required: true,
        },
        usedLifeLines: {
            type: Object,
            required: true,
        },
        answers: {
            type: Array,
            required: true,
        },
        showQuestion: {
            type: Boolean,
            required: true,
        },
        showAnswer1: {
            type: Boolean,
            required: true,
        },
        showAnswer2: {
            type: Boolean,
            required: true,
        },
        showAnswer3: {
            type: Boolean,
            required: true,
        },
        showAnswer4: {
            type: Boolean,
            required: true,
        },
        canAnswer: {
            type: Boolean,
            required: true,
        },
        canUseLifelines: {
            type: Boolean,
            required: true,
        },
        timePoints: {
            type: Number,
            required: false,
            default: 0,
        },
        answerPointsIndex: {
            type: Number,
            required: false,
            default: null,
        },
        answerPoints: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    watch: {
        title() {
            this.pickedAnswer = -1;
        },
        question() {
            this.pickedAnswer = -1;
        },
        canAnswer() {
            if (!this.canAnswer) {
                this.pickedAnswer = -1;
            }
        }
    },
    data() {
        return {
            pickedAnswer: -1,
        };
    },
    emits: ['answer:pick', 'lifeline:request'],
    computed: {
        computedClasses() {
            return [
                'question-container-' + (this.showQuestion ? 'show' : 'hide'),
                `question-container-can-answer-` + (this.canAnswer ? 'yes' : 'no'),
                `question-container-answer-picked-` + (this.pickedAnswer >= 0 ? 'yes' : 'no'),
                `question-container-can-use-lifelines-` + (this.canUseLifelines ? 'yes' : 'no')
            ];
        },
        LifeLineIdentifier() {
            return LifeLineIdentifier
        },
        computedShowAnswer1() {
            if (!this.showAnswer1) {
                return false;
            }

            if (!this.answers || !this.answers[0]) {
                return false;
            }

            return true;
        },
        computedShowAnswer2() {
            if (!this.showAnswer2) {
                return false;
            }

            if (!this.answers || !this.answers[1]) {
                return false;
            }

            return true;
        },
        computedShowAnswer3() {
            if (!this.showAnswer3) {
                return false;
            }

            if (!this.answers || !this.answers[2]) {
                return false;
            }

            return true;
        },
        computedShowAnswer4() {
            if (!this.showAnswer4) {
                return false;
            }

            if (!this.answers || !this.answers[3]) {
                return false;
            }

            return true;
        },
    },
    methods: {
        pickAnswer(answerIndex) {
            if (!this.canAnswer) {
                return;
            }

            this.pickedAnswer = answerIndex;

            this.$emit('answer:pick', answerIndex);
        },
        requestLifeline(lifeLineIdentifier) {
            this.$emit('lifeline:request', lifeLineIdentifier);
        }
    },
}
</script>

<style lang="scss" scoped>
.question-time-left-container {
    transition: 500ms cubic-bezier(0.7, -0.3, 0.3, 1.2);
}

.question-lifelines {
    transition: 500ms cubic-bezier(0.7, -0.3, 0.3, 1.2);
}

.question-lifelines {
    overflow: hidden;
}

.question-container {
    width: 900px;
    position: relative;
    transition: 500ms cubic-bezier(0.7, -0.3, 0.3, 1.2);

    &-can-answer {
        &-yes {
            .question-time-left-container {
                filter: grayscale(0%);
            }
        }

        &-no {
            .question-lifelines, .question-time-left-container {
                filter: grayscale(100%);
            }
        }
    }

    &-can-use-lifelines {
        &-yes {
            .question-lifelines {
                height: 100px;
            }
        }

        &-no {
            .question-lifelines {
                height: 0;
            }
        }
    }

    &-hide {
        top: 800px;
        opacity: 0;
    }

    &-show {
        top: 0;
        opacity: 1;
    }
}

.question-title-container,
.question-body-container {
    border-radius: 7.612px;
    border: 2.349px solid #6A2600;
    background: #00205C;
    box-shadow: 0 11.74251px 4.697px 1.17425px rgba(0, 0, 0, 0.57) inset;

    color: white;
    text-align: center;
    width: 100%;
}

.question-title-container {
    font-size: 30pt;
    font-weight: bold;
    margin-bottom: 40px;

    div, p {
        margin: 0 !important;
    }
}

.question-body-container {
    position: relative;
    height: 420px;

    .question-body-text {
        position: absolute;
        height: fit-content;
        text-align: left;
        font-size: 20pt;
        width: calc(100% - 40px);
        padding: 45px 20px 50px 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .question-lifelines {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.question-answers {
    margin-top: 60px;
}

.tooltip-time-points {
    position: absolute;
    top: 45px;
    right: -230px;
}

.tooltip-answer-points {
    position: absolute;

    &.tooltip-answer-points-0 {
        bottom: 110px;
        left: -230px;
    }

    &.tooltip-answer-points-1 {
        bottom: 110px;
        right: -230px;
    }

    &.tooltip-answer-points-2 {
        bottom: -8px;
        left: -230px;
    }

    &.tooltip-answer-points-3 {
        bottom: -8px;
        right: -230px;
    }
}
</style>
