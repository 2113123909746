<template>
    <div class="right-ui-container">
        <right-ui
            :kpi-health-coverage="kpiHealthCoverage"
            :kpi-health-emergency-protection="kpiHealthEmergencyProtection"
            :kpi-healthier-populations="kpiHealthierPopulations"
            :points="points"
            class="right-ui"
        />
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <div class="row">
            <label>Points ({{ points }})</label>
            <input v-model.number="points" class="form-control" max="2500" min="0" type="range"/>
        </div>
        <div class="row">
            <label>KPI (health coverage) ({{ kpiHealthCoverage }})</label>
            <input v-model.number="kpiHealthCoverage" class="form-control" max="100" min="0" type="range"/>
        </div>
        <div class="row">
            <label>KPI (health emergency protection) ({{ kpiHealthEmergencyProtection }})</label>
            <input v-model.number="kpiHealthEmergencyProtection" class="form-control" max="100" min="0" type="range"/>
        </div>
        <div class="row">
            <label>KPI (healthier populations) ({{ kpiHealthierPopulations }})</label>
            <input v-model.number="kpiHealthierPopulations" class="form-control" max="100" min="0" type="range"/>
        </div>
    </dev-settings-panel>
</template>
<script>
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {DevComponentContainer, DevSettingsPanel} from "@seriousgamesinteractive/components-vue3/DevComponent";
import RightUi from "@/components/ui/RightUi/index.vue";

export default {
    name: 'RightUiDev',
    components: {
        BootstrapSelect,
        DevSettingsPanel,
        RightUi,
    },
    extends: DevComponentContainer,

    data() {
        return {
            points: 10,
            kpiHealthCoverage: 20,
            kpiHealthEmergencyProtection: 30,
            kpiHealthierPopulations: 40,
        }
    },
}
</script>

<style lang="scss" scoped>
.right-ui-container {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}
</style>
