<template>
    <h1>MarkdownHoverTextDev</h1>

    <div class="row">
        <div v-for="(markdownHoverText, i) of markdownHoverTexts" class="markdown-hover-container-dev mb-4">
            <strong>Hover text #{{ i + 1 }}</strong>

            <div class="markdown-hover-container-dev-render mb-2">
                <pre>{{ markdownHoverText }}</pre>
            </div>

            <div class="markdown-hover-container-dev-render">
                <markdown-hover-text :debug="false">{{ markdownHoverText }}</markdown-hover-text>
            </div>
        </div>
    </div>
</template>

<script>
import {DevComponentContainer, EventTicksComponentMixin} from "@seriousgamesinteractive/components-vue3/DevComponent";
import MarkdownHoverText from "./index.vue";

export default {
    name: 'MarkdownHoverTextDev',
    components: {MarkdownHoverText},
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],
    data() {
        return {
            markdownHoverTexts: [
                '~~0:some hover text~~ why don\'t you click it!',
                'Line 1\nLine 2\n\nLine 4\n\n\nLine 8',
                'My **bold** text and some ~underlined~ text',
                ' - List item 1\n - List item 2\n - List item 3\n - List item 4\n - List item 5',
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.row {
    text-align: left;
    width: 600px;
    margin: 0 auto;

    .markdown-hover-container-dev {
        background: rgba(255, 255, 255, .1);
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px;
    }

    .markdown-hover-container-dev-render {
        background: rgba(255, 255, 255, .1);
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px;
    }
}
</style>
