import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n} from '@/lib/i18n/i18n'
import ApiClientMixins from "@/plugins/ApiClientMixins";
import SoundPlayer from "@/plugins/SoundPlayer";
import Particles from "vue3-particles";
import * as Sentry from "@sentry/vue";
import ApiClient from "@seriousgamesinteractive/api-client-vue3";
import {SnackbarService, Vue3Snackbar} from 'vue3-snackbar';
import 'vue3-snackbar/styles';

const app = createApp(App);

if (process.env && parseInt(process.env.VUE_APP_ENABLE_SENTRY, 10) > 0) {
    console.log('Initializing Sentry due to ENABLE_SENTRY');

    Sentry.init({
        app,
        dsn: "https://546598259d5a9a40a0ae9d1033a005c1@o310165.ingest.sentry.io/4505962660495360",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/, /^.+\.dev\.seriousgames\.net\/.+/],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

app
    .use(store)
    .use(router)
    .use(i18n)
    .use(ApiClient, {
        mixins: ApiClientMixins,
        baseUrl: window['BASE_API_URL'] || process.env.VUE_APP_BASE_API_URL,
        useDebugConsole: true,
    })
    .use(Particles)
    .use(SoundPlayer)
    .use(SnackbarService)

app.component('vue3-snackbar', Vue3Snackbar);

app.mount('#app');
