<template>
    <div class="i18n-dev">
        <h1>$i18n</h1>

        <div class="i18n-menu">
            <div class="mb-4">
                <label>Language</label>
                <bootstrap-button-group
                    :values="messageLocales"
                    v-model="$i18n.locale"
                />
            </div>

            <div class="mb-4">
                <label>Message Category</label>
                <bootstrap-button-group
                    :values="messageCategories"
                    v-model="currentMessageCategory"
                />
            </div>

            <div class="mb-4">
                <label>Enable markdown transformations</label>
                <bootstrap-button-group
                    :values="['Yes', 'No']"
                    :model-value="enableMarkdownTransformations ? 'Yes' : 'No'"
                    @update:model-value="enableMarkdownTransformations = $event === 'Yes'"
                />
            </div>
        </div>

        <table class="table table-bordered table-striped" v-if="currentMessageCategory">
            <thead>
            <tr>
                <th v-if="showAll">Message Category</th>
                <th>Message ID</th>
                <th>Message</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="computedMessage in computedMessages">
                <td class="category" v-if="showAll">{{ computedMessage.category }}</td>
                <td class="message-id" @click="onClickMessageId(computedMessage)">{{ computedMessage.messageId }}</td>
                <td class="message" v-if="enableMarkdownTransformations">
                    <markdown-hover-text>{{ computedMessage.message }}</markdown-hover-text>
                </td>
                <td class="message" v-else>
                    <pre>{{ computedMessage.message }}</pre>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <div class="i18n-menu">
            <div class="mb-4">
                <label class="me-4">Language</label>
                <bootstrap-button-group
                    :values="messageLocales"
                    v-model="$i18n.locale"
                    :inactive-class="'btn-dark'"
                />
            </div>

            <div class="mb-4">
                <label class="me-4">Message Category</label>
                <bootstrap-button-group
                    :values="messageCategories"
                    v-model="currentMessageCategory"
                    :inactive-class="'btn-dark'"
                />
            </div>

            <div class="mb-4">
                <label class="me-4">Enable markdown transformations</label>
                <bootstrap-button-group
                    :values="['Yes', 'No']"
                    :model-value="enableMarkdownTransformations ? 'Yes' : 'No'"
                    @update:model-value="enableMarkdownTransformations = $event === 'Yes'"
                    :inactive-class="'btn-dark'"
                />
            </div>
        </div>
    </dev-settings-panel>
</template>

<script>
import {DevSettingsPanel} from "@seriousgamesinteractive/components-vue3/DevComponent";
import {BootstrapButtonGroup, BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";

export default {
    name: 'i18nDev',
    components: {
        BootstrapButtonGroup,
        MarkdownHoverText,
        BootstrapSelect,
        DevSettingsPanel,
    },
    data() {
        console.log('i18n', this.$i18n);

        return {
            currentMessageCategory: 'ALL',
            masterQuizId: 1,
            quizMessages: [],

            enableMarkdownTransformations: true,
            loading: false,
        }
    },
    methods: {
        onClickMessageId(message) {
            this.$store.commit('activeHoverText', message.message);
        },
        getMessage(message) {
            return message.replace('[MEMBER_STATE_TABLE]', this.$t('generic.countryListTable'));
        },
        loadQuiz() {
            this.quizMessages = [];

            const locale = this.$i18n.locale;

            if (locale === 'system') {
                return; // Do nothing else
            }

            this.loading = true;

            this.$apiClient.quiz.getLocalizedQuiz(this.masterQuizId, this.$i18n.locale, false).then((quiz) => {
                const quizMessages = {};

                quizMessages['title'] = quiz.title;
                quizMessages['endText'] = quiz.endText;

                for (let i = 0, iLen = quiz.questions.length; i < iLen; i++) {
                    const quizQuestion = quiz.questions[i];

                    const questionPrefix = `question.${i}`;

                    quizMessages[`${questionPrefix}.title`] = quizQuestion.title;
                    quizMessages[`${questionPrefix}.text`] = quizQuestion.text;
                    quizMessages[`${questionPrefix}.hint`] = quizQuestion.hint;
                    quizMessages[`${questionPrefix}.correctAnswerExplanation`] = quizQuestion.correctAnswerExplanation;

                    for (let j = 0, jLen = quizQuestion.referenceLinks.length; j < jLen; j++) {
                        quizMessages[`${questionPrefix}.referenceLink.${j}`] = quizQuestion.referenceLinks[j];
                    }

                    for (let j = 0, jLen = quizQuestion.answers.length; j < jLen; j++) {
                        quizMessages[`${questionPrefix}.answer.${j}`] = quizQuestion.answers[j].text;
                    }
                }

                this.quizMessages = quizMessages;

                this.loading = false;
            });
        }
    },
    mounted() {
        this.loadQuiz();
    },
    watch: {
        '$i18n.locale'() {
            this.loadQuiz();
        },
    },
    computed: {
        messageLocales() {
            return this.$i18n.availableLocales;
        },
        messageCategories() {
            return [
                'ALL',
                ...Object.keys(this.$i18n.messages[this.$i18n.locale])
            ];
        },
        showAll() {
            return this.currentMessageCategory === 'ALL';
        },
        computedMessages() {
            const allMessages = this.$i18n.messages[this.$i18n.locale];

            allMessages['db:Quiz'] = this.quizMessages;

            console.log('allMessages', allMessages);

            const computedMessages = [];

            if (this.showAll) {
                for (const category in allMessages) {
                    for (const messageId in allMessages[category]) {
                        computedMessages.push({
                            category,
                            messageId,
                            message: this.getMessage(allMessages[category][messageId]),
                        });
                    }
                }
            } else if (this.currentMessageCategory) {
                for (const messageId in allMessages[this.currentMessageCategory]) {
                    computedMessages.push({
                        category: this.currentMessageCategory,
                        messageId,
                        message: this.getMessage(allMessages[this.currentMessageCategory][messageId]),
                    });
                }
            }

            return computedMessages;
        },
    },
}
</script>

<style lang="scss" scoped>
table {
    background: white;
}

.category, .message-id, .message {
    text-align: left;
}
</style>

<style lang="scss">
.i18n-menu {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;

    label {
        display: block;
        font-weight: bold;
    }
}

.i18n-dev {
    .markdown-text {
        table {
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 0;

            thead {
                display: none !important;
            }

            tbody td {
                text-align: left;
                padding-right: 20px;
                font-size: 12pt;
            }
        }
    }
}
</style>
