<template>
    <div :class="computedClasses" class="amr-box">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'AmrBox',
    props: {
        shadowBottom: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        computedClasses() {
            const classes = [];

            if (this.shadowBottom) {
                classes.push(`amr-box-shadow-bottom`);
            }

            return classes;
        }
    }
}
</script>

<style lang="scss" scoped>
.amr-box {
    border-radius: 7.612px;
    border: 2.349px solid #6A2600;
    background: #00205C;
    box-shadow: 0px 11.74251px 4.697px 1.17425px rgba(0, 0, 0, 0.57) inset;
    position: relative;
    margin: 0 auto;

    &.amr-box-shadow-bottom {
        box-shadow: 0px 11.74251px 4.697px 1.17425px rgba(0, 0, 0, 0.57) inset, 0px 15px 4px 0px rgba(0, 0, 0, 0.50);
    }
}
</style>
