<template>
    <points-particles
        v-if="render"
        :amount="50"
        :destination="destination"
        :origin="origin"
        @particle:hit="onParticleHit"
        @animation:complete="onAnimationComplete"
    />

    <dev-settings-panel anchor="bottomLeft">
        <div class="row">
            <button class="btn btn-success" @click="reRender">Re-render</button>
        </div>
    </dev-settings-panel>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>

import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import HintHelpPopup from "@/components/ui/HintHelpPopup/index.vue";
import PreviousAnswersPopup from "@/components/ui/PreviousAnswersPopup/index.vue";
import PointsParticles from '@/components/particles/PointsParticles.vue';

export default {
    name: 'PointsParticlesDev',
    components: {
        PointsParticles,
        PreviousAnswersPopup,
        HintHelpPopup,
        BootstrapSelect,
        DevSettingsPanel,
        EventTicks,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        return {
            render: false,
            origin: {
                x: 100,
                y: 500,
            },
            destination: {
                x: 1800,
                y: 100,
            },
            amount: 10,

            events: ['particle:hit', 'animation:complete'],
        }
    },
    methods: {
        reRender() {
            this.render = false;

            this.$nextTick(() => {
                this.render = true;
            });
        },
        onParticleHit() {
            this.triggerEvent('particle:hit');
        },
        onAnimationComplete() {
            this.triggerEvent('animation:complete');
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
