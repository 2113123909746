export default {
    state: {
        aspectRatioScale: 1,
        aspectRatioScaleW: 1,
        aspectRatioScaleH: 1,
        aspectRatio: 1,
        aspectRatioWindow: 1,
    },
    mutations: {
        aspectRatioScale: (state, value) => {
            state.aspectRatioScale = value;
        },
        aspectRatioScaleW: (state, value) => {
            state.aspectRatioScaleW = value;
        },
        aspectRatioScaleH: (state, value) => {
            state.aspectRatioScaleH = value;
        },
        aspectRatio: (state, value) => {
            state.aspectRatio = value;
        },
        aspectRatioWindow: (state, value) => {
            state.aspectRatioWindow = value;
        },
    },
    getters: {
        aspectRatioScale(state) {
            return state.aspectRatioScale;
        },
        aspectRatioScaleW(state) {
            return state.aspectRatioScaleW;
        },
        aspectRatioScaleH(state) {
            return state.aspectRatioScaleH;
        },
        aspectRatio(state) {
            return state.aspectRatio;
        },
        aspectRatioWindow(state) {
            return state.aspectRatioWindow;
        },
    },
}
