<template>
    <div :class="'right-ui-question-' + (active ? 'active' : 'inactive')" class="right-ui-question">
        <div class="right-ui-question-backgrounds">
            <div class="right-ui-question-background right-ui-question-background-inactive">
                <img src="@/assets/ui/bg-question-number-inactive.png"/>
            </div>

            <div class="right-ui-question-background right-ui-question-background-active">
                <img src="@/assets/ui/bg-question-number-active.png"/>
            </div>
        </div>
        <label>{{ questionNumber }}</label>
    </div>
</template>
<script>
export default {
    name: 'RightUiQuestion',
    props: {
        questionNumber: {
            type: Number,
            required: true,
        },
        active: {
            type: Boolean,
            required: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.right-ui-question {
    position: relative;
    width: 177px;
    height: 55px;

    .right-ui-question-backgrounds,
    .right-ui-question-background {
        position: absolute;
        top: 0;
        right: 0;
    }

    .right-ui-question-background {
        opacity: 0;
    }

    .right-ui-question-background, label {
        transition: 200ms ease-in-out;
    }

    label {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 20pt;
        font-weight: bold;
    }

    &.right-ui-question-active {
        label {
            opacity: 1;
        }

        .right-ui-question-background-active {
            opacity: 1;
        }
    }

    &.right-ui-question-inactive {
        label {
            opacity: 0.3;
        }

        .right-ui-question-background-inactive {
            opacity: 1;
        }
    }
}
</style>
