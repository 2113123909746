<template>
    <div class="locale-select-dev">
        <div class="mb-4">
            <label class="me-4">Locale</label>
            <bootstrap-button-group
                :values="messageLocales"
                v-model="currentLocale"
                :inactive-class="'btn-dark'"
            />
        </div>
    </div>

</template>

<script>
import BootstrapButtonGroup from "@seriousgamesinteractive/components-vue3/bootstrap/BootstrapButtonGroup.vue";

export default {
    name: 'LocaleSelectDev',
    components: {
        BootstrapButtonGroup,
    },
    watch: {
        '$i18n.locale'() {
            if (this.currentLocale === this.$i18n.locale) {
                return;
            }

            this.currentLocale = this.$i18n.locale;

            this.$emit('locale-changed', this.currentLocale);
        },
        currentLocale() {
            if (this.currentLocale === this.$i18n.locale) {
                return;
            }

            this.$i18n.locale = this.currentLocale;

            this.$emit('locale-changed', this.currentLocale);
        }
    },
    data() {
        return {
            currentLocale: this.$i18n.locale,
        }
    },
    computed: {
        messageLocales() {
            return this.$i18n.availableLocales.filter(v => v !== 'system');
        },
    }
}
</script>
