<template>
    <div class="question-information-popup-dev-container">
        <lightbox :is-static="true">
            <question-information-popup
                v-if="question"
                :question="question"
                @click:continue="onClickContinue"
            />
        </lightbox>
    </div>

    <dev-settings-panel anchor="bottomLeft">
        <locale-select-dev />
        <quiz-select-dev @question-changed="question = $event" :show-question="true" />
    </dev-settings-panel>

    <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>

import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {
    DevComponentContainer,
    DevSettingsPanel,
    EventTicks,
    EventTicksComponentMixin
} from "@seriousgamesinteractive/components-vue3/DevComponent";
import QuestionInformationPopup from "@/components/ui/QuestionInformationPopup/index.vue";
import {getFakeQuiz} from "@/utils/generator";
import Lightbox from "@seriousgamesinteractive/components-vue3/common/Lightbox.vue";
import LocaleSelectDev from "@/components/dev/LocaleSelectDev.vue";
import QuizSelectDev from "@/components/dev/QuizSelectDev.vue";

export default {
    name: 'QuestionInformationPopupDev',
    components: {
        QuestionInformationPopup,
        BootstrapSelect,
        DevSettingsPanel,
        EventTicks,
        Lightbox,
        LocaleSelectDev,
        QuizSelectDev,
    },
    extends: DevComponentContainer,
    mixins: [EventTicksComponentMixin],

    data() {
        return {
            events: ['click:continue'],

            question: null,
        }
    },
    methods: {
        onClickContinue() {
            this.triggerEvent('click:continue')
        },
    },
}
</script>

<style lang="scss" scoped>
.question-information-popup-dev-container {
    margin-top: 50px;
}
</style>
