<template>
    <div class="admin-view">
        <amr-popup v-if="!loggedIn" class="admin-authentication-popup" height="xs">
            <template v-slot:content>
                <div class="admin-authentication">
                    <p>Please input the password</p>

                    <p><input v-model="adminCode" class="form-control" type="password" @keydown.enter="onClickLogIn"/>
                    </p>

                    <p v-if="loginError" class="login-error"><strong>Error: </strong> {{ loginError }}</p>
                </div>
            </template>

            <template v-slot:footer-buttons>
                <app-button-row class="button-container" size="xs">
                    <app-button @click="onClickLogIn">Log in</app-button>
                </app-button-row>
            </template>
        </amr-popup>

        <div v-if="loggedIn" class="quiz-stats-container">
            <h1>Quiz statistics</h1>
            <div v-if="!currentQuiz && loading" class="loading">
                Loading quiz {{ masterQuizId }} in language {{ quizLanguage }}..
            </div>
            <div v-else-if="currentQuiz" class="d-flex">
                <div class="section-left">
                    <left-ui-question v-for="n in 10" :question-number="10 - n + 1"
                                      :state="activeQuestionIndex === (10 - n) ? '' : 'active'" class="left-ui-question"
                                      @click="activeQuestionIndex = (10 - n)"/>
                </div>
                <div class="section-center flex-grow-1 d-flex flex-column align-items-stretch">
                    <amr-box class="question-header">
                        <h1>
                            <markdown-hover-text>{{ currentQuestion.title }}</markdown-hover-text>
                        </h1>
                    </amr-box>
                    <amr-box :shadow-bottom="false" class="question-body">
                        <div class="question-text">
                            <p>
                                <markdown-hover-text>{{ currentQuestion.text }}</markdown-hover-text>
                            </p>
                        </div>

                        <div v-if="currentQuestionAnswerStats" class="answers-chart-container">
                            <previous-answers-chart
                                :answer-count-a="currentQuestionAnswerStats[0] || 0"
                                :answer-count-b="currentQuestionAnswerStats[1] || 0"
                                :answer-count-c="currentQuestionAnswerStats[2] || 0"
                                :answer-count-d="currentQuestionAnswerStats[3] || 0"
                                :loading="loading"
                            />
                        </div>
                    </amr-box>
                </div>
                <div class="section-right">
                    <amr-box class="question-answers">
                        <div><strong>Answers:</strong></div>
                        <div class="question-answers">
                            <div v-for="n in 4" :class="['question-answer-' + (n - 1), 'question-answer-' + (currentQuestion.answers[n - 1].isCorrect ? 'correct' : 'incorrect')]"
                                 class="question-answer">
                                {{ currentQuestion.answers[n - 1].text }}
                            </div>
                        </div>
                        <div><strong>Number of answers:</strong></div>
                        <div class="question-total-answers">{{ loading ? 'LOADING...' : totalAnswerCount }}</div>
                    </amr-box>
                    <amr-box class="quiz-language">
                        <div><strong>Quiz language:</strong></div>
                        <bootstrap-select v-model="quizLanguage" :options="quizLanguageOptions"/>
                    </amr-box>
                    <amr-box class="quiz-time-range">
                        <div><strong>Time range:</strong></div>

                        <div>
                            <label class="all-time-checkbox"><input v-model="allTime" type="checkbox"/> Show data from
                                beginning to now</label>
                        </div>
                        <div v-if="!allTime">
                            <Datepicker
                                v-model="dateRange"
                                :disabled="allTime"
                                :enable-time-picker="false"
                                :preset-dates="datePresetRanges"
                                multi-calendars
                                multi-static
                                position="left"
                                range
                            />
                        </div>
                    </amr-box>
                    <div v-if="loading">Loading..</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LeftUiQuestion from "@/components/ui/LeftUi/LeftUiQuestion.vue";
import AmrBox from "@/components/ui/AmrBox.vue";
import PreviousAnswersChart from "@/components/ui/PreviousAnswersChart/index.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import {useRoute} from "vue-router";
import AmrPopup from "@/components/ui/AmrPopup.vue";
import AppButton from "@/components/common/AppButton.vue";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    formatISO,
    startOfDay,
    startOfISOWeek,
    startOfMonth,
    subDays,
    subMonths,
    subYears
} from 'date-fns';
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";


export default {
    name: 'StatView',
    components: {
        AppButtonRow,
        LifeLineButton,
        AppButton,
        AmrPopup, BootstrapSelect, MarkdownHoverText, LeftUiQuestion, AmrBox, PreviousAnswersChart, Datepicker
    },
    data() {
        return {
            loggedIn: false,
            adminCode: process.env.VUE_APP_STATS_PW_PREFILL || '',
            loginError: null,

            activeQuestionIndex: 0,
            quizLanguage: 'en',

            currentQuiz: null,
            currentQuizStats: null,
            masterQuizId: null,

            loading: true,

            lastLoadMasterQuizId: null,
            lastLoadMasterQuizLanguage: null,

            allTime: true,
            dateRange: null,
            datePresetRanges: null,
            timeRange: null,
        }
    },
    mounted() {
        this.masterQuizId = this.masterQuizIdUrlParam;
        this.quizLanguage = this.quizLanguageUrlParam;
        this.activeQuestionIndex = this.questionNumberUrlParam - 1;

        //this.loadQuiz();
        this.recalculateDateRange();
    },
    watch: {
        quizLanguageUrlParam() {
            if (!this.quizLanguageUrlParam) {
                return;
            }

            this.quizLanguage = this.quizLanguageUrlParam;
        },
        questionNumberUrlParam() {
            if (!this.questionNumberUrlParam) {
                return;
            }

            this.activeQuestionIndex = this.questionNumberUrlParam - 1;
        },
        masterQuizIdUrlParam() {
            if (!this.masterQuizIdUrlParam) {
                return;
            }

            this.masterQuizId = this.masterQuizIdUrlParam;
        },
        activeQuestionIndex() {
            const activeQuestionNumber = (this.activeQuestionIndex + 1);

            if (this.questionNumberUrlParam !== activeQuestionNumber) {
                this.$router.replace({
                    params: {
                        questionNumber: activeQuestionNumber,
                    }
                });
            }
        },
        quizLanguage() {
            this.$router.replace({
                params: {
                    quizLanguage: this.quizLanguage,
                }
            });

            this.loadQuiz();
        },
        masterQuizId() {
            this.loadQuiz();
        },
        timeRangeCalculated() {
            this.loadQuiz(true);
        },
        dateRange() {
            this.onDateChanged();
        },
    },
    methods: {
        onClickLogIn() {
            this.$apiClient.setDefaultHeader('X-Admin-Code', this.adminCode);

            this.$apiClient.admin.verifyLogin().then(() => {
                this.loggedIn = true;

                this.loadQuiz();
            }).catch((e) => {
                this.loginError = e;
            });
        },
        loadQuiz(force = false) {
            if (!this.loggedIn) {
                return;
            }

            if (!force && this.masterQuizId === this.lastLoadMasterQuizId && this.quizLanguage === this.lastLoadMasterQuizLanguage) {
                return; // Same as last time, don't do anything
            }

            this.loading = true;

            this.lastLoadMasterQuizId = this.masterQuizId;
            this.lastLoadMasterQuizLanguage = this.quizLanguage;

            if (this.currentQuestionAnswerStats) {
                for (let i = 0, len = this.currentQuestionAnswerStats.length; i < len; i++) {
                    this.currentQuestionAnswerStats[i] = 0;
                }

                this.totalAnswerCount = 0;
            }

            this.$apiClient.quiz.getLocalizedQuiz(this.masterQuizId, this.quizLanguage, false).then((quiz) => {
                this.currentQuiz = quiz;

                this.$apiClient.admin.getLocalizedQuizStats(this.masterQuizId, this.quizLanguage, this.timeRangeCalculated).then((quizStats) => {
                    this.currentQuizStats = quizStats;

                    this.loading = false;
                })
            });
        },
        recalculateDateRange() {
            const datePresetRanges = [
                {
                    label: 'Today',
                    value: [new Date(), new Date()]
                },
                {
                    label: 'This week',
                    value: [startOfISOWeek(new Date()), endOfISOWeek(new Date())]
                },
                {
                    label: 'This month',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())]
                },
                {
                    label: 'Last 7 days',
                    value: [subDays(new Date(), 7), new Date()],
                },
                {
                    label: 'Last 30 days',
                    value: [subDays(new Date(), 30), new Date()],
                },
                {
                    label: 'Last 3 months',
                    value: [subMonths(new Date(), 3), new Date()],
                },
                {
                    label: 'Last 6 months',
                    value: [subMonths(new Date(), 6), new Date()],
                },
                {
                    label: 'Last 1 year',
                    value: [subYears(new Date(), 1), new Date()],
                },
                {
                    label: 'Last 2 years',
                    value: [subYears(new Date(), 2), new Date()],
                },
                /*{
                  label: 'All time',
                  range: [subYears(new Date(), 100), new Date()],
                }*/
            ];

            for (let i = 0, len = datePresetRanges.length; i < len; i++) {
                if (datePresetRanges[i].value instanceof Array) {
                    // It's a range, take start of day and end of day
                    datePresetRanges[i].value[0] = startOfDay(datePresetRanges[i].value[0]);
                    datePresetRanges[i].value[1] = endOfDay(datePresetRanges[i].value[1]);
                }
            }

            let timeFrom = startOfDay(subDays(new Date(), 30));
            let timeTo = endOfDay(new Date());

            this.dateRange = [timeFrom, timeTo];
            this.datePresetRanges = datePresetRanges;

            this.timeRange = [formatISO(timeFrom), formatISO(timeTo)];
        },
        onDateChanged() {
            this.timeRange = [
                formatISO(startOfDay(this.dateRange[0])),
                formatISO(endOfDay(this.dateRange[1]))
            ];
            console.log('DATE RANGE!', this.timeRange);
        },
    },
    computed: {
        timeRangeCalculated() {
            if (this.allTime) {
                return 'allTime';
            } else {
                return this.timeRange;
            }
        },
        questionNumberUrlParam() {
            const route = useRoute();

            if (!route) {
                return;
            }

            const questionNumberParamValue = route.params.questionNumber ? route.params.questionNumber.toString() : null;

            return questionNumberParamValue ? parseInt(questionNumberParamValue, 10) : 1;
        },
        quizLanguageUrlParam() {
            const route = useRoute();

            if (!route) {
                return;
            }

            return route.params.quizLanguage || 'en';
        },
        masterQuizIdUrlParam() {
            const route = useRoute();

            if (!route) {
                return;
            }

            const masterQuizId = route.params.masterQuizId.toString();

            return masterQuizId ? parseInt(masterQuizId, 10) : null;
        },
        currentQuestion() {
            if (!this.currentQuiz) {
                return null;
            }

            return this.currentQuiz.questions[this.activeQuestionIndex];
        },
        currentQuestionAnswerStats() {
            if (!this.currentQuiz || !this.currentQuizStats) {
                return null;
            }

            return this.currentQuizStats[this.activeQuestionIndex];
        },
        totalAnswerCount() {
            let totalAnswers = 0;

            if (this.currentQuestionAnswerStats) {
                totalAnswers += this.currentQuestionAnswerStats[0];
                totalAnswers += this.currentQuestionAnswerStats[1];
                totalAnswers += this.currentQuestionAnswerStats[2];
                totalAnswers += this.currentQuestionAnswerStats[3];
            }

            return totalAnswers;
        },
        quizLanguageOptions() {
            const languageOptions = [
                {
                    value: 'all',
                    label: `All languages`,
                }
            ];

            for (const quizLanguage of this.currentQuiz.allLanguages) {
                languageOptions.push({
                    value: quizLanguage.languageCode,
                    label: `${quizLanguage.language} (${quizLanguage.languageCode})`,
                });
            }

            return languageOptions;
        },
    },
};
</script>

<style lang="scss" scoped>
.admin-authentication-popup {
    margin-top: 100px;
}

.admin-view {
    padding: 100px 100px 0 100px;

    h1 {
        font-weight: bold;
        font-size: 30pt;
        margin-bottom: 80px;
    }
}

.quiz-language, .quiz-time-range {
    margin-top: 20px;
}

.section-left {
    //background: rgba(255, 0, 0, .5);
    flex-basis: 200px;
    flex-grow: 0;
    width: 200px;
    max-width: 200px;
    min-width: 200px;

    .left-ui-question {
        margin-left: auto;
    }
}

.section-center {
    margin: 0 50px;
    //background: rgba(0, 255, 0, .5);
    flex-grow: 1;
    width: 1000px;
    max-width: 1000px;
    min-width: 1000px;
    height: 700px;
}

.section-right {
    //background: rgba(0, 0, 255, .5);
    flex-basis: 400px;
    flex-grow: 0;
    width: 400px;
    max-width: 400px;
    min-width: 400px;
}

.left-ui-question {
    margin-bottom: 10px;
    cursor: pointer;
}

.question-header, .question-body {
    width: 100%;
}

$questionHeaderHeight: 100px;
$questionHeaderMarginBottom: 20px;
$questionTextHeight: 150px;

.question-header {
    height: $questionHeaderHeight;
    margin-bottom: $questionHeaderMarginBottom;
    position: relative;

    h1 {
        width: 90%;
        font-weight: bold;
        font-size: 30pt;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.question-body {
    height: calc(100% - $questionHeaderHeight - $questionHeaderMarginBottom);

    .question-text {
        height: $questionTextHeight;
        overflow-y: scroll;
        position: relative;

        p {
            width: 90%;
            font-size: 18pt;
            text-align: left;
            font-weight: bold;
            padding: 20px;
        }
    }

    .answers-chart-container {
        height: calc(100% - $questionTextHeight);
    }
}

.quiz-language, .quiz-time-range {
    padding: 20px;
    text-align: left;
    font-size: 22pt;
}

.question-answers {
    padding: 20px;
    text-align: left;
    font-size: 22pt;

    .question-answers, .question-total-answers {
        font-size: 17pt;
    }

    .question-answer {
        padding: 2px 15px;
        border: 2px solid transparent;

        &-correct {
            background: rgba(128, 188, 0, 0.60);
            border: 2px solid #6A2600;
            border-radius: 10px;
        }

        &:before {
            width: 50px;
            margin-right: 15px;
            font-weight: bold;
            color: white;
        }

        &-0:before {
            content: "A";
        }

        &-1:before {
            content: "B";
        }

        &-2:before {
            content: "C";
        }

        &-3:before {
            content: "D";
        }
    }
}

.admin-authentication {
    padding-top: 50px;
    max-width: 400px;
    margin: 0 auto;
}

.all-time-checkbox {
    font-size: 12pt;
}
</style>
