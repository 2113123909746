<template>
    <amr-popup class="lifeline-confirmation-popup" height="xs" icon-position="center" width="sm">
        <template v-slot:icon>
            <life-line-button :identifier="lifeline" :is-interactable="false" class="lifeline-icon"/>
        </template>

        <template v-slot:content>
            <div class="confirmation-container">
                <strong>{{ confirmationMessage }}</strong>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <app-button-row class="button-container" size="xs">
                <app-button @click="onClickYes">{{ $t('generic.buttonYes') }}</app-button>
                <app-button @click="onClickNo">{{ $t('generic.buttonNo') }}</app-button>
            </app-button-row>
        </template>
    </amr-popup>
</template>

<script>
import AppButton from "@/components/common/AppButton.vue";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";

import LifelineIconHint from "@/assets/icons/icon-hint.png";
import LifelineIconFiftyFifty from "@/assets/icons/icon-50-50.png";
import LifelineIconStats from "@/assets/icons/icon-stats.png";
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";
import AmrPopup from "@/components/ui/AmrPopup.vue";
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";

export default {
    name: 'LifelineConfirmationPopup',
    components: {NoDragImage, AmrPopup, LifeLineButton, MarkdownHoverText, AppButtonRow, TimeLeft, AppButton},
    props: {
        lifeline: {
            type: String,
            required: true,
        },
    },
    emits: ['click:yes', 'click:no'],
    computed: {
        confirmationMessage() {
            return this.$t('game.lifelineConfirmation').replace('[LIFELINE]', this.lifelineName);
        },
        lifelineName() {
            if (this.lifeline === LifeLineIdentifier.Hint) {
                return this.$t('game.lifelineHintHelp');
            } else if (this.lifeline === LifeLineIdentifier.FiftyFifty) {
                return this.$t('game.lifelineFiftyFifty');
            } else if (this.lifeline === LifeLineIdentifier.Stats) {
                return this.$t('game.lifelineHowPreviousPlayersAnswered');
            }

            return 'Unknown';
        },
        lifelineIcon() {
            if (this.lifeline === LifeLineIdentifier.Hint) {
                return LifelineIconHint;
            } else if (this.lifeline === LifeLineIdentifier.FiftyFifty) {
                return LifelineIconFiftyFifty;
            } else if (this.lifeline === LifeLineIdentifier.Stats) {
                return LifelineIconStats;
            }

            return null;
        }
    },
    methods: {
        onClickYes() {
            this.$emit('click:yes');
        },
        onClickNo() {
            this.$emit('click:no');
        },
    },
}
</script>

<style lang="scss" scoped>
.lifeline-confirmation-popup {
    .confirmation-container {
        position: absolute;
        top: 50%;
        left: 50%;
        white-space: pre;
        font-size: 25pt;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        text-align: center;
    }

    .button-container {
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
}
</style>
