<template>
    <div :class="computedClass" class="app-button-row d-flex align-items-center justify-content-center">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'AppButtonRow',
    props: {
        size: {
            type: String,
            required: false,
            default: 'md',
        },
    },
    computed: {
        computedClass() {
            const classes = [];

            if (this.size) {
                classes.push(`app-button-row-size-${this.size}`);
            }

            return classes;
        }
    }
}
</script>

<style lang="scss">
$baseButtonWidth: 475px;
$baseButtonHeight: 117px;
$baseFontSize: 50px;

$glueHeight: 4px;
$glueWidth: 30px;

$scaleXs: 0.6;
$scaleSm: 0.8;
$scaleMd: 1.0;
$scaleLg: 1.2;

$glueTranslateY: -28px;

.app-button-row {
    &-size-xs .app-button {
        width: $baseButtonWidth * $scaleXs !important;
        height: $baseButtonHeight * $scaleXs !important;

        label {
            //font-size: $baseFontSize * $scaleXs;
            //line-height: $baseButtonHeight * $scaleXs !important;

            //top: -0px !important;
        }

        &:not(:first-child)::before {
            left: -($baseButtonWidth * $scaleXs) / 2;
            top: ($baseButtonHeight * $scaleXs) / 2;
            //transform: translateY(-26px);
        }
    }

    &-size-sm .app-button {
        width: $baseButtonWidth * $scaleSm !important;
        height: $baseButtonHeight * $scaleSm !important;

        label {
            //font-size: $baseFontSize * $scaleSm;

            //top: -4px !important;
        }

        &:not(:first-child)::before {
            left: -($baseButtonWidth * $scaleSm) / 2;
            top: ($baseButtonHeight * $scaleSm) / 2;
            //transform: translateY(-26px);
        }
    }

    &-size-md .app-button {
        width: $baseButtonWidth * $scaleMd !important;
        height: $baseButtonHeight * $scaleMd !important;

        label {
            //font-size: $baseFontSize * $scaleMd;

            //top: -9px !important;
        }

        &:not(:first-child)::before {
            left: -($baseButtonWidth * $scaleMd) / 2;
            top: ($baseButtonHeight * $scaleMd) / 2;
            //transform: translateY(-28px);
        }
    }

    &-size-lg .app-button {
        width: $baseButtonWidth * $scaleLg !important;
        height: $baseButtonHeight * $scaleLg !important;

        label {
            //font-size: $baseFontSize * $scaleLg;

            //top: -9px !important;
        }

        &:not(:first-child)::before {
            left: -($baseButtonWidth * $scaleLg) / 2;
            top: ($baseButtonHeight * $scaleLg) / 2;
            //transform: translateY(-30px);
        }
    }

    .app-button {
        position: relative;

        &:not(:first-child)::before {
            position: absolute;
            content: "";
            height: $glueHeight;
            width: $glueWidth;
            display: inline-block;
            //background: yellow;
            background: #926333;
            z-index: -1;

            left: calc(-50% + 2px);
        }
    }
}
</style>
