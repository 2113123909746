// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/buttons/btn-right.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".challenge-a-friend-popup .status-message-container[data-v-7bc2be29] {\n  transition: 200ms ease-in-out;\n  padding-top: 10px;\n  padding-bottom: 0;\n  margin: 0;\n  font-size: 16pt;\n  font-weight: bold;\n}\n.challenge-a-friend-popup .status-message-container-hide[data-v-7bc2be29] {\n  opacity: 0;\n}\n.challenge-a-friend-popup .status-message-container-show[data-v-7bc2be29] {\n  opacity: 1;\n}\n.challenge-a-friend-popup p[data-v-7bc2be29] {\n  font-size: 23pt;\n  padding-top: 30px;\n  padding-bottom: 15px;\n  margin: 0;\n}\n.challenge-a-friend-popup .input-group[data-v-7bc2be29] {\n  padding: 0 50px;\n}\n.btn-right[data-v-7bc2be29] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 120px;\n  height: 59px;\n  color: white;\n  position: relative;\n  cursor: pointer;\n  outline: none !important;\n}\n.btn-right[data-v-7bc2be29]:hover {\n  top: 1px;\n}\n.btn-right[data-v-7bc2be29]:active {\n  top: 2px;\n}\n.btn-right img[data-v-7bc2be29] {\n  max-width: 100%;\n  max-height: 100%;\n}\n.share-text[data-v-7bc2be29] {\n  padding: 0 20px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
