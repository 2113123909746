<template>
    <div :class="computedClass" class="app-container">
        <aspect-ratio-container :aspect-height="1080" :aspect-width="1920">
            <who-logo region="eu" class="background-logo background-logo-europeanRegion" />
            <who-logo region="de" class="background-logo background-logo-germany" />
            <who-logo region="ru" class="background-logo background-logo-russia" />

            <router-view/>

            <transition name="fade">
                <lightbox v-if="computedActiveHoverText"
                          background-style="linear-gradient(rgba(0, 157, 225, .7), rgba(0, 18, 29, .7))">
                    <amr-popup class="hover-text-popup" height="auto" width="auto">
                        <template v-slot:content>
                            <markdown-text>{{ computedActiveHoverText }}</markdown-text>
                        </template>

                        <template v-slot:footer-buttons>
                            <app-button-row class="button-container" size="xs">
                                <app-button @click="onHoverTextPopupClickOk">{{ $t('generic.buttonOk') }}</app-button>
                            </app-button-row>
                        </template>
                    </amr-popup>
                </lightbox>
            </transition>

            <vue3-snackbar :duration="4000" right top/>
        </aspect-ratio-container>

        <transition name="fade">
            <div v-if="showAspectRatioWarning" class="aspect-ratio-warn">
                <lightbox :background-opacity=".8" background="#000000">
                    <p>{{ $t('generic.pleaseRotatePhone') }}</p>
                    <no-drag-image src="@/assets/ui/rotate-phone.png"/>
                </lightbox>
            </div>
        </transition>
    </div>
</template>

<script>
import NoDragImage from "@seriousgamesinteractive/components-vue3/common/NoDragImage.vue";
import Lightbox from "@seriousgamesinteractive/components-vue3/common/Lightbox.vue";
import AspectRatioContainer from "@seriousgamesinteractive/components-vue3/common/AspectRatioContainer.vue";

import {mapState} from 'vuex'
import {AudioIdentifiers, AudioSounds} from "@/lib/audio";
import AmrPopup from "@/components/ui/AmrPopup.vue";
import AppButton from "@/components/common/AppButton.vue";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import MarkdownText from '@/components/common/MarkdownText/index.vue';
import WhoLogo from "@/components/ui/WhoLogo.vue";

export default {
    name: 'App',
    components: {
        WhoLogo,
        MarkdownText,
        AppButtonRow, LifeLineButton, AppButton,
        AmrPopup,
        NoDragImage,
        Lightbox,
        AspectRatioContainer,
    },
    data() {
        return {}
    },
    computed: {
        ...mapState(['background', 'language', 'languageLogo', 'hideLogos', 'aspectRatioWindow', 'activeHoverText']),
        computedClass() {
            return [
                `background-${this.background || 'none'}`,
                `language-${this.language || 'none'}`,
                `language-logo-${this.languageLogo || 'none'}`,
                this.hideLogos ? 'hide-logos' : '',
            ]
        },
        computedActiveHoverText() {
            if (!this.activeHoverText) {
                return '';
            }

            let hoverText = this.activeHoverText;

            hoverText = hoverText.replace('[MEMBER_STATE_TABLE]', this.$t('generic.countryListTable'));

            return hoverText;
        },
        showAspectRatioWarning() {
            return this.aspectRatioWindow < 1;
        },
    },
    methods: {
        changeLanguage(language) {
            this.$store.commit('language', language);
        },
        onHoverTextPopupClickOk() {
            this.$store.commit('activeHoverText', null);
        },
    },
    mounted() {
        console.log(`Client version: 0.0.1`)

        for (const audioIdentifier in AudioSounds) {
            this.$soundPlayer.loadSound(audioIdentifier, AudioSounds[audioIdentifier], audioIdentifier === AudioIdentifiers.BG_100_1000 ? 'music' : 'fx');
        }

        const enableFastMode = process.env.VUE_APP_ENABLE_FAST_MODE ? parseInt(process.env.VUE_APP_ENABLE_FAST_MODE, 10) > 0 : false;

        if (enableFastMode) {
            console.log('Fast mode enabled!');

            this.$store.commit('fastMode', true);
        }

        this.$apiClient.setErrorHandler((error) => {
            this.$snackbar.add({
                type: 'error',
                text: error,
            });
        });
    },
}
</script>

<style lang="scss">
// Matches assets/logos/eu.who.png
$logoEnWidth: 293px;
$logoEnHeight: 143px;

// Matches assets/logos/de.who.png
$logoDeWidth: 513px;
$logoDeHeight: 143px;

// Matches assets/logos/ru.who.png
$logoRuWidth: 428px;
$logoRuHeight: 143px;

html, body {
    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: hidden;
    background: black;
}

#app {
    color: white;
    font-family: "Source Sans 3";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    /*aspect-ratio: 16/9;
    max-width: 100%;
    max-height: 100%;*/
    width: 100%;
    height: 100%;
}

.app-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-size: cover;
    background-color: #ffffff;

    &.background-who-blue {
        background-image: url('~@/assets/backgrounds/who-blue.png');
    }

    &.language-logo-europeanRegion {
        .background-logo-europeanRegion {
            left: calc(50% - ($logoEnWidth / 2));
        }

		.background-logo-germany {
			top: -$logoDeHeight;
			opacity: 0;
		}

        .background-logo-russia {
            right: -$logoRuWidth;
            opacity: 0;
        }
    }

    &.language-logo-russia {
        .background-logo-europeanRegion {
            left: -$logoEnWidth;
            opacity: 0;
        }

		.background-logo-germany {
			top: -$logoDeHeight;
			opacity: 0;
		}

        .background-logo-russia {
            right: calc(50% - ($logoRuWidth / 2));
        }
    }

	&.language-logo-germany {
		.background-logo-europeanRegion {
			left: -$logoEnWidth;
			opacity: 0;
		}

		.background-logo-russia {
			right: -$logoRuWidth;
			opacity: 0;
		}
	}

    &.hide-logos {
        .background-logo {
            display: none !important;
        }
    }
}

.background-logo {
    position: fixed;
    top: 15px;
    transition: 800ms cubic-bezier(0.7, -0.3, 0.3, 1.2);

    //pointer-events: none;

    &.background-logo-europeanRegion {
        left: 15px;
    }

	&.background-logo-germany {
		left: calc(50% - ($logoDeWidth / 2));
	}

    &.background-logo-russia {
        right: 15px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.hover-text-popup {
    padding: 30px 30px 50px 30px;

    p {
        text-align: left;
    }
}

.lightbox {
    .lightbox-content-inner {
        h1, h2, h3, h4, h5, h6, p {
            text-align: left !important;
        }
    }
}
</style>

<style lang="scss">
.markdown-text {
    table {
        /*margin-left: 30px;
        margin-top: -80px;
        margin-bottom: 50px;*/

        thead {
            display: none !important;
        }

        tbody td {
            text-align: left;
            padding-right: 20px;
            font-size: 12pt;
        }
    }
}

.hover-text-popup {
    table {
        //margin-top: -30px;
        //margin-bottom: 0;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.logo {
    user-select: none;
}
</style>
