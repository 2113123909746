<template>
    <amr-popup-with-timeout :timeout-seconds="60" class="hint-help-popup" timer-width="md" @timeout="onTimeout">
        <template v-slot:icon>
            <life-line-button :identifier="LifeLineIdentifier.Hint" :is-interactable="false"/>
        </template>

        <template v-slot:content>
            <div class="help-container">
                <markdown-hover-text>{{ computedHelpText }}</markdown-hover-text>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <app-button-row class="button-container" size="xs">
                <app-button @click="onClickOk">{{ $t('generic.buttonOk') }}</app-button>
            </app-button-row>
        </template>
    </amr-popup-with-timeout>
</template>

<script>
import AppButton from "@/components/common/AppButton.vue";
import TimeLeft from "@/components/ui/TimeLeft/TimeLeft.vue";
import AppButtonRow from "@/components/common/AppButtonRow.vue";
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";
import AmrPopupWithTimeout from "@/components/ui/AmrPopupWithTimeout.vue";
import LifeLineButton from "@/components/ui/QuestionContainer/LifeLineButton.vue";
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";

export default {
    name: 'HintHelpPopup',
    components: {
        LifeLineButton,
        NoDragImage,
        AmrPopupWithTimeout,
        MarkdownHoverText,
        AppButtonRow,
        TimeLeft,
        AppButton
    },
    props: {
        helpText: {
            type: String,
            required: true,
        },
    },
    emits: ['click:ok', 'timeout'],
    computed: {
        LifeLineIdentifier() {
            return LifeLineIdentifier
        },
        computedHelpText() {
            return `**${this.$t('game.lifelineHintHelp')}**: ${this.helpText}`;
        }
    },
    methods: {
        onTimeout() {
            this.$emit('timeout');
        },
        onClickOk() {
            this.$emit('click:ok');
        },
    },
}
</script>

<style lang="scss" scoped>
.hint-help-popup {
    .help-container {
        font-size: 23pt;
        height: fit-content;
        width: 1000px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        text-align: left !important;
    }
}
</style>
