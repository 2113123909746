<template>
    <points-particles
        v-for="particleGroup of particleGroups"
        v-if="particleGroups.length > 0"
        :amount="particleGroup.amount"
        :destination-element="particleGroup.destinationElement"
        :destination-radius="particleGroup.destinationRadius"
        :origin-element="particleGroup.originElement"
        :total-value="particleGroup.totalValue"
        @particle:hit="onParticleHit(particleGroup, $event)"
        @animation:complete="onParticleAnimationComplete(particleGroup)"
    />

    <div :class="computedClasses" class="game-view pt-4">
        <div :class="loading ? 'status-message-container-show' : ''" class="d-flex status-message-container">
            <h1>{{ $t('generic.loading') }}</h1>
        </div>
        <div :class="(!loading && !started) ? 'status-message-container-show' : ''"
             class="d-flex status-message-container">
            <h1>Waiting to start..</h1>
        </div>
        <div v-if="!loading && started" class="d-flex main-container justify-content-between align-items-stretch">
            <left-ui
                :is-muted="isMuted"
                :question-number="currentQuestionIndex + 1"
                :question-states="questionStates"
                :seconds-elapsed="computedTotalSecondsElapsed"
                class="left-ui"
                @click:back="onClickBack"
                @mute:update="updateIsMuted"
                @activate:devPanel="onActivateDevPanel"
            />

            <transition name="fade">
                <end-popup
                    v-if="reachedEnd"
                    :body-text="quiz.endText"
                    :bonus-points="totalBonusPoints"
                    :correct-answers="correctAnswerCount"
                    :correct-answers-max="10"
                    :final-score="kpiPoints"
                    :final-score-max="kpiPointsMax"

                    @click:challenge-friend="openEndPopup('challengeFriend')"
                    @click:learn-more="openEndPopup('learnMore')"
                    @click:play-again="onClickPlayAgain"
                />
            </transition>

            <question-container-live
                v-if="!reachedEnd && currentQuestion"

                :answer-points="answerPoints"
                :answers="answers"
                :can-answer="canAnswer"
                :can-use-lifelines="canUseLifelines"
                :lifeline-used-fiftyfifty="lifelineUsedFiftyFifty"

                :lifeline-used-hint="lifelineUsedHint"
                :lifeline-used-stats="lifelineUsedStats"
                :question="currentQuestion.text"
                :seconds-left="computedQuestionSecondsLeft"
                :seconds-total="computedQuestionSecondsTotal"
                :show-answer1="showAnswer1"
                :show-answer2="showAnswer2"
                :show-answer3="showAnswer3"

                :show-answer4="showAnswer4"
                :show-correct-answer="showCorrectAnswer"
                :show-question="showQuestion"

                :time-points="timePoints"
                :title="currentQuestion.title"

                class="center-ui"
                @answer:pick="onAnswerPick"
                @answer:result="onAnswerResult"
                @lifeline:request="onLifelineRequest"

                @lifeline:use="onLifelineUse"
            />

            <right-ui
                :kpi-health-coverage="computedKpiHealthCoverage"
                :kpi-health-emergency-protection="computedKpiHealthEmergencyProtection"
                :kpi-healthier-populations="computedKpiHealthierPopulations"
                :points="computedPoints"

                class="right-ui"
            />

            <transition name="fade">
                <div v-show="showNextQuestionButton" class="show-next-button-container">
                    <button class="btn-next-question" @click="onClickNextQuestion">
                        {{ $t('generic.buttonNextQuestion') }}
                    </button>
                </div>
            </transition>

            <transition name="fade">
                <lightbox v-if="showPopup"
                          background-style="linear-gradient(rgba(0, 157, 225, .7), rgba(0, 18, 29, .7))">
                    <lifeline-confirmation-popup
                        v-if="!reachedEnd && lifelineRequestPopupIdentifier"
                        :lifeline="lifelineRequestPopupIdentifier"
                        class="popup-lifeline-confirmation"
                        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                        @click:no="onLifelineReject(lifelineRequestPopupIdentifier)"
                        @click:yes="onLifelineConfirm(lifelineRequestPopupIdentifier)"
                    />

                    <hint-help-popup
                        v-else-if="!reachedEnd && showHintPopup"
                        :help-text="currentQuestion.hint"
                        class="popup-hint-help"
                        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                        @timeout="onHideHintPopup"
                        @click:ok="onHideHintPopup"
                    />

                    <previous-answers-popup-live
                        v-else-if="!reachedEnd && showStatsPopup"
                        :answer-ids="answerIds"
                        :language="language"
                        :master-quiz-id="masterQuizId"
                        :question-number="currentQuestionIndex + 1"

                        class="popup-stats"
                        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"

                        @timeout="onHideStatsPopup"
                        @click:ok="onHideStatsPopup"
                    />

                    <question-information-popup
                        v-else-if="!reachedEnd && showInformationPopup"
                        :is-last-question="currentQuestionIndex === 9"
                        :question="currentQuestion"
                        @timeout="onHideInformationPopup"
                        @click:continue="onHideInformationPopup"
                    />

                    <challenge-friend-popup
                        v-else-if="showChallengeFriendPopup"
                        :master-quiz-id="masterQuizId"
                        :quiz-language="language"
                        @click:ok="showChallengeFriendPopup = false"
                    />

                    <learn-more-popup
                        v-else-if="showLearnMorePopup"
                        :quiz="quiz"
                        @click:ok="showLearnMorePopup = false"
                    />
                </lightbox>
            </transition>
        </div>
    </div>

    <div v-if="devPanelActivated" :class="'dev-panel-' + (devPanelOpen ? 'open' : 'closed')" class="dev-panel">
        <div class="dev-panel-sticker" @click="devPanelOpen = !devPanelOpen"/>

        <amr-popup>
            <template v-slot:content>
                <div class="dev-panel-content">
                    <h1>Dev panel</h1>

                    <div>
                        <app-button-row size="xs">
                            <app-button @click="devPanelPreviousQuestion">Previous</app-button>
                            <app-button @click="devPanelNextQuestion(true)">Next (correct)</app-button>
                            <app-button @click="devPanelNextQuestion(false)">Next (incorrect)</app-button>
                            <app-button @click="devPanelSkipQuestion(false, 2)">Skip 2</app-button>
                            <app-button @click="devPanelSkipToEnd">Skip to end</app-button>
                        </app-button-row>
                    </div>

                    <div>
                        <app-button-row size="xs">
                            <app-button @click="devPanelRandomKpis">Random KPIs</app-button>
                            <app-button @click="devPanelRandomPoints">Random Points</app-button>
                        </app-button-row>
                    </div>

                    <div>
                        <app-button-row size="xs">
                            <app-button @click="devPanelToggleFastMode">Fast mode ({{
                                    fastMode ? 'enabled' : 'disabled'
                                }})
                            </app-button>
                            <app-button @click="devPanelToggleCheatMode">Cheat mode
                                ({{ cheatMode ? 'enabled' : 'disabled' }})
                            </app-button>
                        </app-button-row>
                    </div>

                </div>
            </template>

            <template v-slot:footer-buttons>
                <app-button-row class="button-container" size="xs">
                    <app-button @click="devPanelOnClickClose">Close</app-button>
                </app-button-row>
            </template>
        </amr-popup>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import LeftUi from "@/components/ui/LeftUi/index.vue";
import QuestionContainerLive from "@/components/ui/QuestionContainer/QuestionContainerLive.vue";
import RightUi from "@/components/ui/RightUi/index.vue";
import {AudioIdentifiers} from "@/lib/audio";
import {clearTimeoutPromise, timeoutPromise} from "@seriousgamesinteractive/utils/promiseUtil";
import Lightbox from "@seriousgamesinteractive/components-vue3/common/Lightbox.vue";
import HintHelpPopup from "@/components/ui/HintHelpPopup/index.vue";
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";
import AppButton from "@/components/common/AppButton.vue";
import {useRoute} from "vue-router";
import LifelineConfirmationPopup from "@/components/ui/LifelineConfirmationPopup/index.vue";
import PreviousAnswersPopup from "@/components/ui/PreviousAnswersPopup/index.vue";
import PreviousAnswersPopupLive from "@/components/ui/PreviousAnswersPopup/PreviousAnswersPopupLive.vue";
import QuestionInformationPopup from "@/components/ui/QuestionInformationPopup/index.vue";
import PointsParticles from "@/components/particles/PointsParticles.vue";
import EndPopup from "@/components/ui/EndPopup/index.vue";
import AmrPopup from '@/components/ui/AmrPopup.vue';
import AppButtonRow from '@/components/common/AppButtonRow.vue';
import NoDragImage from "@seriousgamesinteractive/components-vue3/common/NoDragImage.vue";
import ChallengeFriendPopup from '@/components/ui/ChallengeFriendPopup/index.vue';
import {shuffle} from '@seriousgamesinteractive/utils/arrayUtil';
import LearnMorePopup from '@/components/ui/LearnMorePopup/index.vue';
import {KpiIdentifier} from "@/enum/KpiIdentifier";
import {pickRandom} from "@seriousgamesinteractive/utils/randomUtil";
import {TimerDurations} from '@/lib/timer-durations';

const ParticleGroupIdentifier = {
    Points: 'Points',
    TimePoints: 'TimePoints',
    KpiUniversalHealthCoverage: 'UniversalHealthCoverage',
    KpiHealthEmergenciesProtection: 'HealthEmergenciesProtection',
    KpiHealthierPopulations: 'HealthierPopulations',
};

export default {
    components: {
        LearnMorePopup,
        ChallengeFriendPopup,
        NoDragImage, AppButtonRow,
        AmrPopup,
        EndPopup,
        PointsParticles,
        QuestionInformationPopup,
        PreviousAnswersPopupLive,
        PreviousAnswersPopup,
        LifelineConfirmationPopup,
        AppButton,
        HintHelpPopup,
        Lightbox,
        RightUi,
        QuestionContainerLive,
        LeftUi,
    },
    data() {
        return {
            kpiHealthierPopulations: 0,
            kpiHealthEmergencyProtection: 0,
            kpiHealthCoverage: 0,
            kpiPoints: 0,

            totalBonusPoints: 0,

            isMuted: false,

            quiz: null,

            loading: true,
            started: false,

            mainClockTickTimeout: null,
            totalSecondsTaken: 0,

            secondsLeftTotal: null,
            quizClockTickTimeout: null,
            currentQuestionIndex: 0,
            questionSecondsTaken: 0,

            answers: [],
            showCorrectAnswer: false,

            showQuestion: false,
            showAnswer1: false,
            showAnswer2: false,
            showAnswer3: false,
            showAnswer4: false,

            canAnswer: false,
            canUseLifelines: false,

            showHintPopup: false,
            showStatsPopup: false,
            showInformationPopup: false,
            showChallengeFriendPopup: false,
            showLearnMorePopup: false,

            devPanelActivated: false,
            devPanelOpen: false,

            lifelineRequestPopupIdentifier: null,

            lifelineUsedHint: false,
            lifelineUsedFiftyFifty: false,
            lifelineUsedStats: false,

            answerLifelineUsedHint: false,
            answerLifelineUsedFiftyFifty: false,
            answerLifelineUsedStats: false,

            givenAnswers: [],

            answerPoints: 0,
            timePoints: 0,

            showNextQuestionButton: false,

            uiShow: false,

            particleGroups: [],
            particleGroupsExtra: {
                [ParticleGroupIdentifier.Points]: 0,
                [ParticleGroupIdentifier.TimePoints]: 0,
                [ParticleGroupIdentifier.KpiHealthierPopulations]: 0,
                [ParticleGroupIdentifier.KpiHealthEmergenciesProtection]: 0,
                [ParticleGroupIdentifier.KpiUniversalHealthCoverage]: 0,
            },
            particleGroupsToAdd: {
                [ParticleGroupIdentifier.Points]: 0,
                [ParticleGroupIdentifier.TimePoints]: 0,
                [ParticleGroupIdentifier.KpiHealthierPopulations]: 0,
                [ParticleGroupIdentifier.KpiHealthEmergenciesProtection]: 0,
                [ParticleGroupIdentifier.KpiUniversalHealthCoverage]: 0,
            },

            kpiPointsMax: null,

            forceContinueTimeout: null,

        };
    },
    watch: {
        currentQuestionIndex() {
            this.onNewQuestion();
        },
    },
    computed: {
        ...mapGetters(['language', 'questionStates', 'fastMode', 'cheatMode']),

        reachedEnd() {
            return this.currentQuestionIndex >= 10;
        },
        correctAnswerCount() {
            let correctAnswerCount = 0;

            for (const questionState of this.questionStates) {
                if (questionState === 'correct') {
                    correctAnswerCount += 1;
                }
            }

            return correctAnswerCount;
        },
        showPopup() {
            return !!this.lifelineRequestPopupIdentifier
                || this.showHintPopup
                || this.showStatsPopup
                || this.showInformationPopup
                || this.showChallengeFriendPopup
                || this.showLearnMorePopup;
        },
        masterQuizId() {
            const masterQuizId = useRoute().params.masterQuizId;

            return typeof (masterQuizId) !== 'number' ? parseInt(masterQuizId.toString(), 10) : masterQuizId;
        },
        currentQuestion() {
            return this.quiz.questions[this.currentQuestionIndex];
        },
        computedQuestionSecondsLeft() {
            if (!this.currentQuestion) {
                return 0;
            }

            return this.computedQuestionSecondsTotal - this.questionSecondsTaken
        },
        computedQuestionSecondsTotal() {
            if (!this.currentQuestion) {
                return 0;
            }

            return (this.fastMode ? 5 : this.currentQuestion.timeToComplete);
        },
        computedTotalSecondsElapsed() {
            return this.totalSecondsTaken;
        },
        computedClasses() {
            return [
                `game-view-ui-${this.uiShow ? 'show' : 'hide'}`,
            ];
        },
        computedKpiHealthierPopulations() {
            return this.kpiHealthierPopulations + Math.round(this.particleGroupsExtra[ParticleGroupIdentifier.KpiHealthierPopulations]);
        },
        computedKpiHealthEmergencyProtection() {
            return this.kpiHealthEmergencyProtection + Math.round(this.particleGroupsExtra[ParticleGroupIdentifier.KpiHealthEmergenciesProtection]);
        },
        computedKpiHealthCoverage() {
            return this.kpiHealthCoverage + Math.round(this.particleGroupsExtra[ParticleGroupIdentifier.KpiUniversalHealthCoverage]);
        },
        computedPoints() {
            return this.kpiPoints + Math.round(this.particleGroupsExtra[ParticleGroupIdentifier.Points]) + Math.round(this.particleGroupsExtra[ParticleGroupIdentifier.TimePoints]);
        },
        answerIds() {
            const answerIds = [];

            for (const answer of this.currentQuestion.answers) {
                answerIds.push(answer.id);
            }

            return answerIds;
        }
    },
    mounted() {
        if (!this.masterQuizId) {
            this.$router.push('/');

            return;
        }

        if (!this.language) {
            this.$router.push(`/quiz/${this.masterQuizId}`);

            return;
        }

        this.loadQuiz();
    },
    methods: {
        getParticleElementById(id) {
            const element = document.getElementById(id);

            if (!element) {
                console.error(`Could not find particle element id ${id}`);

                return;
            }

            return element;
        },
        triggerParticles(identifier, options) {
            if (options.originElementId) {
                options.originElement = this.getParticleElementById(options.originElementId);
            }

            if (options.destinationElementId) {
                options.destinationElement = this.getParticleElementById(options.destinationElementId);
            }

            if (!options.explosionScale) {
                options.explosionScale = 1;
            }

            const groupOptions = {
                identifier: identifier,
                uid: (Math.random() * 0x999999) + identifier,
                originElement: options.originElement,
                destinationElement: options.destinationElement,
                destinationRadius: options.destinationRadius,
                amount: options.amount,
                totalValue: options.totalValue || options.amount,
                explosionScale: options.explosionScale,

                initOptions: options,
            };

            console.log('groupOptions', groupOptions);

            this.particleGroups.push(groupOptions);
        },

        devPanelOnClickClose() {
            this.devPanelOpen = false;
        },
        onActivateDevPanel() {
            this.$snackbar.add({
                type: 'success',
                text: 'Dev panel has been activated',
            });

            this.devPanelActivated = true;
        },
        onHideHintPopup() {
            this.showHintPopup = false;

            this.startQuestionClockTickTimeout();
        },
        onHideStatsPopup() {
            this.showStatsPopup = false;

            this.startQuestionClockTickTimeout();
        },
        onHideInformationPopup() {
            this.showInformationPopup = false;

            this.nextQuestion();
        },
        onClickBack() {
            this.$router.push(`/quiz/${this.masterQuizId}`);
        },
        onAnswerPick({answer}) {
            console.log('onPickAnswer', answer);

            this.canUseLifelines = false;

            this.clearQuestionClockTickTimeout();

            this.$soundPlayer.stopSoundGroup('music');
            this.$soundPlayer.playSoundOnce(AudioIdentifiers.FINAL_ANSWER, 'result');
        },
        onParticleHit(particleGroup, particle) {
            this.particleGroupsExtra[particleGroup.identifier] += particle.value;
        },
        moveParticleGroupsToAddAll() {
            console.log('this.particleGroupsToAdd', this.particleGroupsToAdd);

            for (const particleGroupIdentifier in this.particleGroupsToAdd) {
                this.moveParticleGroupsToAdd(particleGroupIdentifier);
            }
        },
        moveParticleGroupsToAdd(particleGroupIdentifier) {
            console.log('this.particleGroupsToAdd[particleGroupIdentifier]', this.particleGroupsToAdd[particleGroupIdentifier]);
            if (this.particleGroupsToAdd[particleGroupIdentifier] > 0) {
                const extraAmount = this.particleGroupsToAdd[particleGroupIdentifier];

                switch (particleGroupIdentifier) {
                    case ParticleGroupIdentifier.Points:
                    case ParticleGroupIdentifier.TimePoints:
                        this.kpiPoints += extraAmount;

                        break;
                    case ParticleGroupIdentifier.KpiUniversalHealthCoverage:
                        this.kpiHealthCoverage += extraAmount;

                        break;
                    case ParticleGroupIdentifier.KpiHealthEmergenciesProtection:
                        this.kpiHealthEmergencyProtection += extraAmount;

                        break;
                    case ParticleGroupIdentifier.KpiHealthierPopulations:
                        this.kpiHealthierPopulations += extraAmount;

                        break;
                }

                this.particleGroupsToAdd[particleGroupIdentifier] = 0;
                this.particleGroupsExtra[particleGroupIdentifier] = 0;
            }
        },
        onParticleAnimationComplete(particleGroup) {
            // If the particle group is completed, let's remove it from the particle groups
            for (let i = this.particleGroups.length - 1; i >= 0; i--) {
                if (this.particleGroups[i].identifier === particleGroup.identifier) {
                    console.log('Animation complete', particleGroup);

                    if (particleGroup.destinationElement) {
                        particleGroup.destinationElement.style.transition = '500ms ease-in-out';
                        particleGroup.destinationElement.style.transform = '';
                    }

                    //this.particleGroups.splice(i, 1);
                }
            }

            this.moveParticleGroupsToAdd(particleGroup.identifier);

            this.$nextTick(() => {
                for (let i = this.particleGroups.length - 1; i >= 0; i--) {
                    if (this.particleGroups[i].identifier === particleGroup.identifier) {
                        this.particleGroups.splice(i, 1);
                    }
                }

                if (particleGroup.identifier === ParticleGroupIdentifier.Points) {
                    this.forceShowInformationPopup();
                }
            });
        },
        forceShowInformationPopup() {
            if (this.showInformationPopup) {
                return;
            }

            if (this.forceContinueTimeout) {
                clearTimeout(this.forceContinueTimeout);

                this.forceContinueTimeout = null;
            }

            this.moveParticleGroupsToAddAll();

            this.particleGroups = [];

            this.showInformationPopup = true;
        },
        onAnswerResult({isCorrect, answer, answerIndex}) {
            this.clearQuestionClockTickTimeout();

            this.$soundPlayer.stopSoundGroup('music');

            this.$store.commit('setQuestionState', {
                questionIndex: this.currentQuestionIndex,
                questionState: isCorrect ? 'correct' : 'incorrect',
            });

            if (isCorrect) {
                this.$soundPlayer.playSoundOnce(AudioIdentifiers.CORRECT_ANSWER, 'result');

                this.answerPoints = this.currentQuestion.points;
                this.timePoints = this.computedQuestionSecondsLeft;

                // We do this in the animation handler
            } else {
                this.$soundPlayer.playSoundOnce(AudioIdentifiers.WRONG_ANSWER, 'result');

                this.answerPoints = 0;
                this.timePoints = 0;

                this.showCorrectAnswer = true;
            }

            this.setGivenAnswer({
                questionId: this.currentQuestion.id,
                answerId: answer ? answer.id : null,
                lifelinesUsed: {
                    hint: this.answerLifelineUsedHint,
                    fiftyFifty: this.answerLifelineUsedFiftyFifty,
                    stats: this.answerLifelineUsedStats,
                },
                timePoints: this.timePoints,
                devGenerated: false,
            });

            this.particleGroupsToAdd[ParticleGroupIdentifier.Points] = 0;
            this.particleGroupsToAdd[ParticleGroupIdentifier.TimePoints] = 0;
            this.particleGroupsToAdd[ParticleGroupIdentifier.KpiHealthEmergenciesProtection] = 0;
            this.particleGroupsToAdd[ParticleGroupIdentifier.KpiUniversalHealthCoverage] = 0;
            this.particleGroupsToAdd[ParticleGroupIdentifier.KpiHealthierPopulations] = 0;

            if (!isCorrect && this.currentQuestion.correctAnswerExplanation) {
                // Incorrect answer but there's an explanation popup to show
                this.timeoutPromise(TimerDurations.beforeExplanationPopup).then(() => {
                    this.showInformationPopup = true;
                });
            } else if (isCorrect) {
                // Correct answer
                const answerButtonId = 'answer-button-' + (answerIndex + 1);

                this.totalBonusPoints += this.computedQuestionSecondsLeft;

                this.particleGroupsToAdd[ParticleGroupIdentifier.Points] = this.answerPoints;
                this.particleGroupsToAdd[ParticleGroupIdentifier.TimePoints] = this.timePoints;
                this.particleGroupsToAdd[ParticleGroupIdentifier.KpiHealthEmergenciesProtection] = this.currentQuestion.kpi.healthEmergencyProtection;
                this.particleGroupsToAdd[ParticleGroupIdentifier.KpiUniversalHealthCoverage] = this.currentQuestion.kpi.healthCoverage;
                this.particleGroupsToAdd[ParticleGroupIdentifier.KpiHealthierPopulations] = this.currentQuestion.kpi.healthierPopulations;

                //const totalPoints = this.currentQuestion.points + this.computedQuestionSecondsLeft;

                requestAnimationFrame(() => {
                    if (this.answerPoints > 0) {
                        this.triggerParticles(ParticleGroupIdentifier.Points, {
                            originElementId: 'tooltip-answer-points',
                            destinationElementId: 'points-ui-ball',

                            destinationRadius: 200,

                            amount: this.getParticleAmountFromTotal(this.answerPoints),
                            totalValue: this.answerPoints,
                        });
                    }

                    if (this.timePoints > 0) {
                        this.triggerParticles(ParticleGroupIdentifier.TimePoints, {
                            originElementId: 'tooltip-time-points',
                            destinationElementId: 'points-ui-ball',

                            destinationRadius: 200,

                            amount: this.getParticleAmountFromTotal(this.timePoints),
                            totalValue: this.timePoints,
                        });
                    }

                    if (this.currentQuestion.kpi.healthEmergencyProtection) {
                        this.triggerParticles(ParticleGroupIdentifier.KpiHealthEmergenciesProtection, {
                            originElementId: answerButtonId,
                            destinationElementId: 'kpi-health-emergency-protection',

                            destinationRadius: 100,
                            explosionScale: 0.2,

                            amount: this.getParticleAmountFromTotal(this.currentQuestion.kpi.healthEmergencyProtection),
                            totalValue: this.currentQuestion.kpi.healthEmergencyProtection,
                        });
                    }

                    if (this.currentQuestion.kpi.healthCoverage) {
                        this.triggerParticles(ParticleGroupIdentifier.KpiUniversalHealthCoverage, {
                            originElementId: answerButtonId,
                            destinationElementId: 'kpi-health-coverage',

                            destinationRadius: 100,
                            explosionScale: 0.2,

                            amount: this.getParticleAmountFromTotal(this.currentQuestion.kpi.healthCoverage),
                            totalValue: this.currentQuestion.kpi.healthCoverage,
                        });
                    }

                    if (this.currentQuestion.kpi.healthierPopulations) {
                        this.triggerParticles(ParticleGroupIdentifier.KpiHealthierPopulations, {
                            originElementId: answerButtonId,
                            destinationElementId: 'kpi-healthier-populations',

                            destinationRadius: 100,
                            explosionScale: 0.2,

                            amount: this.getParticleAmountFromTotal(this.currentQuestion.kpi.healthierPopulations),
                            totalValue: this.currentQuestion.kpi.healthierPopulations,
                        });
                    }
                });

				this.timeoutPromise(TimerDurations.afterAnswering).then(() => {
					this.forceShowInformationPopup();
				});

                /*if (this.forceContinueTimeout) {

                    clearTimeout(this.forceContinueTimeout);

                    this.forceContinueTimeout = null;
                }

                this.forceContinueTimeout = setTimeout(() => {
                }, 5000);*/
            } else {
                // Incorrect answer but no explanation popup
                this.timeoutPromise(TimerDurations.afterAnswering).then(() => {
                    this.nextQuestion();
                });
            }
        },
        getParticleAmountFromTotal(total) {
            return Math.round(Math.min(100, Math.max(1, total / 3)));
        },
        outOfTime() {
            this.onAnswerResult({
                isCorrect: false,
                answer: null,
            });
        },
        onClickNextQuestion() {
            this.nextQuestion();
        },
        onLifelineUse(lifeline) {
            if (lifeline === LifeLineIdentifier.Hint) {
                this.showHintPopup = true;
            } else if (lifeline === LifeLineIdentifier.Stats) {
                this.showStatsPopup = true;
            }
        },
        onLifelineReject(lifeline) {
            this.startQuestionClockTickTimeout();

            this.lifelineRequestPopupIdentifier = null;
        },
        onLifelineConfirm(lifeline) {
            if (lifeline === LifeLineIdentifier.Hint) {
                this.lifelineUsedHint = true;
                this.answerLifelineUsedHint = true;
            } else if (lifeline === LifeLineIdentifier.FiftyFifty) {
                this.lifelineUsedFiftyFifty = true;
                this.answerLifelineUsedFiftyFifty = true;

                this.startQuestionClockTickTimeout();
            } else if (lifeline === LifeLineIdentifier.Stats) {
                this.lifelineUsedStats = true;
                this.answerLifelineUsedStats = true;
            }

            this.lifelineRequestPopupIdentifier = null;
        },
        onLifelineRequest(lifeline) {
            this.clearQuestionClockTickTimeout();

            this.lifelineRequestPopupIdentifier = lifeline;
        },
        updateIsMuted(isMuted) {
            this.isMuted = isMuted;

            if (this.isMuted) {
                this.$soundPlayer.mute();
            } else {
                this.$soundPlayer.unmute();
            }
        },
        loadQuiz() {
            this.$store.commit('resetQuestionStates');

            this.uiShow = false;
            this.loading = true;

            this.$apiClient.quiz.getLocalizedQuiz(this.masterQuizId, this.language).then((quiz) => {
                let secondsLeftTotal = 0;

                console.log('quiz', quiz);

                let maxPoints = 0;

                for (const question of quiz.questions) {
                    secondsLeftTotal += question.timeToComplete;

                    maxPoints += question.points + question.timeToComplete;
                }

                this.kpiPointsMax = maxPoints;

                this.secondsLeftTotal = secondsLeftTotal;

                this.quiz = quiz;

                this.loading = false;
                this.uiShow = false;
                this.started = true;

                return this.timeoutPromise(500);
            }).then(() => {
                this.uiShow = true;

                return this.timeoutPromise(500);
            }).then(() => {
                this.startQuiz();
            })
        },
        startQuiz() {
            this.startMainClockTickTimeout();

            this.started = true;
            this.currentQuestionIndex = 0;

            this.totalSecondsTaken = 0;

            this.nextQuestion(false);
        },
        nextQuestion(incrementQuestionIndex = true) {
            this.moveParticleGroupsToAddAll();

            this.showQuestion = false;
            this.canAnswer = false;
            this.showNextQuestionButton = false;

            this.timeoutPromise(incrementQuestionIndex ? TimerDurations.nextQuestion : 500).then(() => {
                if (incrementQuestionIndex) {
                    this.currentQuestionIndex += 1;
                } else {
                    this.currentQuestionIndex = 0;
                }

                this.onNewQuestion();
            });
        },
        onReachEnd() {
            this.moveParticleGroupsToAddAll();

            this.showEndPopup = true;

            this.clearQuestionClockTickTimeout();
            this.clearMainClockTickTimeout();

            if (this.quizResultSubmitting || this.quizResultSubmitted) {
                return; // Already submitted
            }

            const resultData = {
                finalScore: this.kpiPoints,
                kpiPoints: {
                    [KpiIdentifier.HealthCoverage]: this.kpiHealthCoverage,
                    [KpiIdentifier.HealthEmergencyProtection]: this.kpiHealthEmergencyProtection,
                    [KpiIdentifier.HealthierPopulations]: this.kpiHealthierPopulations,
                },
                secondsTaken: this.totalSecondsTaken,
                givenAnswers: this.givenAnswers,
            };

            console.log('RESULT data', resultData);

            this.quizResultSubmitting = true;

            this.$apiClient.ignoreErrorHandler = true;

            this.$apiClient.quiz.submitQuizResult(this.masterQuizId, this.language, resultData).then(() => {
                this.quizResultSubmitting = false;
                this.quizResultSubmitted = true;

                console.log('submitted quiz result');

            }).catch((error) => {
                this.$snackbar.add({
                    type: 'error',
                    text: `Could not submit highscore: ${error}`,
                });
            }).finally(() => {
                this.$apiClient.ignoreErrorHandler = true;
            });
        },
        setGivenAnswer(givenAnswer) {
            for (const givenAnswerSingle of this.givenAnswers) {
                if (givenAnswerSingle.questionId === givenAnswer.questionId) {
                    if (givenAnswerSingle.answerId === null && givenAnswer.answerId !== null) {
                        // Overwrite
                        console.log(`Found existing given answer for question with id ${givenAnswer.questionId} but it was NULL so overwriting it`, givenAnswerSingle, givenAnswer);

                        for (const key in givenAnswer) {
                            givenAnswerSingle[key] = givenAnswer[key];
                        }
                    } else {
                        console.log(`Could not set given answer for question with id ${givenAnswer.questionId} as it already exists and not overwriting it`, givenAnswerSingle, givenAnswer);
                    }

                    return;
                }
            }

            console.log(`Pushed given answer`, givenAnswer);

            this.givenAnswers.push(givenAnswer)
        },
        onNewQuestion() {
            if (!this.currentQuestion) {
                this.onReachEnd();

                return;
            }

            // Verify we got results for old questions - sometimes they don't detect right
            for (let i = 0; i < this.currentQuestionIndex; i++) {
                if (this.questionStates[i] !== 'correct' && this.questionStates[i] !== 'incorrect') {
                    // The answer to a previous question was not answered (should be "correct" or "incorrect")
                    // but it should have SOME response
                    // let's set it to incorrect
                    this.$store.commit('setQuestionState', {
                        questionIndex: i,
                        questionState: 'incorrect', // Default to incorrect
                    });
                }
            }

            const givenAnswerCountMinusOne = this.givenAnswers.length - 1;

            if (givenAnswerCountMinusOne >= 0 && givenAnswerCountMinusOne < this.currentQuestionIndex) {
                for (let i = givenAnswerCountMinusOne; i < this.currentQuestionIndex; i++) {
                    if (i < 0 || i >= 10) {
                        continue;
                    }

                    console.log(`Pushing missing givenAnswer with index ${i}`);

                    this.setGivenAnswer({
                        questionId: this.currentQuestion.id,
                        answerId: null,
                        lifelinesUsed: {
                            hint: this.answerLifelineUsedHint,
                            fiftyFifty: this.answerLifelineUsedFiftyFifty,
                            stats: this.answerLifelineUsedStats,
                        },
                        timePoints: 0,
                        devGenerated: false,
                    });
                }
            }

            this.$soundPlayer.stopSoundGroup('result');

            this.questionSecondsTaken = 0;

            const answers = [];


            for (let i = 0, len = this.currentQuestion.answers.length; i < len; i++) {
                const answer = this.currentQuestion.answers[i];

                answers.push(answer);
            }

            this.answerLifelineUsedHint = false;
            this.answerLifelineUsedFiftyFifty = false;
            this.answerLifelineUsedStats = false;

            this.answers = answers;
            this.showCorrectAnswer = false;

            this.questionSecondsTaken = 0;
            this.showAnswer1 = false;
            this.showAnswer2 = false;
            this.showAnswer3 = false;
            this.showAnswer4 = false;
            this.canAnswer = false;
            this.showHintPopup = false;
            this.showNextQuestionButton = false;
            this.canUseLifelines = false;

            this.answerPoints = 0;
            this.timePoints = 0;

            console.log(`STARTING AT 0 UP TO ${this.currentQuestionIndex}`);

            this.$store.commit('setQuestionState', {
                questionIndex: this.currentQuestionIndex,
                questionState: 'active',
            });

			const answerDisplayInitialTimeout = parseInt(process.env.VUE_APP_BEFORE_ANSWER_DISPLAY_TIMEOUT, 10);
			const answerDisplayTimeout = parseInt(process.env.VUE_APP_ANSWER_DISPLAY_TIMEOUT, 10);

            this.timeoutPromise(50)
                .then(() => {
                    this.showQuestion = true;

                    return this.timeoutPromise(answerDisplayInitialTimeout);
                })
                .then(() => {
                    this.showAnswer1 = true;

                    return this.timeoutPromise(answerDisplayTimeout);
                })
                .then(() => {
                    this.showAnswer2 = true;

                    return this.timeoutPromise(answerDisplayTimeout);
                })
                .then(() => {
                    this.showAnswer3 = true;

                    return this.timeoutPromise(answerDisplayTimeout);
                })
                .then(() => {
                    this.showAnswer4 = true;

                    return this.timeoutPromise(answerDisplayTimeout);
                })
                .then(() => {
                    this.startAnswering();
                });
            //
        },
        startAnswering() {
            let bgSoundIdenfitier = AudioIdentifiers.BG_100_1000;

            this.$soundPlayer.stopSoundGroup('music');
            this.$soundPlayer.playSound(bgSoundIdenfitier, 'music');

            this.startQuestionClockTickTimeout();

            this.canAnswer = true;
            this.canUseLifelines = true;
        },
        startQuestionClockTickTimeout() {
            this.clearQuestionClockTickTimeout();

            this.questionClockTickTimeout = setTimeout(this.questionClockTick, 1000);
        },
        clearQuestionClockTickTimeout() {
            if (this.questionClockTickTimeout) {
                clearTimeout(this.questionClockTickTimeout);

                this.questionClockTickTimeout = null;

                //console.log('CLEAR CLOCK TIME OUT');
            }
        },
        questionClockTick() {
            this.clearQuestionClockTickTimeout();

            this.questionSecondsTaken += 1;

            if (this.computedQuestionSecondsLeft < 0) {
                // OUT OF TIME!
                this.outOfTime();
            } else {
                this.questionClockTickTimeout = setTimeout(this.questionClockTick, 1000);
            }
        },
        startMainClockTickTimeout() {
            this.clearMainClockTickTimeout();

            this.mainClockTickTimeout = setTimeout(this.mainClockTick, 1000);
        },
        clearMainClockTickTimeout() {
            if (this.mainClockTickTimeout) {
                clearTimeout(this.mainClockTickTimeout);

                this.mainClockTickTimeout = null;

                //console.log('CLEAR CLOCK TIME OUT');
            }
        },
        mainClockTick() {
            this.clearMainClockTickTimeout();

            this.totalSecondsTaken += 1;
            this.mainClockTickTimeout = setTimeout(this.mainClockTick, 1000);
        },
        timeoutPromise(timeout) {
            if (this.fastMode) {
                console.log(`Not waiting ${timeout} since fast mode is ON!`);
            }

            return timeoutPromise(this.fastMode ? 1 : timeout);
        },

        openEndPopup(popupIdentifier) {
            this.showChallengeFriendPopup = (popupIdentifier === 'challengeFriend');
            this.showLearnMorePopup = (popupIdentifier === 'learnMore');
        },

        closeAllPopups() {
            this.showLearnMorePopup = false;
            this.showChallengeFriendPopup = false;
            this.showHintPopup = false;
            this.showStatsPopup = false;
            this.showInformationPopup = false;
        },
        onClickPlayAgain() {
            this.clearMainClockTickTimeout();
            this.clearQuestionClockTickTimeout();

            clearTimeoutPromise();

            this.$soundPlayer.stopSoundGroup('music');

            this.kpiPoints = 0;
            this.kpiHealthCoverage = 0;
            this.kpiHealthEmergencyProtection = 0;
            this.kpiHealthierPopulations = 0;
            this.totalBonusPoints = 0;

            for (const key in this.particleGroupsExtra) {
                this.particleGroupsExtra[key] = 0;
            }

            this.givenAnswers = [];

            this.timePoints = 0;
            this.answerPoints = 0;
            this.kpiPointsToAdd = 0;

            this.closeAllPopups();

            this.answerLifelineUsedHint = false;
            this.answerLifelineUsedFiftyFifty = false;
            this.answerLifelineUsedStats = false;

            this.lifelineRequestPopupIdentifier = null;
            this.lifelineUsedHint = false;
            this.lifelineUsedFiftyFifty = false;
            this.lifelineUsedStats = false;

            this.showAnswer1 = false;
            this.showAnswer2 = false;
            this.showAnswer3 = false;
            this.showAnswer4 = false;

            this.secondsLeftTotal = false;
            this.questionSecondsTaken = false;

            this.canAnswer = false;
            this.canUseLifelines = false;
            this.showNextQuestionButton = false;

            this.totalSecondsTaken = 0;

            this.quizResultSubmitting = false;
            this.quizResultSubmitted = false;

            for (let i = 0; i < 10; i++) {
                this.$store.commit('setQuestionState', {
                    questionIndex: i,
                    questionState: 'inactive',
                });
            }

            // Shuffle all answers
            for (const question of this.quiz.questions) {
                shuffle(question.answers);
            }

            this.reachedEnd = false;

            this.timeoutPromise(1000).then(() => {
                this.currentQuestionIndex = 0;

                this.startMainClockTickTimeout();
            });
        },

        // Dev panel methods:
        devGenerateGivenAnswerFromQuestionIndex(questionIndex, isCorrect = null) {
            const question = this.quiz.questions[questionIndex];
            const answers = question.answers;

            const filteredAnswers = [];

            if (isCorrect !== null) {
                // Only answers matching either correct or incorrect
                for (const answer of answers) {
                    if (answer.isCorrect === isCorrect) {
                        filteredAnswers.push(answer);
                    }
                }
            } else {
                // All answers
                for (const answer of answers) {
                    filteredAnswers.push(answer);
                }
            }

            const answerChosen = pickRandom(filteredAnswers);

            return {
                questionId: question.id,
                answerId: answerChosen ? answerChosen.id : null,
                lifelinesUsed: {
                    hint: false,
                    fiftyFifty: false,
                    stats: false,
                },
                timePoints: 0,
                devGenerated: true,
            }
        },

        devPanelPreviousQuestion() {
            if (this.currentQuestionIndex <= 0) {
                return;
            }

            clearTimeoutPromise();

            this.closeAllPopups();

            this.$store.commit('setQuestionState', {
                questionIndex: this.currentQuestionIndex,
                questionState: 'inactive',
            });

            this.givenAnswers.pop();

            this.currentQuestionIndex = this.currentQuestionIndex - 1;
        },
        devPanelNextQuestion(isCorrect) {
            if (this.currentQuestionIndex >= 9) {
                return;
            }

            clearTimeoutPromise();

            this.closeAllPopups();

            this.$store.commit('setQuestionState', {
                questionIndex: this.currentQuestionIndex,
                questionState: isCorrect ? 'correct' : 'incorrect',
            });

            //if (this.givenAnswers.length < 10) {
            this.setGivenAnswer(this.devGenerateGivenAnswerFromQuestionIndex(this.currentQuestionIndex, isCorrect))
            //}

            this.currentQuestionIndex = this.currentQuestionIndex + 1;
        },
        devPanelSkipQuestion(isCorrect, skipCount) {
            if (this.currentQuestionIndex >= 9) {
                return;
            }

            this.currentQuestionIndex = this.currentQuestionIndex + skipCount;
        },
        devPanelSkipToEnd() {
            clearTimeoutPromise();

            this.closeAllPopups();

            const isCorrect = true;

            for (let i = this.currentQuestionIndex; i < 10; i++) {
                this.$store.commit('setQuestionState', {
                    questionIndex: i,
                    questionState: isCorrect ? 'correct' : 'incorrect',
                });

                //if (this.givenAnswers.length < 10) {
                this.setGivenAnswer(this.devGenerateGivenAnswerFromQuestionIndex(i, isCorrect));
                //}
            }

            this.currentQuestionIndex = 10;
        },
        devPanelRandomKpis() {
            this.kpiHealthCoverage = Math.round(100 * Math.random());
            this.kpiHealthEmergencyProtection = Math.round(100 * Math.random());
            this.kpiHealthierPopulations = Math.round(100 * Math.random());
        },
        devPanelRandomPoints() {
            const kpiPoints = Math.max(0, Math.min(100, Math.round(this.kpiPointsMax * Math.random())));
            const bonusPoints = Math.max(0, Math.min(100, Math.floor((this.kpiPointsMax - kpiPoints) * Math.random())));

            this.kpiPoints = Math.round(kpiPoints + bonusPoints);
            this.totalBonusPoints = bonusPoints;
        },
        devPanelToggleFastMode() {
            this.$store.commit('fastMode', !this.fastMode);
        },
        devPanelToggleCheatMode() {
            this.$store.commit('cheatMode', !this.cheatMode);
        },
    },
};
</script>

<style lang="scss" scoped>
.status-message-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    transition: 300ms cubic-bezier(0.7, -0.3, 0.3, 1.2);
    opacity: 0;

    &.status-message-container-show {
        opacity: 1;
    }
}

.app-logo, .language-button-container, .disclaimer-container {
    transition: 800ms cubic-bezier(0.7, -0.3, 0.3, 1.2);
}

.app-logo {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
}

.left-ui, .right-ui {
    position: absolute;
    transition: 800ms cubic-bezier(0.7, -0.3, 0.3, 1.2);
}

.left-ui {
    left: 0;
    opacity: 1;
}

.right-ui {
    right: 0;
    opacity: 1;
}

.game-view {
    &-ui-hide {
        .left-ui {
            left: -500px !important;
            opacity: 0 !important;
        }

        .right-ui {
            right: -500px !important;
            opacity: 0 !important;
        }
    }
}

.language-button-container {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
        margin-bottom: 50px;
        font-size: 35pt;
    }
}

.show-next-button-container {
    position: absolute;
    bottom: 140px;
    right: 280px;

    .btn-next-question {
        background: url("@/assets/buttons/btn-next-question.png");
        width: 226px;
        height: 69px;
        padding: 5px;

        color: white;
        font-weight: bold;
        font-size: 20pt;

        border: 0;
        outline: 0;
        position: relative;

        &:hover {
            top: 1px;
        }

        &:active {
            top: 2px;
        }
    }
}

.disclaimer-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 80%;
}

.intro-language-committed {
    .app-logo {
        top: -500px;
        opacity: 0;
    }

    .language-button-container {
        bottom: -500px;
        opacity: 0;
    }

    .disclaimer-container {
        bottom: -100px;
    }
}

.main-container {
    width: 100%;
}

.end-popup {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
}

.dev-panel {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999999999999;
    transition: 200ms ease-in-out;

    .dev-panel-content {
        color: white;
        padding: 20px;
    }

    .amr-popup {
        transition: 200ms ease-in-out;
    }

    &-closed {
        top: -590px;

        .amr-popup {
            opacity: 0;
        }

        .dev-panel-sticker {
            display: block;
        }
    }

    &-open {
        top: 0;

        .amr-popup {
            opacity: 1;
        }

        .dev-panel-sticker {
            display: none;
        }
    }

    .dev-panel-sticker {
        position: absolute;
        bottom: 0;
        width: 400px;
        height: 10px;
        background: yellow;
        border-radius: 0 0 5px 5px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99999999;
        cursor: pointer;
        transition: 200ms ease-in-out;
        opacity: .2;

        &:hover {
            bottom: -20px;
            height: 30px;
            opacity: 1;
        }
    }
}
</style>

<style lang="scss">
.dev-panel .amr-box {
    background: rgba(#00205C, .8);
}
</style>
