<template>
    <div :class="computedClasses" :title="title" class="life-line-button" @click="onClick">
        <no-drag-image :src="icon" class="icon"/>

        <div class="life-line-tooltip">
            {{ title }}
        </div>
    </div>
</template>

<script>
import {LifeLineIdentifier} from "@/enum/LifeLineIdentifier";

import IconLifeLineHint from "@/assets/icons/icon-hint.png";
import IconLifeLineFiftyFifty from "@/assets/icons/icon-50-50.png";
import IconLifeLineStats from "@/assets/icons/icon-stats.png";
import {NoDragImage} from "@seriousgamesinteractive/components-vue3/common";

export default {
    name: 'LifeLineButton',
    components: {NoDragImage},
    props: {
        identifier: {
            type: String,
            required: true,
        },
        isUsed: {
            type: Boolean,
            required: false,
            default: false,
        },
        isInteractable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        computedClasses() {
            return [
                this.isUsed ? `life-line-button-used` : '',
                this.isInteractable ? `life-line-button-interactable` : '',
            ];
        },
        icon() {
            if (this.identifier === LifeLineIdentifier.Hint) {
                return IconLifeLineHint;
            } else if (this.identifier === LifeLineIdentifier.FiftyFifty) {
                return IconLifeLineFiftyFifty;
            } else if (this.identifier === LifeLineIdentifier.Stats) {
                return IconLifeLineStats;
            }

            return null;
        },
        title() {
            if (this.identifier === LifeLineIdentifier.Hint) {
                return this.$t('game.lifelineHintHelp');
            } else if (this.identifier === LifeLineIdentifier.FiftyFifty) {
                return this.$t('game.lifelineFiftyFifty');
            } else if (this.identifier === LifeLineIdentifier.Stats) {
                return this.$t('game.lifelineHowPreviousPlayersAnswered');
            }

            return 'N/A';

        },
    },
    emits: ['use'],
    methods: {
        onClick() {
            if (!this.isInteractable) {
                return; // Can't interact
            }

            if (this.isUsed) {
                return; // Already used
            }

            this.$emit('use');
        },
    }
}
</script>

<style lang="scss" scoped>
.life-line-button {
    background: url("@/assets/buttons/btn-hex.png") no-repeat;
    width: 87px;
    height: 101px;

    position: relative;
    pointer-events: none;

    &-interactable {
        cursor: pointer;
        pointer-events: all !important;
    }

    &-used {
        cursor: not-allowed;
        opacity: .3;

        &:hover, &:active {
            top: 0 !important;
        }

        .life-line-tooltip {
            display: none !important;
        }
    }

    &:hover {
        top: 1px;

        .life-line-tooltip {
            display: block;
            opacity: 1;
        }
    }

    &:active {
        top: 2px;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 6px));
        user-select: none;
    }
}

.life-line-tooltip {
    pointer-events: none !important;
    opacity: 0;
    transition: 200ms ease-in-out;
    position: absolute;
    top: -85px;
    left: 50%;
    transform: translateX(-50%);
    background: url("@/assets/ui/tooltip-bottom.png") no-repeat;
    width: 620px;
    height: 77px;
    color: white;
    font-size: 20pt;
    padding-top: 2px;
    padding-bottom: 24px;
    line-height: 55px;
    text-align: center;
}
</style>
