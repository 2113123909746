<template>
    <div class="app-logo">
        <div class="app-logo-image logo">
            <transition name="fade" mode="out-in">
                <img v-if="language === 'de'" src="@/assets/logos/de.app-logo.png"/>
                <img v-else-if="language === 'ru'" src="@/assets/logos/ru.app-logo.png"/>
                <img v-else src="@/assets/logos/en.app-logo.png"/>
            </transition>
        </div>
        <div class="app-logo-label-container" v-if="label">
            <label class="app-logo-label">
                <markdown-hover-text>{{ label }}</markdown-hover-text>
            </label>
        </div>
    </div>
</template>
<script>
import MarkdownHoverText from "@/components/common/MarkdownHoverText/index.vue";

export default {
    name: 'AppLogo',
    components: {MarkdownHoverText},
    props: {
        label: {
            type: String,
            default: null,
            required: false,
        }
    },
    computed: {
        language() {
            return this.$i18n.locale;
        },
    },
}
</script>

<style lang="scss" scoped>
.app-logo {
    width: 1300px;

    img {
        max-width: 100%;
        clear: both;
    }

    .app-logo-label {
        background: #019BDE;
        padding: 4px;
        border-radius: 4px;
        width: 100%;
        clear: both;
        font-size: 18pt;
    }

    .app-logo-label-container {
        //background: red;
        height: 200px;
        position: relative;
        width: 100%;
        margin-top: 10px;

        .app-logo-label {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
}

.app-logo-image {
    height: 160px;
}

</style>
