function getTimeoutFromEnv(key, defaultTimeout = 1000) {
	const timeout = process.env[key];

	if (!timeout) {
		console.error(`Could not find timeout by key ${key}`);

		return defaultTimeout;
	}

	const timeoutNumber = parseInt(timeout, 10);

	if (timeoutNumber <= 0) {
		console.error(`Timeout ${timeout} could not be parsed`);

		return defaultTimeout;
	}

	return timeoutNumber;
}

const TimerDurations = {
	beforeAnswerDisplay: getTimeoutFromEnv('VUE_APP_BEFORE_ANSWER_DISPLAY_TIMEOUT'),
	answerDisplay: getTimeoutFromEnv('VUE_APP_ANSWER_DISPLAY_TIMEOUT'),
	beforeExplanationPopup: getTimeoutFromEnv('VUE_APP_BEFORE_EXPLANATION_POPUP_TIMEOUT'),
	afterAnswering: getTimeoutFromEnv('VUE_APP_AFTER_ANSWERING_TIMEOUT'),
	nextQuestion: getTimeoutFromEnv('VUE_APP_NEXT_QUESTION_TIMEOUT'),
	beforeAnswerResult: getTimeoutFromEnv('VUE_APP_BEFORE_ANSWER_RESULT_TIMEOUT'),
};

console.log('TimerDurations', TimerDurations);

export {
	TimerDurations
};
