<template>
    <component
        :is="tagName"
        ref="markdown-text-compiler"
        :class="[this.class, debug ? 'markdown-text-compiler-debug' : '']"
        class="markdown-text-compiler"
    >
        <slot/>
    </component>
    <component
        :is="tagName"
        v-if="markdownHtml"
        ref="markdown-compile-destination"
        :class="class" class="markdown-text"
        v-html="markdownHtml"
    />
</template>

<script>
import {marked} from 'marked';
import MarkdownTextMixins from "@/components/common/MarkdownText/MarkdownTextMixins.vue";

export default {
    name: 'MarkdownText',
    mixins: [MarkdownTextMixins],
    // props defined in MarkdownTextMixins
    data() {
        return {
            observer: null,
            observerMutationCount: 0,

            markdownHtml: '',
            markdownRaw: '',
        };
    },
    emits: ['render'],
    watch: {
        observerMutationCount() {
            this.renderMarkdown();
        },
    },
    computed: {
        tagName() {
            return this.inline ? 'span' : 'div';
        },
    },
    mounted() {
        const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

        this.observer = new MutationObserver(() => {
            this.observerMutationCount += 1;
        });

        const htmlObserverOptions = {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true,
        };

        // Setup the observer to observe when slot changes
        //console.log('MarkdownTextCompiler', this.$refs['markdown-text-compiler']);
        this.observer.observe(this.$refs['markdown-text-compiler'], htmlObserverOptions);

        this.renderMarkdown();
    },
    beforeUnmount() {
        // Clean up, disconnect observer
        this.observer.disconnect();

        this.observer = null;
        this.markdownRaw = null;
        this.markdownHtml = null;
    },
    methods: {
        renderMarkdown() {
            // Fetch from raw HTML element (best way to get slot content)
            let markdownRaw = this.$refs['markdown-text-compiler'].innerText;

            // Trim
            markdownRaw = markdownRaw.replace(/(^\s+|\s+$)/g, ' ');

            this.markdownRaw = markdownRaw;

            const featureBlacklist = this.features.filter((item) => {
                return this.featuresBlacklist.indexOf(item) >= 0;
            });

            const tokenizer = new marked.Tokenizer();

            // Disable blacklisted tokens
            for (const feature of featureBlacklist) {
                tokenizer[feature] = () => {
                };
            }

            /*const strikeThroughExtension = {
                name: 'strikethrough',
                level: 'inline',
                start(src) {
                    console.log('start', src);
                    return src.match(/~~/)?.index;
                },
                tokenizer(src, tokens) {
                    console.log('tokenizer', src, tokens);
                    const rule = /~~[^~]+~~/;
                    const match = rule.exec(src);

                    if (!match) {
                        return null;
                    }

                    return {
                        type: 'strikethrough',
                        raw: match[0],
                        text: match[1].trim(),
                    };
                },
                renderer(token) {
                    console.log('renderer', token);
                    return `<del>${this.parser.parseInline(token.text)}</del>`;
                },
                childTokens: ['text'],
            };

            const extensions = [strikeThroughExtension];

            //const options = {tokenizer, extensions};*/
            const options = { tokenizer, breaks: this.brNewLines, gfm: true };

            //console.log('options', options);

            let markdownHtml = this.inline
                ? marked.parseInline(markdownRaw, options)
                : marked.parse(markdownRaw, options);

            // The strikethrough extension doesn't work correct, let's just do it manually in the HTML
            //markdownHtml = markdownHtml.replace(/~~(.+?)~~/g, '<del>$1</del>');

            // The underline extension doesn't work correct, let's just do it manually in the HTML
            //markdownHtml = markdownHtml.replace(/~(.+?)~/g, '<u>$1</u>');

            if (this.linkTarget) {
                // To allow for "linkTarget" to work, let's replace "<a " with "<a target='blank' "
                markdownHtml = markdownHtml.replace(/<a\s+/g, `<a target="${this.linkTarget}" `);
            }

            // Trim
            markdownHtml = markdownHtml.replace(/(^\s+|\s+$)/g, '');

            /*if (this.brNewLines) {
                // Replace all kinds of newlines with a \n
                markdownHtml = markdownHtml.replace(/(\r\n|\r\n|\r|\n)/g, '\n');

                // Replace any amount of newlines with maximum two
                markdownHtml = markdownHtml.replace(/\n{2,}/g, '\n\n');

                // Trim again, just for good measure
                markdownHtml = markdownHtml.replace(/(^\s+|\s+$)/g, '');
            }*/

            this.markdownHtml = markdownHtml;

            requestAnimationFrame(() => {
                this.$emit('render', {
                    html: markdownHtml,
                    element: this.$refs['markdown-compile-destination'],
                });
            });
        },
    },
};
</script>

<style lang="scss">
.markdown-text-compiler {
    display: none;

    &.markdown-text-compiler-debug {
        display: block !important;
    }
}

.markdown-text {
    // Some of these markdown items are put into a p element, others put into a blockquote element, probably due to newlines
    p:last-child,
    blockquote:last-child {
        margin-bottom: 0;
    }

    del {
        // Change the default behavior of ~text~ from strikethrough to underlinedd
        text-decoration-line: underline !important;
    }

    ol {
        list-style: decimal !important;
    }

    ul {
        list-style: circle !important;
    }
}
</style>
