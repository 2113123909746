// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/ui/bg-kpi.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/ui/bg-kpi-label.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kpi[data-v-24e1d248] {\n  width: 245px;\n  height: 250px;\n  cursor: help;\n}\n.kpi:hover .kpi-label[data-v-24e1d248] {\n  opacity: 1;\n}\n.kpi-circle[data-v-24e1d248] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 172px;\n  height: 172px;\n  margin: 0 auto;\n  padding: 6px;\n  position: relative;\n}\n.kpi-circle .kpi-icon[data-v-24e1d248] {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.kpi-circle .kpi-circle-fill[data-v-24e1d248] {\n  width: 160px;\n  overflow: hidden;\n  position: absolute;\n  bottom: 6px;\n  transition: 200ms ease-out;\n}\n.kpi-circle .kpi-circle-fill .kpi-circle-fill-inner[data-v-24e1d248] {\n  width: 160px;\n  height: 160px;\n  background: #80BC00;\n  border-radius: 50%;\n  position: absolute;\n  bottom: 0;\n  left: 0;\n}\n.kpi-label[data-v-24e1d248] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  width: 245px;\n  height: 73px;\n  opacity: 0;\n  transition: opacity 200ms ease-in-out;\n  padding: 17px 6px 11px;\n}\n.kpi-label .kpi-label-inner[data-v-24e1d248] {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n.kpi-label label[data-v-24e1d248] {\n  position: absolute;\n  line-height: 18px;\n  text-align: center;\n  font-size: 14pt;\n  font-weight: bold;\n  width: 100%;\n  height: -moz-fit-content;\n  height: fit-content;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.kpi-circle[data-v-24e1d248] {\n  transform-origin: center;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
