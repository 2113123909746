export default {
  'intro': {
    'selectQuiz': 'Select Quiz', // Type: text
    'selectLanguage': 'Select Language', // Type: text
    'disclaimer': '**Disclaimer: No prizes will be awarded for completing this quiz, regardless of the score.**', // Type: markdown
    'handleWithCareMessage': '~~0:Antimicrobial resistance (AMR)~~ risks reversing the health gains achieved in the last century. There is an urgent need for increased action to ensure that antimicrobials (antibiotics, antivirals, antifungals and antiparasitics) are used appropriately so they continue to cure the infections they were designed to treat.', // Type: markdown
    'logoText': 'Global action has not yet met the scale of the threat. Increased awareness as well as strong political commitments and financial investments are needed to control ~~0:antimicrobial resistance (AMR)~~ to achieve **universal health coverage**, enhance **health emergency preparedness and response** and ensure **healthier populations**.', // Type: text
  },
  'generic': {
    'buttonYes': 'Yes', // Type: text
    'buttonNo': 'No', // Type: text
    'buttonOk': 'OK', // Type: text
    'buttonMute': 'Mute', // Type: text
    'buttonNextQuestion': 'Next question', // Type: text
    'buttonFinalScore': 'Go to final score', // Type: text
    'buttonUnmite': 'Unmute', // Type: text
    'buttonBack': 'Back', // Type: text
    'loading': 'Loading..', // Type: text
    'pleaseRotatePhone': 'Please rotate your phone for optimal experience', // Type: text
    'countryListTable': '0 | 1 | 2 \r\n' + 
      '- | - | - \r\n' + 
      'Albania | Greece | Portugal\n' + 
      'Andorra | Hungary | Republic of Moldova\n' + 
      'Armenia | Iceland | Romania\n' + 
      'Austria | Ireland | Russian Federation\n' + 
      'Azerbaijan | Israel | San Marino\n' + 
      'Belarus | Italy | Serbia\n' + 
      'Belgium | Kazakhstan | Slovakia\n' + 
      'Bosnia and Herzegovina | Kyrgyzstan | Slovenia\n' + 
      'Bulgaria | Latvia | Spain\n' + 
      'Croatia | Lithuania | Sweden\n' + 
      'Cyprus | Luxembourg | Switzerland\n' + 
      'Czechia | Malta | Tajikistan\n' + 
      'Denmark | Monaco | T\u00fcrkiye\n' + 
      'Estonia | Montenegro | Turkmenistan\n' + 
      'Finland | Netherlands (Kingdom of the) | Ukraine\n' + 
      'France | North Macedonia | United Kingdom of Great Britain and Northern Ireland\n' + 
      'Georgia | Norway | Uzbekistan\n' + 
      'Germany | Poland |', // Type: markdown
  },
  'game': {
    'labelMinutes': 'min', // Type: text
    'labelPoints': 'points', // Type: text
    'kpiHealthCoverage': 'Universal health coverage', // Type: text
    'kpiHealthEmergencyProtection': 'Health emergencies protection', // Type: text
    'kpiHealthierPopulations': 'Healthier populations', // Type: text
    'lifelineHowPreviousPlayersAnswered': 'How previous players answered', // Type: text
    'lifelineFiftyFifty': 'Remove 2 answers', // Type: text
    'lifelineHintHelp': 'Hint', // Type: text
    'lifelineConfirmation': 'Use lifeline?\n' + 
      '[LIFELINE]', // Type: text
  },
  'statistics': {
    'title': 'Quiz statistics', // Type: text
    'labelAnswers': 'Answers', // Type: text
    'labelNumberOfAnswers': 'Number of answers', // Type: text
  },
  'end': {
    'title': 'Congratulations on completing the quiz!', // Type: text
    'bonusPoints': 'Bonus points', // Type: text
    'finalScore': 'Final score', // Type: text
    'correctAnswers': 'Correct answers', // Type: text
    'buttonPlayAgain': 'Play again', // Type: text
    'buttonChallengeAFriend': 'Challenge a friend', // Type: text
    'buttonLearnMore': 'Learn more', // Type: text
    'endLogoText': 'Antimicrobials are running out', // Type: text
    'challengeAFriend': 'Share the link to see if others can\n' + 
      'beat your score:', // Type: text
    'challengeAFriendLinkCopied': 'Link copied to clipboard', // Type: text
  },
  'hoverText': {
    'hoverText_0': '**Antimicrobial Resistance (AMR)** occurs naturally when bacteria, viruses, fungi and parasites change over time and no longer respond to medicines, making infections harder to treat and increasing the risk of disease spread, severe illness and death. AMR is greatly accelerated by the misuse and overuse of antimicrobials.', // Type: markdown
    'hoverText_1': '**European Union (EU)** countries include: Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, the Kingdom of the Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain and Sweden.\n' + 
      '\n' + 
      '**European Economic Area (EEA)** includes: EU countries as well as Iceland, Liechtenstein and Norway.', // Type: markdown
    'hoverText_2': '**Health burden** is a population-wide measure of the impact of a health issue. Often expressed as disability-adjusted life years (DALYs), it accounts for years of life lost due to premature death and the years of life compromised by disease and disability. One DALY represents a year of healthy life lost.', // Type: markdown
    'hoverText_3': 'Three common types of resistant bacteria are: Escherichia coli, methicillin-resistant Staphylococcus aureus, and carbapenem-resistant Pseudomonas aeruginosa.', // Type: markdown
    'hoverText_4': '**Infection prevention and control** is a practical, evidence-based approach preventing patients and health workers from being harmed by avoidable infections. It affects all aspects of health care and includes hand and environmental hygiene, injection safety and waste management to mitigate infections and AMR.', // Type: markdown
    'hoverText_5': '**Hand hygiene** is a primary measure for reducing the risk of transmitting infection through effective hand washing with soap and running water or applying alcohol-based hand sanitizer.\n' + 
      '\n' + 
      '**Environmental hygiene** is the effective cleaning and decontaminating of surfaces, medical equipment and devices and safe handling of sharps, blood, body fluids, waste and linen to reduce infection transmission.', // Type: markdown
    'hoverText_6': '**Access antibiotics** have a narrow spectrum of activity and a good safety profile in terms of side-effects.\n' + 
      '\n' + 
      '**Watch antibiotics** are broader-spectrum antibiotics and are recommended as first-choice options for patients with more severe clinical presentations or for\n' + 
      'infections where the causative pathogens are more likely to be resistant to Access antibiotics.\n' + 
      '\n' + 
      '**Reserve antibiotics** are last-choice antibiotics used to treat multidrug-resistant infections.', // Type: markdown
    'hoverText_7': '**Antimicrobial stewardship** promotes the optimal prescribing and use of antimicrobials to treat infections effectively, protects patients and combats AMR.', // Type: markdown
    'hoverText_8': '**One Health** is an approach that recognizes the interdependence of human, animal and environmental health and the need for collaboration between sectors to combat AMR through education, surveillance, infection prevention and control, appropriate use in agriculture and research and development.', // Type: markdown
    'hoverText_10': '**Rifampicin-resistant tuberculosis (RR-TB)** is caused by bacteria that do not respond to rifampicin.\n' + 
      '\n' + 
      '**Multidrug-resistant tuberculosis (MDR-TB)** is caused by bacteria that do not respond to, at least, isoniazid and rifampicin, the two most powerful anti-TB medicines.\n' + 
      '\n' + 
      'Patients with multidrug-resistant or rifampicin-resistant tuberculosis (MDR\/RR-TB) require second-line treatment regimens, which are more complex than those used to treat patients without drug-resistant TB.', // Type: markdown
    'hoverText_11': '**Member States of the WHO European Region** include:\n' + 
      '[MEMBER_STATE_TABLE]', // Type: markdown
    'hoverText_12': '**Extensively drug-resistant TB (XDR-TB)** is a form of MDR-TB which is also resistant to two groups of second-line anti-TB medicines, making it even more difficult to treat.', // Type: markdown
    'hoverText_13': 'WHO\'s **AWaRe** (Access, Watch, Reserve) book on antibiotics provides concise, evidence-based guidance on the choice of antibiotic, dose, route of administration, and duration of treatment for more than 30 of the most common clinical infections, taking into account the benefits, harms and potential for resistance.', // Type: markdown
    'hoverText_14': '**Access antibiotics** have a narrow spectrum of activity, lower cost, a good safety profile and generally low resistance potential. They are often recommended as empiric first- or second-choice treatment options for common infections.', // Type: markdown
    'hoverText_15': '**Watch antibiotics** are broader-spectrum antibiotics, generally with higher costs, and are recommended only as first-choice options for patients with more severe clinical presentations or for infections where the causative pathogens are more likely to be resistant to Access antibiotics.\n' + 
      '\n' + 
      '**Reserve antibiotics** are last-choice antibiotics used to treat multidrug-resistant infections.', // Type: markdown
    'hoverText_16': '**WHO Member States** are grouped into six regions: Africa, Americas, Eastern Mediterranean, Europe, South-East Asia and Western Pacific.', // Type: markdown
    'hoverText_17': '**MDR-TB** is caused by bacteria that do not respond to, at least, isoniazid and rifampicin, the two most powerful anti-TB medicines.', // Type: markdown
  },
};